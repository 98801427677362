// Lib
import React from 'react';
import { useSelector } from 'react-redux';
import * as Immutable from 'immutable';

// Utils
import * as elementFactory from '../../../../common/elements/elementRegistry';
import * as pointsLib from '../../../../common/maths/geometry/point';
import { getTranslationStyles } from '../../../utils/cssUtil';

// Selectors
import { getCurrentBoardId } from '../../selectors/currentBoardSelector';
import { getCurrentUserId } from '../../../user/currentUserSelector';

// Components
import ElementDragPreviewPositioner from '../../dnd/ElementDragPreviewPositioner';
import DraggableElementCreationTool from '../../dnd/DraggableElementCreationTool';
import Icon from '../../../components/icons/Icon';

// Constants
import { DRAG_PREVIEW_ID } from '../../../reducers/draggingConstants';
import { ElementType } from '../../../../common/elements/elementTypes';
import { CardClipperToolProps } from './CardClipperTool';

// Styles
import './CardClipperButton.scss';

type CardClipperButtonProps = CardClipperToolProps & {
    y: number;

    onTouchStart: (event: React.TouchEvent) => void;
    onTouchEnd: (event: React.TouchEvent) => void;
    onMouseDown: (event: React.MouseEvent) => void;
    onMouseUp: (event: React.MouseEvent) => void;

    beforeDragStart: () => void;
    onDragEnd: (dragSuccess: boolean) => void;
};

// Clipped text is saved on mousedown in the cardClipperEditorStateManager
const getDefaultContent = ({ clippedText }: CardClipperButtonProps) => ({
    textContent: clippedText,
});

const getElementScaledCustomDragOffset = ({
    selectionHeight,
    gridSize,
    getContextZoomScale,
}: CardClipperButtonProps) => ({
    x: -29 * gridSize * getContextZoomScale(),
    y: 2 - selectionHeight / 2,
});

const getElementScaledGrabOffset = (props: CardClipperButtonProps) => {
    return pointsLib.scale(-1, getElementScaledCustomDragOffset(props));
};

// We need to keep editing on drag, otherwise the original card will try to save the editor state update
// that we made to highlight the clipped text (due to the Milanote Editor's "isNoLongerEditing" prop comparison).
const CardClipperButton = (props: CardClipperButtonProps) => {
    const { gridSize, getContextZoomScale, onTouchStart, onTouchEnd, onMouseDown, onMouseUp } = props;

    const currentBoardId = useSelector(getCurrentBoardId);
    const currentUserId = useSelector(getCurrentUserId);

    let mouseDownPreview = null;
    if (props.isClipping) {
        const elementDragPreviewData = Immutable.fromJS(
            elementFactory.createElementObject({
                id: DRAG_PREVIEW_ID,
                elementType: ElementType.CARD_TYPE,
                content: getDefaultContent(props),
            }),
        );

        const zoomScale = getContextZoomScale();

        const scaledCustomDragOffset = getElementScaledCustomDragOffset(props);
        const unscaledCustomDragOffset = pointsLib.reverseScale(zoomScale, scaledCustomDragOffset);

        const mouseDownPreviewItem = {
            unscaledElementOffsetsMap: {
                // Shifts the drag preview up half the height of the selection area and to the right so the card text
                // is in a similar spot to where it's being clipped from
                [DRAG_PREVIEW_ID]: unscaledCustomDragOffset,
            },
            grabbedElement: elementDragPreviewData,
            element: elementDragPreviewData,
        };

        mouseDownPreview = (
            <ElementDragPreviewPositioner
                item={mouseDownPreviewItem}
                currentBoardId={currentBoardId}
                element={elementDragPreviewData}
                isPresentational
                gridSize={gridSize}
            />
        );
    }

    return (
        <div className="CardClipperButton" style={getTranslationStyles({ x: 0, y: props.y })}>
            <DraggableElementCreationTool
                {...props}
                currentBoardId={currentBoardId}
                currentUserId={currentUserId}
                getElementScaledCustomDragOffset={getElementScaledCustomDragOffset}
                getElementScaledGrabOffset={getElementScaledGrabOffset}
                getDefaultContent={getDefaultContent}
                elementType={ElementType.CARD_TYPE}
                onTouchStart={onTouchStart}
                onTouchEnd={onTouchEnd}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
            >
                {mouseDownPreview}
                <div className="draggable-shadow">
                    <Icon name="element-card" />
                </div>
                <Icon name="element-card" />
            </DraggableElementCreationTool>
        </div>
    );
};

export default CardClipperButton;
