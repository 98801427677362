import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Selector
import { getCurrentCellSelections } from '../../../../../element/table/tableSelector';
import { isSelectingMultipleSelections } from '../../../../../element/table/utils/tableCellSelectionUtils';

// Components
import ToolbarTool from '../../ToolbarTool';
import Icon from '../../../../../components/icons/Icon';

// Actions
import { getElementId } from '../../../../../../common/elements/utils/elementPropertyUtils';
import HotTableManager from '../../../../../element/table/manager/HotTableManager';

const mapStateToProps = createStructuredSelector({
    currentCellSelections: getCurrentCellSelections,
});

const AddRowTool = (props) => {
    const { element, currentCellSelections } = props;

    const elementId = getElementId(element);

    const updateAddRow = () => {
        const hotTable = HotTableManager?.getHotTableComponent(elementId);
        if (!hotTable?.tableOperationsRef?.current) return;

        hotTable.tableOperationsRef.current.addRowsBelow();
    };

    return (
        <div className="AddRowTool">
            <ToolbarTool
                {...props}
                onClick={updateAddRow}
                name="Add row"
                isActive={false}
                disabled={isSelectingMultipleSelections(currentCellSelections)}
                tooltipEnabled={true}
                tooltipText={'Add row'}
                shortcut={['alt', '↓']}
            >
                <Icon name="toolbar-add-row" />
            </ToolbarTool>
        </div>
    );
};

AddRowTool.propTypes = {
    iconName: PropTypes.string,
    element: PropTypes.object,
    currentCellSelections: PropTypes.object,

    dispatchAddRow: PropTypes.func,
};

export default connect(mapStateToProps)(AddRowTool);
