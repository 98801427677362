import { MentionAttrs } from './Mention';
import randomString from '../../../uid/randomString';

export type MentionSuggestion = {
    id: string;

    name: string;
    email: string;
    image?: string;

    _name: {
        displayName: string;
        givenName: string;
        familyName: string;
    };

    disabled: boolean;
    sharingCurrentBoard: boolean;

    shareCount?: number;
    score: number;
};

// ~1 / 57 billion chance of collision
const MENTION_KEY_DEFAULT_LENGTH = 6;

/**
 * Converts data from a suggestion entry to the format used by the mention mark.
 */
export function mentionSuggestionToAttrs(
    suggestionEntry: MentionSuggestion,
    mentionKey: string = randomString(MENTION_KEY_DEFAULT_LENGTH),
): MentionAttrs {
    const { _name, name, id } = suggestionEntry;

    return {
        mentionKey,
        id,
        label: name || _name?.displayName,
    };
}
