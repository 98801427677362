// Lib
import React, { ReactElement, useCallback, useMemo } from 'react';
import { defer, stubTrue } from 'lodash';

// Utils
import { getElementId } from '../../../../common/elements/utils/elementPropertyUtils';
import { isTiptapSelectionCollapsedAtEnd } from '../../../../common/tiptap/utils/isTiptapSelectionCollapsedAtEnd';

// Components
import TitleTiptapEditor, { TitleTiptapEditorProps } from '../../titleEditor/TitleTiptapEditor';

// Types
import { Command } from '@tiptap/core';

type OmittedTitleEditorProps = Omit<
    TitleTiptapEditorProps,
    'className' | 'placeholder' | 'onEmptyDelete' | 'keyboardShortcuts'
>;

export type TaskListTitleTiptapEditorProps = OmittedTitleEditorProps & {
    goToNextTaskHandler: (args: { elementId: string }) => void;
    dispatchHideTitle: (args: { id: string }) => void;
};

const TaskListTitleTiptapEditor = (props: TaskListTitleTiptapEditorProps): ReactElement => {
    const {
        element,
        title,
        isEditable,
        isEditing,
        isSingleSelected,
        spellCheck,
        currentEditorId,
        isFocusedForegroundElement,
        startEditing,
        dispatchUpdateTitle,
        goToNextTaskHandler,
        dispatchHideTitle,
    } = props;

    const goToNextTask = useCallback(() => {
        goToNextTaskHandler({ elementId: getElementId(element) });
        return true;
    }, []);

    const goToNextTaskIfAtEnd: Command = useCallback(({ state }) => {
        if (!isTiptapSelectionCollapsedAtEnd(state)) return false;

        // Deferring to prevent the default Mousetrap event handler from being triggered
        defer(goToNextTask);

        return true;
    }, []);

    const hideTitleAndGoToNextTask = useCallback(() => {
        dispatchHideTitle({ id: getElementId(element) });
        goToNextTask();
    }, []);

    const keyboardShortcuts = useMemo(
        () => ({
            ArrowDown: goToNextTaskIfAtEnd,
            ArrowRight: goToNextTaskIfAtEnd,
            Tab: goToNextTask,
            Enter: goToNextTask,
            // Prevent cmd+enter from creating a new line
            'Mod-Enter': stubTrue,
            'Mod-Return': stubTrue,
        }),
        [goToNextTask],
    );

    return (
        <TitleTiptapEditor
            element={element}
            className="TaskListTitleTiptapEditor"
            title={title}
            placeholder="New To-do List"
            isFocusedForegroundElement={isFocusedForegroundElement}
            isEditable={isEditable}
            isEditing={isEditing}
            isSingleSelected={isSingleSelected}
            spellCheck={spellCheck}
            currentEditorId={currentEditorId}
            onEmptyDelete={hideTitleAndGoToNextTask}
            dispatchUpdateTitle={dispatchUpdateTitle}
            startEditing={startEditing}
            keyboardShortcuts={keyboardShortcuts}
        />
    );
};

export default TaskListTitleTiptapEditor;
