// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import Button from '../buttons/Button';
import Icon from '../icons/Icon';

// Styles
import './SearchClearButton.scss';

const SearchClearButton = ({ show, canClear, onMouseDown, onClick }) => {
    if (!show) return null;

    const iconName = canClear ? 'search-clear' : 'search-magnifying-glass';

    return (
        <Button
            className={classNames('SearchClearButton', { clear: canClear })}
            onMouseDown={onMouseDown}
            onClickFn={onClick}
        >
            <Icon name={iconName} />
        </Button>
    );
};

SearchClearButton.propTypes = {
    show: PropTypes.bool,
    canClear: PropTypes.bool,
    onClick: PropTypes.func,
    onMouseDown: PropTypes.func,
};

export default SearchClearButton;
