// Lib
import React from 'react';

// Utils
import { getHasClones } from '../../../common/elements/utils/elementPropertyUtils';

// Components
import CardEditor from './CardEditor';
import CloneCardEditor from './CloneCardEditor';

// Types
import { ImMNElement } from '../../../common/elements/elementModelTypes';
import { TiptapContent } from '../../../common/tiptap/tiptapTypes';
import { RawDraftContentState } from 'draft-js';

type CardEditorSwitchProps = {
    element: ImMNElement;
    isClone: boolean;
    isEditing: boolean;
    isEditable: boolean;
    isSingleSelected: boolean;
    isFocusedForegroundElement: boolean;
    currentEditorId: string;
    editorRef: (editor: HTMLDivElement | null) => void;
    saveContent: (content: TiptapContent | RawDraftContentState, transactionId?: number) => void;
    startEditing: () => void;
    moveElementsToTrash: () => void;
    createNewCard: () => void;
};

const CardEditorSwitch = (props: CardEditorSwitchProps) => {
    const { element, isClone } = props;

    if (getHasClones(element) || isClone) return <CloneCardEditor {...props} />;

    // TODO-TIPTAP: On Draft removal, explicitly pass props
    return <CardEditor {...props} />;
};

export default CardEditorSwitch;
