// Lib
import { Extension } from '@tiptap/react';

// Commands
import { setNode } from './setNode';
import { toggleList } from './toggleList';
import { clearBlockNodes } from './clearBlockNodes';

// Constants
import { DEFAULT_TIPTAP_EXTENSION_PRIORITY } from '../../tiptapTypes';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        coreCommands: {
            /**
             * Set a blockquote node
             */
            clearBlockNodes: () => ReturnType;
        };
    }
}

export const CoreCommands = Extension.create({
    // _Reducing_ the priority allows the commands in this extension to override the
    //  OOTB commands, otherwise the OOTB commands are used instead.
    priority: DEFAULT_TIPTAP_EXTENSION_PRIORITY - 1,

    addCommands() {
        return {
            clearBlockNodes,
            // The following commands override OOTB commands by using a lower priority (as explained above)
            // setNode is used by most node extension commands when setting their node type
            //  - We need to intercept it to correctly handle list types
            setNode,
            toggleList,
        };
    },
});
