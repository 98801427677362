import { isFunction } from 'lodash';
import { MutableRefObject, Ref } from 'react';

type Options = {
    skipNullRef?: boolean;
};

/**
 * Function to make it easier to attach multiple refs to the same element.
 *
 * @param refs an array of refs to attach
 * @param options.allowNullRef if true, only update the refs when the element is non-null
 * @returns a callback that can be passed to a refs prop
 */
export const multiRef = <T>(refs: Array<Ref<T | null>>, options?: Options) => {
    return (elem: T | null) => {
        if (!elem && options?.skipNullRef) return;
        refs.forEach((r: Ref<T | null>) => {
            if (!r) return;
            if (isFunction(r)) {
                r(elem);
                return;
            }
            // Refs can be immutable when being passed to an html element's prop, but we
            // know that isn't happening here so it's safe to cast.
            (r as MutableRefObject<T | null>).current = elem;
        });
    };
};
