import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { identity } from 'lodash/fp';
import { useSelector } from 'react-redux';

// List drag and drop
import makeCanDropFn from '../dnd/canDrop/makeCanDropFn';
import onlyTasks from '../dnd/canDrop/onlyTaskPredicate';

// Utils
import { elementClassNames, getDomElementId } from '../utils/elementUtil';
import { getIsTaskComplete } from './taskSelector';
import { getElementStyle } from '../../utils/grid/gridUtils';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import { getUtilityButtonDisplayClassName } from './taskUtils';

// Selectors
import { getIsTouchSelector, getLegacyHybridUseCaseSelector } from '../../platform/platformSelector';

// Components
import Checkbox from '../../components/editor/plugins/checklist/components/Checkbox';
import TaskEditorWrapper from './editor/TaskEditorSwitch';
import LazyList from '../../list/LazyList';
import TaskStyler from '../taskList/TaskStyler';
import TaskDepthIndent from './TaskDepthIndent';
import TaskDragHandle from './TaskDragHandle';
import TaskDeleteButton from './TaskDeleteButton';
import ElementActivityIndicator from '../activity/ElementActivityIndicator';
import TaskPillsPanel from './pills/TaskPillsPanel';
import AttachedCommentsDropTarget from '../dnd/elementDropTargets/AttachedCommentsDropTarget';
import AttachedCommentsContainer from '../attachedComment/AttachedCommentsContainer';

// Constants
import { ELEMENT_DEFAULT_WIDTH } from '../../../common/elements/elementConstants';
import { LegacyHybridUseCase } from '../../platform/platformTypes';

// Styles
import './Task.scss';

const taskListCanDrop = makeCanDropFn([onlyTasks]);

const Task = (props) => {
    const {
        element,
        elementEvents,
        onCheckboxClick,
        taskListElementId,
        connectDragSource,
        gridSize,
        inList,
        allowDrag,
        moveToTrashHandler,
        onKeyboardDelete,
        changeIndentationHandler,
        goToPreviousTaskHandler,
        goToNextTaskHandler,
        depth = 0,
        isFirstEntry,
        noTitle = true,
        isEditable,
        taskListOnMouseDown,
        taskListOnContextMenu,
        onTaskToggle,
        currentBoardId,
        onStartBackspaceHandler,
        onEndDeleteHandler,
        setParentHoveredChildAcceptsDrop,
        isPresentational,
        isParentTaskListSelected,
        isDragPreview,
        permissions,
        getContextZoomScale,
        selectParentTaskList,
    } = props;

    // TODO-MINI - remove this legacy use case and simplify allowTaskDrag/showDragHandler logic once legacy apps are deprecated
    const legacyHybridUseCaseSelector = useSelector(getLegacyHybridUseCaseSelector);
    const isTouchDevice = useSelector(getIsTouchSelector);

    const isCompleted = getIsTaskComplete(element);
    const allowTaskDrag = isEditable && allowDrag;
    const showDragHandler =
        allowTaskDrag &&
        // Don't show task drag handler on Android legacy app, as updating the task order
        // is done in a native bottom sheet
        legacyHybridUseCaseSelector !== LegacyHybridUseCase.ANDROID_BOARD_LIST;

    const taskClasses = classNames('Task drag-handle', {
        completed: isCompleted,
        'drag-enabled': showDragHandler,
    });
    const classes = elementClassNames(taskClasses, { ...props });
    const utilityButtonDisplayClassName = getUtilityButtonDisplayClassName(isTouchDevice, isParentTaskListSelected);

    const elementId = getElementId(element);

    const dragHandleWrapper = connectDragSource || identity;

    const dragHandle = showDragHandler ? (
        <TaskDragHandle
            dragHandleWrapper={allowTaskDrag ? dragHandleWrapper : identity}
            className={utilityButtonDisplayClassName}
        />
    ) : null;

    return (
        <div
            id={getDomElementId(elementId)}
            className={classes}
            style={getElementStyle(ELEMENT_DEFAULT_WIDTH, gridSize, inList)}
        >
            <ElementActivityIndicator {...props} />
            <TaskStyler {...elementEvents} largeTop={(noTitle && depth === 0 && isFirstEntry) || isDragPreview}>
                <TaskDepthIndent depth={depth} />
                <Checkbox checked={isCompleted} onClick={onCheckboxClick} />
                <div className="task-body">
                    <TaskEditorWrapper {...props} />
                    <TaskPillsPanel {...props} />
                </div>
                {dragHandle}
                <TaskDeleteButton
                    show={isEditable && isCompleted}
                    className={utilityButtonDisplayClassName}
                    elementId={elementId}
                    currentBoardId={currentBoardId}
                    moveToTrashHandler={moveToTrashHandler}
                />
                <AttachedCommentsDropTarget
                    setParentHoveredChildAcceptsDrop={setParentHoveredChildAcceptsDrop}
                    gridSize={gridSize}
                    isPresentational={isPresentational}
                    element={element}
                    elementId={elementId}
                    getContextZoomScale={getContextZoomScale}
                />
                <AttachedCommentsContainer
                    elementId={elementId}
                    element={element}
                    gridSize={gridSize}
                    isPresentational={isPresentational}
                    currentBoardId={currentBoardId}
                    permissions={permissions}
                />
            </TaskStyler>
            <LazyList
                {...props}
                depth={depth + 1}
                enableDoubleClick={false}
                listId={elementId}
                listElementProps={{
                    taskListElementId,
                    allowDrag: true,
                    onKeyboardDelete,
                    moveToTrashHandler,
                    changeIndentationHandler,
                    goToPreviousTaskHandler,
                    goToNextTaskHandler,
                    onStartBackspaceHandler,
                    onEndDeleteHandler,
                    depth: depth + 1,
                    taskListOnMouseDown,
                    taskListOnContextMenu,
                    onTaskToggle,
                    selectParentTaskList,
                    isParentTaskListSelected,
                }}
                listCanDropFn={taskListCanDrop}
            />
        </div>
    );
};

Task.propTypes = {
    element: PropTypes.object.isRequired,
    gridSize: PropTypes.number,
    inList: PropTypes.string,
    isLocked: PropTypes.bool,
    isSelected: PropTypes.bool,
    isEditing: PropTypes.bool,
    isEditable: PropTypes.bool,
    isHovered: PropTypes.bool,
    spellCheck: PropTypes.bool,
    startEditing: PropTypes.func,
    stopEditing: PropTypes.func,
    saveContent: PropTypes.func,
    moveElementsToTrash: PropTypes.func,
    onKeyboardDelete: PropTypes.func,
    currentEditorKey: PropTypes.string,
    currentEditorId: PropTypes.string,
    createNewCard: PropTypes.func,
    onEmptyBackspace: PropTypes.func,
    saveCaptionContent: PropTypes.func,
    elementEvents: PropTypes.object,
    tempSize: PropTypes.object,
    filterQuery: PropTypes.string,
    updateElementType: PropTypes.func,
    dispatchUpdateElement: PropTypes.func,
    toggleComplete: PropTypes.func,
    editorRef: PropTypes.func,
    focusOnEditor: PropTypes.func,

    isClipping: PropTypes.bool,
    isClipDragging: PropTypes.bool,
    isClipCopy: PropTypes.bool,

    taskListElementId: PropTypes.string,
    connectDragSource: PropTypes.func,

    allowDrag: PropTypes.bool,

    moveToTrashHandler: PropTypes.func,
    changeIndentationHandler: PropTypes.func,
    goToPreviousTaskHandler: PropTypes.func,
    goToNextTaskHandler: PropTypes.func,
    onStartBackspaceHandler: PropTypes.func,
    onEndDeleteHandler: PropTypes.func,

    depth: PropTypes.number,
    noTitle: PropTypes.bool,

    isFirstEntry: PropTypes.bool,

    taskListOnMouseDown: PropTypes.func,
    taskListOnContextMenu: PropTypes.func,
    onCheckboxClick: PropTypes.func,

    childElementIds: PropTypes.array,
    onTaskToggle: PropTypes.func,
    currentBoardId: PropTypes.string,

    permissions: PropTypes.number,
    isPresentational: PropTypes.bool,
    isDragPreview: PropTypes.bool,
    setParentHoveredChildAcceptsDrop: PropTypes.func,
    getContextZoomScale: PropTypes.func,

    selectParentTaskList: PropTypes.func,
    isParentTaskListSelected: PropTypes.bool,
};

export default Task;
