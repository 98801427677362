// Lib
import DefaultCodeBlock from '@tiptap/extension-code-block';
import { mergeAttributes } from '@tiptap/core';

// Utils
import { findAdjacentPositions } from '../utils/tiptapOperations';

// Types
import { NodeRange } from '@tiptap/pm/model';
import { EditorState } from '@tiptap/pm/state';
import { TiptapDispatch, TiptapNodeType } from '../tiptapTypes';

/**
 * Merges adjacent code blocks by inserting a newline between them.
 *
 * This prevents multiple code blocks from being created when the user
 * toggles the type of the block to be a code block.
 */
const mergeCodeBlocks = (state: EditorState, dispatch?: TiptapDispatch): boolean => {
    if (!dispatch) return true;

    const { tr } = state;

    const nodeRange = new NodeRange(tr.selection.$from, tr.selection.$to, 1);
    const adjacentCodeBlockPositions = findAdjacentPositions(state, TiptapNodeType.codeBlock, nodeRange);

    const initialStepsCount = state.tr.steps.length;

    adjacentCodeBlockPositions.forEach((start) => {
        // Only map positions that have changed while performing the following operations
        const mappedStart = tr.mapping.slice(initialStepsCount).map(start);
        tr.insertText('\n', mappedStart - 1, mappedStart + 1);
    });

    return true;
};

export const CodeBlock = DefaultCodeBlock.extend({
    addCommands() {
        return {
            ...this.parent?.(),
            setCodeBlock:
                (attributes) =>
                ({ commands, state }) => {
                    const isCommandApplied = commands.setNode(this.name, attributes);

                    if (isCommandApplied) mergeCodeBlocks(state, this.editor.view.dispatch);

                    return isCommandApplied;
                },
            toggleCodeBlock:
                (attributes) =>
                ({ commands, editor }) => {
                    if (!editor.isActive(this.name)) {
                        return commands.setCodeBlock(attributes);
                    }

                    return commands.toggleNode(this.name, 'paragraph', attributes);
                },
        };
    },

    /**
     * This overrides the default CodeBlock extension to add a <span> around the
     * code content.
     * This is used to allow the code content to be horizontally scrollable.
     */
    renderHTML({ node, HTMLAttributes }) {
        return [
            'pre',
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
            [
                'code',
                {
                    class: node.attrs.language ? this.options.languageClassPrefix + node.attrs.language : null,
                },
                ['span', { class: 'code-content' }, 0],
            ],
        ];
    },

    addKeyboardShortcuts() {
        return {
            ...this.parent?.(),
            /* eslint-disable @typescript-eslint/naming-convention */
            'Mod-.': () => this.editor.commands.toggleCodeBlock(),
            'Mod->': () => this.editor.commands.toggleCodeBlock(),
        };
    },
});
