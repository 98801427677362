// Utils
import { ImMNElement, MNElement } from '../../../common/elements/elementModelTypes';
import { getAutoHeight, getMaxHeight, getMinHeight } from '../../../common/elements/utils/elementPropertyUtils';

interface CardDimensionProperties {
    minHeight?: number;
    maxHeight?: number;
}

export const getIsAutoHeight = (element: ImMNElement | MNElement): boolean =>
    !!getAutoHeight(element) || (!getMaxHeight(element) && !getMinHeight(element));

export const isHeightRestricted = (element: ImMNElement | MNElement): boolean =>
    !getAutoHeight(element) && !!getMaxHeight(element);

export const getCardDimensionPropertiesPx = ({
    element,
    gridSize,
}: {
    element: ImMNElement | MNElement;
    gridSize: number;
}): CardDimensionProperties => {
    // If min height return min-height
    const savedMinHeight = getMinHeight(element);
    if (savedMinHeight) return { minHeight: savedMinHeight * gridSize };

    const savedMaxHeight = getMaxHeight(element);
    if (savedMaxHeight) return { maxHeight: savedMaxHeight * gridSize };

    // If not overflowed and no min height or no max height, just size the card based on the contents
    return {};
};

// Really 2.5, but a draft div has 9px of padding which isn't included in the container padding
const CARD_PADDING_GRID_UNITS = 3.4;

// Calculate how far the text extends past its container. Return a negative number when the container is larger than the text.
export const getEditorOverflowDistance = (containerMaxHeight: number, editorHeight: number, gridSize: number): number =>
    editorHeight - (containerMaxHeight - CARD_PADDING_GRID_UNITS * gridSize);

export const getIsEditorOverThreshold = (containerMaxHeight: number, editorHeight: number, gridSize: number): boolean =>
    getEditorOverflowDistance(containerMaxHeight, editorHeight, gridSize) > 0;
