// Lib
import DefaultTextStyle from '@tiptap/extension-text-style';

/**
 * Ensures that highlights won't be applied to coloured text.
 */
export const TextStyle = DefaultTextStyle.extend({
    // Excluding both means that the coloured text won't be applied to highlighted text and
    //  multiple colours also can't be applied to the same text.
    excludes: 'textStyle highlight',
});
