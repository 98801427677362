import React, { ComponentType, Ref, useCallback, useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import shallowEqual from 'shallowequal';

import { ImMNElement } from '../../../../common/elements/elementModelTypes';
import { ElementType } from '../../../../common/elements/elementTypes';
import { currentlyEditingThisEditor } from '../../../components/editor/milanoteEditor/editorPropComparisons';
import { multiRef } from '../../../utils/multiRef';
import {
    getCardDimensionPropertiesPx,
    getIsAutoHeight,
    getIsEditorOverThreshold,
    isHeightRestricted,
} from '../cardSizeUtil';

interface EditorOverflowObserverProps {
    editorRef: Ref<HTMLElement>;
    containerRef: Ref<HTMLElement>;
    isEditing: boolean;
    isResizing: boolean;
    gridSize: number;
    element: ImMNElement;
    currentEditorKey: string;
}

export default <DecoratedProps extends EditorOverflowObserverProps>(
    DecoratedComponent: ComponentType<DecoratedProps>,
) => {
    const editorOverflowObserver = (props: DecoratedProps) => {
        const { isResizing, gridSize, element, isEditing, currentEditorKey, editorRef, containerRef } = props;
        const [isOverflowed, setIsOverflowed] = useState(false);

        // these are implemented as state rather than refs because we
        // need to trigger the useEffects when they change
        const [containerHtmlElement, setContainerHtmlElement] = useState<HTMLElement | null>(null);
        const [editorHtmlElement, setEditorHtmlElementInner] = useState<HTMLElement | null>(null);
        const setEditorHtmlElement = (editor: HTMLElement | null) => {
            // TODO-TIPTAP - getTextDomElement is just to support Draft; this can be removed when it's gone
            setEditorHtmlElementInner((editor as any)?.getTextDomElement?.() || editor);
        };

        // Track the heights as a single object - with two useState(0)'s, we'd need to set them individually,
        // and let their dependant useEffects run unnecessarily (and inaccurately) in between.
        const [cachedHeights, setCachedHeightsInner] = useState({ editorHeight: 0, containerHeight: 0 });
        const setCachedHeights = useCallback(
            (editorHeight: number | undefined, containerHeight: number | undefined) => {
                setCachedHeightsInner((cached) => {
                    const updated = {
                        editorHeight: editorHeight || cached.editorHeight,
                        containerHeight: containerHeight || cached.containerHeight,
                    };
                    if (shallowEqual(updated, cached)) return cached;
                    return updated;
                });
            },
            [],
        );

        // Read heights immediately on mount, and whenever the user starts/stops editing
        const currentlyEditing = currentlyEditingThisEditor(props);
        const heightRestricted = isHeightRestricted(element);
        const { maxHeight } = getCardDimensionPropertiesPx({ element, gridSize }) || 0;
        useEffect(() => {
            if (!editorHtmlElement) return;
            if (!heightRestricted) return;

            const editorHeight = editorHtmlElement.getBoundingClientRect().height;

            setCachedHeights(editorHeight, maxHeight);
        }, [maxHeight, gridSize, editorHtmlElement, currentlyEditing, heightRestricted]);

        // Attach a resize observer during the resize action
        useEffect(() => {
            if (!editorHtmlElement || !containerHtmlElement || !isResizing) return;

            const observer = new ResizeObserver((entries) => {
                const editorEntry = entries.find((entry) => entry.target === editorHtmlElement);
                const containerEntry = entries.find((entry) => entry.target === containerHtmlElement);
                setCachedHeights(editorEntry?.contentRect.height, containerEntry?.contentRect.height);
            });

            observer.observe(editorHtmlElement);
            observer.observe(containerHtmlElement);

            return () => observer.disconnect();
        }, [isResizing, containerHtmlElement, editorHtmlElement]);

        // Update the overflow status whenever the recorded heights change
        useEffect(() => {
            const { containerHeight, editorHeight } = cachedHeights;
            if (!containerHeight || !editorHeight) return;
            const isOverThreshold = getIsEditorOverThreshold(containerHeight, editorHeight, gridSize);
            setIsOverflowed(isOverThreshold);
        }, [cachedHeights, gridSize]);

        // Determine whether to actually show the overflow display
        const isEditingMainCardContent = isEditing && currentEditorKey === ElementType.CARD_TYPE;
        const isAutoHeight = getIsAutoHeight(element);
        const showEditorOverflow = (() => {
            if (!isOverflowed) return false;
            if (isResizing) return true;
            if (isEditingMainCardContent) return false;
            if (isAutoHeight) return false;
            return true;
        })();

        return (
            <DecoratedComponent
                {...props}
                editorOverflowed={showEditorOverflow}
                editorRef={multiRef([setEditorHtmlElement, editorRef])}
                containerRef={multiRef([setContainerHtmlElement, containerRef])}
            />
        );
    };
    return editorOverflowObserver;
};
