// Lib
import { defer } from 'lodash/fp';

// Utils
import { isEmpty, propIn } from '../../../common/utils/immutableHelper';
import mergeEditorJson from '../../../common/tiptap/utils/jsonContentUtils/manipulation/mergeEditorJson';
import rawGetFocusIndexSelection from '../../../common/utils/editor/rawUtils/rawGetFocusIndexSelection';
import {
    getElementId,
    getLocationParentId,
    getTextContent,
    getTitle,
} from '../../../common/elements/utils/elementPropertyUtils';
import { getListChildNewLocation } from '../../../common/elements/utils/elementLocationUtils';
import {
    getDepth,
    getElement,
    getGrandparent,
    getNthPhysicalAncestor,
    getParent,
} from '../../../common/elements/utils/elementTraversalUtils';
import { isTask, isTaskLike, isTaskList } from '../../../common/elements/utils/elementTypeUtils';
import {
    getAdjacentTask,
    getNextTask,
    getPreviousTask,
    getTaskListAncestorId,
} from '../../../common/taskLists/taskListUtils';
import { sortByScoreProperty } from '../../../common/elements/utils/elementSortUtils';
import { depthFirstTraversal, getChildIds } from '../../../common/dataStructures/graphUtils';
import {
    buildOrderedSubGraph,
    getChildrenViaGraph,
    getDescendantsViaGraph,
    getPreviousSiblingViaGraph,
    getSortedChildrenInSectionViaGraph,
} from '../../../common/elements/utils/elementGraphUtils';
import { getShowTitle } from './taskListSelector';
import rawGetFocusStartSelection from '../../../common/utils/editor/rawUtils/rawGetFocusStartSelection';
import { getMainEditorId, getMainEditorKey } from '../utils/elementEditorUtils';
import { getNewTransactionId } from '../../utils/undoRedo/undoRedoTransactionManager';
import { getIsTaskComplete } from '../task/taskSelector';

// Actions
import { moveMultipleElements } from '../actions/elementMoveActions';
import {
    deselectAllElements,
    finishEditingElement,
    setSelectedElements,
    startEditingElement,
} from '../selection/selectionActions';
import { moveElementsToTrash } from '../actions/elementShortcutActions';
import {
    createAndEditElement,
    createAndSelectElement,
    createElementSync,
    updateElement,
} from '../actions/elementActions';
import doesEditorJsonHaveText from '../../../common/tiptap/utils/jsonContentUtils/doesEditorJsonHaveText';

// Selectors
import { getElements } from '../selectors/elementSelector';
import { elementGraphSelector } from '../selectors/elementGraphSelector';
import { getCurrentBoardId } from '../../reducers/currentBoardId/currentBoardIdSelector';
import { getCurrentlyEditingId } from '../selection/currentlyEditingSelector';

// Constants
import { SHOW_TITLE_PROPERTY } from '../../../common/taskLists/taskListConstants';
import { BoardSections } from '../../../common/boards/boardConstants';
import { ELEMENT_MOVE_OPERATIONS } from '../../../common/elements/elementConstants';
import { ElementType } from '../../../common/elements/elementTypes';

export const MAX_TASK_DEPTH = 6;

export const createTaskList = (args) => (dispatch, getState) => {
    const { location, content, currentBoardId, transactionId = getNewTransactionId(), creationSource } = args;

    const state = getState();
    const elements = getElements(state);

    const { parentId } = location;
    const parent = getElement(elements, parentId);

    // If parent ID is a Task or TaskList then don't create the task list, only the task and select the parent
    if (isTaskLike(parent)) {
        const taskListId = isTask(parent) ? parent : getTaskListAncestorId(elements, parentId);

        dispatch(setSelectedElements({ ids: [taskListId] }));

        return dispatch(
            createAndEditElement({
                elementType: ElementType.TASK_TYPE,
                location,
                transactionId,
                select: false,
            }),
        );
    }

    return dispatch(
        createAndSelectElement({
            elementType: ElementType.TASK_LIST_TYPE,
            location,
            content,
            currentBoardId,
            transactionId,
            creationSource,
        }),
    ).then((taskListId) =>
        dispatch(
            createAndEditElement({
                elementType: ElementType.TASK_TYPE,
                location: getListChildNewLocation({ listId: taskListId }),
                transactionId,
                creationSource,
                select: false,
            }),
        ),
    );
};

export const createAndEditNewTaskList = (args) => (dispatch, getState) =>
    dispatch(
        createAndEditElement({
            ...args,
            content: {
                [SHOW_TITLE_PROPERTY]: true,
                ...args.content,
            },
            elementType: ElementType.TASK_LIST_TYPE,
            select: true,
        }),
    ).then((taskListElementId) => {
        const location = getListChildNewLocation({ listId: taskListElementId });

        dispatch(
            createElementSync({
                elementType: ElementType.TASK_TYPE,
                location,
                currentBoardId: args.currentBoardId,
                select: true,
            }),
        );

        return taskListElementId;
    });

export const createNextNewTask =
    ({
        elementId,
        transactionId = getNewTransactionId(),
        insertBefore,
        content,
        selection,
        dispatchSaveEditorSelection,
        isFocusedForegroundElement,
    }) =>
    (dispatch, getState) => {
        const state = getState();

        const currentBoardId = getCurrentBoardId(state);
        const elements = getElements(state);
        const element = getElement(elements, elementId);
        const parentId = getLocationParentId(element);

        // Current index
        const elementGraph = elementGraphSelector(state);

        const siblings = getSortedChildrenInSectionViaGraph({
            elements,
            elementGraph,
            parentId,
            sortFn: sortByScoreProperty,
            // We want to ignore attached comments
            section: BoardSections.INBOX,
        }).toList();

        const currentTaskIndex = siblings.indexOf(element);

        const newTaskIndex = insertBefore ? currentTaskIndex : currentTaskIndex + 1;

        const location = getListChildNewLocation({ listId: parentId, index: newTaskIndex });

        // Create the new task
        const newTaskId = dispatch(
            createElementSync({
                elementType: ElementType.TASK_TYPE,
                location,
                currentBoardId,
                select: !insertBefore,
                transactionId,
                content,
            }),
        );

        // Move the current task's children to the new task
        const childrenIds = getChildIds({ graph: elementGraph, parentId: elementId });

        // If we're inserting before (pressing enter at the start of a populated task),
        // then the children don't need to moved to a new parent.
        // They can be left on the current task while a new task is added above
        if (!insertBefore) {
            const moves = childrenIds
                // Comments can now be children of tasks, so we don't want to move them to the new task
                .filter((childId) => {
                    const child = getElement(elements, childId);
                    return isTask(child);
                })
                .map((childId, index) => ({
                    id: childId,
                    location: getListChildNewLocation({ listId: newTaskId, index }),
                }));

            if (!isEmpty(moves)) {
                dispatch(moveMultipleElements({ moves, transactionId, moveOperation: ELEMENT_MOVE_OPERATIONS.TASK }));
            }
        }

        const newState = getState();
        const newElements = getElements(newState);
        const newTask = getElement(newElements, newTaskId);

        const editorId = getMainEditorId({ element: newTask, isFocusedForegroundElement });

        if (selection && dispatchSaveEditorSelection) {
            dispatchSaveEditorSelection({ editorId, selection });
        }

        if (!insertBefore) {
            const editNewTask = () => {
                dispatch(
                    startEditingElement({
                        id: newTaskId,
                        editorId,
                        editorKey: getMainEditorKey({ element: newTask, isFocusedForegroundElement }),
                        transactionId,
                        canUndo: true,
                    }),
                );
            };

            defer(editNewTask);
        }

        return newTaskId;
    };

export const goToAdjacentTask =
    (forward) =>
    ({ elementId, dispatchSaveEditorSelection, isFocusedForegroundElement, isTiptapEditor }) =>
    (dispatch, getState) => {
        const state = getState();
        const elements = getElements(state);

        const elementGraph = elementGraphSelector(state);
        const nowEditingTaskElement = getAdjacentTask(forward)({ elementId, elements, elementGraph });

        if (!nowEditingTaskElement) return false;

        const isNowEditingTaskList = isTaskList(nowEditingTaskElement);
        const editorId = getMainEditorId({ element: nowEditingTaskElement, isFocusedForegroundElement });
        const editorKey = getMainEditorKey({ element: nowEditingTaskElement, isFocusedForegroundElement });

        // If attempting to edit the taskList make sure it has a title to edit
        if (isNowEditingTaskList && !getShowTitle(nowEditingTaskElement)) return false;

        // Tiptap editors will handle the selection themselves
        // TODO-TIPTAP - remove with Draft
        if (forward && !isTiptapEditor) {
            const nowEditingTextContent = getTextContent(nowEditingTaskElement);
            const selection = rawGetFocusStartSelection(nowEditingTextContent);
            dispatchSaveEditorSelection && dispatchSaveEditorSelection({ editorId, selection });
        }

        // start editing that element
        const editTask = () => {
            dispatch(
                startEditingElement({
                    id: getElementId(nowEditingTaskElement),
                    editorId,
                    editorKey,
                }),
            );
        };

        editTask();

        return true;
    };

export const goToPreviousTask = goToAdjacentTask(false);
export const goToNextTask = goToAdjacentTask(true);

export const moveTaskToTrash =
    ({
        currentBoardId,
        elementId,
        focusAnotherTask,
        forward,
        transactionId = getNewTransactionId(),
        isFocusedForegroundElement,
    }) =>
    (dispatch, getState) => {
        const state = getState();
        const currentlyEditingId = getCurrentlyEditingId(state);

        const keepEditing = elementId === currentlyEditingId;

        const elements = getElements(state);

        const taskListId = getTaskListAncestorId(elements, elementId);

        const taskElementToDelete = getElement(elements, elementId);

        const elementGraph = elementGraphSelector(state);
        const immediateTaskListChildren = getChildrenViaGraph({
            elements,
            elementGraph,
            parentId: taskListId,
        }).toList();
        const taskToDeleteIndex = immediateTaskListChildren.indexOf(taskElementToDelete);

        // Deleting the only remaining child task of the task list, so delete the entire task instead
        if (immediateTaskListChildren.size === 1 && taskToDeleteIndex !== -1) {
            const taskList = getElement(elements, taskListId);

            // If showing the title & has title then just return focus to the task list title
            if (getShowTitle(taskList) && !!getTitle(taskList)) {
                dispatch(
                    moveElementsToTrash({
                        currentBoardId,
                        elementId,
                        keepSelection: true,
                        transactionId,
                    }),
                );

                const editTask = () => {
                    dispatch(
                        startEditingElement({
                            id: getElementId(taskList),
                            editorId: getMainEditorId({ element: taskList, isFocusedForegroundElement }),
                            editorKey: getMainEditorKey({ element: taskList, isFocusedForegroundElement }),
                            transactionId,
                        }),
                    );
                };

                keepEditing && editTask();

                return;
            }

            return dispatch(
                moveElementsToTrash({
                    currentBoardId,
                    elementId: taskListId,
                    keepSelection: false,
                    transactionId,
                }),
            );
        }

        let nowEditingTaskElement = forward
            ? getNextTask({ elementId, elements, elementGraph }) ||
              getPreviousTask({ elementId, elements, elementGraph })
            : getPreviousTask({ elementId, elements, elementGraph }) ||
              getNextTask({ elementId, elements, elementGraph });

        // If we're back to the root, choose the next task instead
        if (isTaskList(nowEditingTaskElement)) {
            nowEditingTaskElement = getNextTask({ elementId, elements, elementGraph });
        }

        const nowEditingId = getElementId(nowEditingTaskElement);

        // If the current task has children - move the children to the previous task
        const childIds = getChildIds({ graph: elementGraph, parentId: elementId });

        if (!isEmpty(childIds) && nowEditingId) {
            const moves = childIds.map((childId, index) => ({
                id: childId,
                location: getListChildNewLocation({ listId: nowEditingId, index }),
            }));

            dispatch(moveMultipleElements({ moves, transactionId, moveOperation: ELEMENT_MOVE_OPERATIONS.TASK }));
        }

        // We need this to happen before the move to trash so that editing on undo works (because the
        // actions are reversed and the task needs to be moved back into the task list so it can be focused).
        if (nowEditingTaskElement && keepEditing) {
            const editTask = () => {
                dispatch(
                    startEditingElement({
                        id: nowEditingId,
                        editorId: getMainEditorId({ element: nowEditingTaskElement, isFocusedForegroundElement }),
                        editorKey: getMainEditorKey({ element: nowEditingTaskElement, isFocusedForegroundElement }),
                        transactionId,
                        canUndo: true,
                    }),
                );
            };

            editTask();
        }

        dispatch(moveElementsToTrash({ currentBoardId, elementId, keepSelection: true, transactionId }));
    };

export const indentTask =
    ({ elementId, dispatchSaveCurrentSelection }) =>
    (dispatch, getState) => {
        dispatchSaveCurrentSelection();

        const state = getState();
        const elements = getElements(state);

        const taskListId = getTaskListAncestorId(elements, elementId);

        const elementGraph = elementGraphSelector(state);
        const previousSibling = getPreviousSiblingViaGraph({
            elements,
            elementGraph,
            elementId,
            sortFn: sortByScoreProperty,
        });

        // If there's no previous sibling then we can't indent this task
        if (!previousSibling) return;
        const currentDepth = getDepth(elements, taskListId, elementId);

        const remainingDepth = MAX_TASK_DEPTH - currentDepth;

        // Can't indent when we're already at the max depth
        if (remainingDepth < 1) return;

        // Do depth first search from the task, then figure out what moves are required in order to respect the
        // maximum depth
        const taskListGraph = buildOrderedSubGraph({
            elements,
            elementGraph,
            startElementId: elementId,
            elementSortFn: sortByScoreProperty,
        });
        const dfsResult = depthFirstTraversal(taskListGraph, elementId);

        const previousSiblingId = getElementId(previousSibling);
        const siblingChildrenCount = getChildrenViaGraph({ elements, elementGraph, parentId: previousSiblingId }).size;

        let moves = null;
        // If we only have a remaining depth of 1, then when this element is indented it will be at the max depth.
        // Thus all of its children need to be moved to this element's parent
        if (remainingDepth === 1) {
            moves = dfsResult.map((dfsEntry, index) => ({
                id: dfsEntry.id,
                location: getListChildNewLocation({ listId: previousSiblingId, index: siblingChildrenCount + index }),
            }));
        } else {
            // Otherwise we need to find all the tasks whose depth from the current task is larger than or equal to the
            // remaining depth, because these tasks will need to be "un-indented" so that they do not exceed the max depth.
            moves = dfsResult
                .filter((dfsEntry) => dfsEntry.depth >= remainingDepth)
                .reduce(
                    (currMoves, dfsEntry) => {
                        // If this task exceeds the max depth then it needs to be moved to its grandparent plus the amount it
                        // exceeds the max depth by.  For example, if the depth equals the max depth, then it should be moved
                        // to its grandparent.  In the unlikely scenario that the task exceeds the max depth by 1 it needs to
                        // be moved to the great grandparent to ensure that the max depth is no longer exceeded.
                        const ancestor = getNthPhysicalAncestor(dfsEntry.depth - remainingDepth + 2)(
                            elements,
                            dfsEntry.id,
                        );
                        const ancestorId = getElementId(ancestor);
                        const ancestorChildrenCount = getChildrenViaGraph({
                            elements,
                            elementGraph,
                            parentId: ancestorId,
                        }).size;

                        const alreadyMovingToGrandparentCount = currMoves.filter(
                            (move) => move.location.parentId === ancestorId,
                        ).length;

                        // The index is important to ensure that tasks remain visually where they are supposed to be in the list
                        const index = ancestorChildrenCount + alreadyMovingToGrandparentCount;

                        currMoves.push({
                            id: dfsEntry.id,
                            location: getListChildNewLocation({ listId: ancestorId, index }),
                        });

                        return currMoves;
                    },
                    [
                        {
                            id: elementId,
                            location: getListChildNewLocation({
                                listId: previousSiblingId,
                                index: siblingChildrenCount,
                            }),
                        },
                    ],
                );
        }

        dispatch(moveMultipleElements({ moves, moveOperation: ELEMENT_MOVE_OPERATIONS.TASK }));
    };

export const unIndentTask =
    ({ elementId, dispatchSaveCurrentSelection }) =>
    (dispatch, getState) => {
        dispatchSaveCurrentSelection();

        const state = getState();
        const elements = getElements(state);

        const element = getElement(elements, elementId);
        const parent = getParent(elements, elementId);
        const grandparent = getGrandparent(elements, elementId);

        // If there's no grandparent (should never happen) or the grandparent is not a task or task list
        // we can't un-indent it
        if (!parent || !grandparent || !(isTaskList(grandparent) || isTask(grandparent))) return;

        const elementGraph = elementGraphSelector(state);

        const grandparentId = getElementId(grandparent);
        const grandparentChildren = getChildrenViaGraph({ elements, elementGraph, parentId: grandparentId })
            .sort(sortByScoreProperty)
            .toList();

        const parentIndex = grandparentChildren.indexOf(parent);

        const moves = [
            {
                id: elementId,
                location: getListChildNewLocation({ listId: grandparentId, index: parentIndex + 1 }),
            },
        ];

        // Now get the siblings of the element that's being un-indented
        // Any of the siblings below the current element should become children of the current element
        const parentId = getElementId(parent);
        const initialSiblings = getChildrenViaGraph({ elements, elementGraph, parentId })
            .sort(sortByScoreProperty)
            .toList();

        const elementIndex = initialSiblings.indexOf(element);

        const currentElementChildIds = getChildIds({ graph: elementGraph, parentId: elementId });

        const newChildren = initialSiblings.filter((el, index) => index > elementIndex);

        newChildren.forEach((el, index) => {
            moves.push({
                id: getElementId(el),
                location: getListChildNewLocation({ listId: elementId, index: index + currentElementChildIds.length }),
            });
        });

        dispatch(moveMultipleElements({ moves, moveOperation: ELEMENT_MOVE_OPERATIONS.TASK }));
    };

export const getCompletedTasks =
    ({ taskListId }) =>
    (dispatch, getState) => {
        const state = getState();

        const elements = getElements(state);
        const elementGraph = elementGraphSelector(state);

        const descendantTasks = getDescendantsViaGraph({ elements, elementGraph, parentId: taskListId });

        return descendantTasks.filter((el) => isTask(el) && getIsTaskComplete(el));
    };

export const moveCompletedTasksToTrash =
    ({ taskListId }) =>
    (dispatch, getState) => {
        const completedTasks = dispatch(getCompletedTasks({ taskListId }));

        if (!completedTasks || !completedTasks.size) return;

        const state = getState();
        const currentBoardId = getCurrentBoardId(state);

        dispatch(
            moveElementsToTrash({
                elements: completedTasks.toList(),
                currentBoardId,
            }),
        );
    };

export const onSubmitTaskTitle =
    ({ taskListId }) =>
    (dispatch, getState) => {
        const state = getState();

        const elements = getElements(state);
        const elementGraph = elementGraphSelector(state);

        const nextTask = getNextTask({ elements, elementGraph, elementId: taskListId });

        if (!nextTask) return dispatch(deselectAllElements());

        const editorContent = getTextContent(nextTask);
        const hasText = doesEditorJsonHaveText(editorContent);

        return hasText ? dispatch(deselectAllElements()) : dispatch(goToNextTask({ elementId: taskListId }));
    };

// ---- Backspace / delete handlers ----

export const joinToPreviousTask =
    ({ elementId, textContent, dispatchSaveEditorSelection, currentBoardId, isEditable, isFocusedForegroundElement }) =>
    (dispatch, getState) => {
        const state = getState();
        const elements = getElements(state);

        // Get previous element
        const elementGraph = elementGraphSelector(state);
        const previousTask = getPreviousTask({ elements, elementId, elementGraph });

        // If not task stop
        if (!isTask(previousTask)) return;

        const previousTaskId = getElementId(previousTask);

        // Append text to that element
        const initialTextContent = getTextContent(previousTask);
        const newTextContent = mergeEditorJson(initialTextContent, textContent);

        const transactionId = getNewTransactionId();

        dispatch(
            updateElement({
                id: previousTaskId,
                changes: {
                    textContent: newTextContent,
                },
                transactionId,
            }),
        );

        dispatch(moveElementsToTrash({ isEditable, currentBoardId, elementId, keepSelection: true, transactionId }));

        // Move the current task's children to be children of the previous task that we're joining
        const currentTaskChildren = getChildrenViaGraph({ elements, elementGraph, parentId: elementId })
            .sort(sortByScoreProperty)
            .toList();

        if (!isEmpty(currentTaskChildren)) {
            const moves = currentTaskChildren
                .map((el, index) => ({
                    id: getElementId(el),
                    location: getListChildNewLocation({ listId: previousTaskId, index }),
                }))
                .toArray();

            dispatch(moveMultipleElements({ moves, transactionId, moveOperation: ELEMENT_MOVE_OPERATIONS.TASK }));
        }

        const editorId = getMainEditorId({ element: previousTask, isFocusedForegroundElement });

        // TODO-TIPTAP - Remove this on Draft.js removal
        const initialText = propIn(['blocks', 0, 'text'], initialTextContent);
        const initialTextContentLength = initialText ? initialText.length : 0;

        const selection = rawGetFocusIndexSelection(newTextContent, initialTextContentLength);
        dispatchSaveEditorSelection({ editorId, selection });
        // End TODO

        const editTask = () => {
            dispatch(
                startEditingElement({
                    id: getElementId(previousTask),
                    editorId,
                    editorKey: getMainEditorKey({ element: previousTask, isFocusedForegroundElement }),
                }),
            );
        };

        editTask();
    };

export const joinNextTaskToThis =
    ({ elementId, textContent, dispatchSaveEditorSelection, currentBoardId, isEditable, isFocusedForegroundElement }) =>
    (dispatch, getState) => {
        const state = getState();
        const elements = getElements(state);

        const thisTask = getElement(elements, elementId);

        // Get previous element
        const elementGraph = elementGraphSelector(state);
        const nextTask = getNextTask({ elements, elementId, elementGraph });

        // If not task stop
        if (!isTask(nextTask)) return;

        const nextTaskId = getElementId(nextTask);

        // Move the current task's children to be children of the previous task that we're joining
        const nextTaskChildren = getChildrenViaGraph({ elements, elementGraph, parentId: nextTaskId })
            .sort(sortByScoreProperty)
            .toList();

        // Append text to this element
        const appendingTextContent = getTextContent(nextTask);
        const newTextContent = mergeEditorJson(textContent, appendingTextContent);

        const transactionId = getNewTransactionId();

        // Need to stop editing the element and defer the update to ensure the update is reflected in the Milanote Editor
        // The Milanote editor only updates its state if it's not currently being edited
        dispatch(finishEditingElement(elementId));

        defer(() => {
            dispatch(
                updateElement({
                    id: elementId,
                    changes: {
                        textContent: newTextContent,
                    },
                    transactionId,
                }),
            );

            dispatch(
                moveElementsToTrash({
                    isEditable,
                    currentBoardId,
                    elementId: nextTaskId,
                    keepSelection: true,
                    transactionId,
                }),
            );

            if (!isEmpty(nextTaskChildren)) {
                const moves = nextTaskChildren
                    .map((el, index) => ({
                        id: getElementId(el),
                        location: getListChildNewLocation({ listId: elementId, index }),
                    }))
                    .toArray();

                dispatch(moveMultipleElements({ moves, transactionId, moveOperation: ELEMENT_MOVE_OPERATIONS.TASK }));
            }

            const editorId = getMainEditorId({ element: thisTask, isFocusedForegroundElement });

            // TODO-TIPTAP - Remove this on Draft.js removal
            const initialText = propIn(['blocks', 0, 'text'], textContent);
            const initialTextContentLength = initialText ? initialText.length : 0;

            const selection = rawGetFocusIndexSelection(newTextContent, initialTextContentLength);
            dispatchSaveEditorSelection({ editorId, selection });
            // End TODO

            dispatch(
                startEditingElement({
                    id: getElementId(thisTask),
                    editorId,
                    editorKey: getMainEditorKey({ element: thisTask, isFocusedForegroundElement }),
                }),
            );
        });
    };
