import { clamp } from 'lodash';

// Utils
import platformSingleton from '../../../platform/platformSingleton';
import { focusFakeInput } from '../../../utils/ipad/ipadUtils';
import { getDomElementId } from '../../../element/utils/elementUtil';
import { animateScrollPromise } from '../../../utils/animation/animateScrollIntoView';
import { getElementType } from '../../../../common/elements/utils/elementPropertyUtils';
import { translateDOMRectIntoScrollableElementCoordinates } from '../../../utils/dom/scrollableElementUtils';
import { isPlatformIos } from '../../../platform/utils/platformDetailsUtils';
import { applyScrollExcessPadding } from './scrollExcessUtils';
import getParentScrollableSection from '../../../utils/dom/getParentScrollableSection';
import { isInUnsortedNotes } from '../../../../common/elements/utils/elementLocationUtils';
import { prop } from '../../../../common/utils/immutableHelper';

// Selectors
import { getElement } from '../../../element/selectors/elementSelector';
import { getMilanoteApplicationModeSelector } from '../../../platform/platformSelector';
import { getSelectedElementIds } from '../../../element/selection/selectedElementsSelector';
import { getCurrentBoardId, getCurrentBoardVisibleDescendants } from '../../../element/selectors/currentBoardSelector';

// Constants
import { ELEMENT_EDIT_START } from '../../../../common/elements/selectionConstants';

// Types
import { Capacitor } from '@capacitor/core';
import { UnknownAction } from 'redux';
import { ReduxStore } from '../../../types/reduxTypes';
import { MNElement } from '../../../../common/elements/elementModelTypes';
import { Rect } from '../../../../common/maths/geometry/rect/rectTypes';
import { ElementType } from '../../../../common/elements/elementTypes';
import { MilanoteApplicationMode } from '../../../platform/platformTypes';
import { SheetActionTypes } from '../../structural/sheet/SheetActionTypes';

type ScrollPosition = 'start' | 'end' | { x: number; y: number };
type ScrollToElementOptions = {
    elementId: string;
    element: MNElement;
    scrollPosition: ScrollPosition;
};

const SCROLL_TO_PADDING = 50;

/**
 * Horizontally centers the element that's being edited.
 */
const getTargetScrollLeft = (
    scrollableElement: HTMLElement,
    elementRect: Rect,
    windowRect: Rect,
    defaultScrollPosition: ScrollPosition,
) => {
    // If the element is larger than the window, and the user clicked on a TipTap editor, we want to scroll
    // to the position where the user clicked on
    const isElementLargerThanWindow = elementRect.width >= windowRect.width;
    if (isElementLargerThanWindow) {
        if (defaultScrollPosition === 'start' || defaultScrollPosition === 'end')
            return elementRect.left - SCROLL_TO_PADDING;

        return clamp(
            scrollableElement.scrollLeft + defaultScrollPosition.x - 100,
            elementRect.right - windowRect.width + SCROLL_TO_PADDING,
        );
    }

    // Otherwise, we want to scroll to the center of the element
    const centerOffsetX = (windowRect.width - elementRect.width) / 2;

    return elementRect.left - centerOffsetX;
};

/**
 * Gets the target scrollTop property for the scrollable element.
 */
const getTargetScrollTop = (scrollableElement: HTMLElement, elementRect: Rect, scrollPosition: ScrollPosition) => {
    // FIXME-MOBILE - This is an approximation - the virtual keyboard and toolbar will take up ~ half of the screen,
    //  so we want the element to fill up half of the remaining area
    const offset = Math.round(window.innerHeight / 4);

    if (scrollPosition === 'start') return elementRect.top - SCROLL_TO_PADDING;

    // NOTE: We add 50 to the offset to account for the distance between elementRect.bottom and the cursor position
    if (scrollPosition === 'end') return elementRect.bottom + SCROLL_TO_PADDING - offset;

    // If given the exact point to scroll to, we want to scroll to a position slightly above the click position.
    // e.g. When the user click on a TipTap editor, it will pass editorFocusClientCoords, the point the user clicks on
    return scrollableElement.scrollTop + scrollPosition.y - offset;
};

/**
 * Determines the target scroll position for the scrollable element.
 */
const getTargetScroll = (scrollableElement: HTMLElement, elementRect: Rect, scrollPosition: ScrollPosition) => {
    const windowRect = scrollableElement.getBoundingClientRect();

    const targetScrollLeft = getTargetScrollLeft(scrollableElement, elementRect, windowRect, scrollPosition);
    const targetScrollTop = getTargetScrollTop(scrollableElement, elementRect, scrollPosition);

    const maxScrollLeft = scrollableElement.scrollWidth;
    const maxScrollTop = scrollableElement.scrollHeight;

    // Clamp the target scroll values to the scrollable element's bounds
    return {
        left: clamp(targetScrollLeft, 0, maxScrollLeft),
        top: clamp(targetScrollTop, 0, maxScrollTop),
    };
};

const getScrollToElementOptions = (state: any, action: UnknownAction): ScrollToElementOptions | undefined => {
    const applicationMode = getMilanoteApplicationModeSelector(state);
    if (applicationMode !== MilanoteApplicationMode.mobile) return;

    if (action.type === ELEMENT_EDIT_START) {
        const elementId = action.id as string;
        const editorFocusClientCoords = action.editorFocusClientCoords as { x: number; y: number } | undefined;

        let scrollPosition: ScrollPosition = editorFocusClientCoords || 'end';

        const element = getElement(state, { elementId });

        // For elements being edited at the top of the element, scroll to the top of the element
        const elementType = getElementType(element);
        if (elementType === ElementType.COLUMN_TYPE || elementType === ElementType.COLOR_SWATCH_TYPE) {
            scrollPosition = 'start';
        }

        return { elementId, element, scrollPosition };
    }

    if (action.type === SheetActionTypes.SHEET_OPEN && action.scrollToElement) {
        const elementId = prop(0, getSelectedElementIds(state));
        const element = getElement(state, { elementId });

        return {
            elementId: prop(0, getSelectedElementIds(state)),
            element,
            scrollPosition: 'start',
        };
    }
};

export default (store: ReduxStore) => (next: Function) => async (action: UnknownAction) => {
    const state = store.getState();

    const scrollToElementOptions = getScrollToElementOptions(state, action);
    if (!scrollToElementOptions) return next(action);

    const { elementId, element, scrollPosition } = scrollToElementOptions;

    const elementDomId = getDomElementId(elementId);
    const domElement = document.getElementById(elementDomId);

    if (!domElement) return next(action);

    const scrollableElement = getParentScrollableSection(domElement);
    if (!scrollableElement) return next(action);

    // FIXME-MOBILE - Unsorted notes would need its own logic to scroll to the element
    const currentBoardElements = getCurrentBoardVisibleDescendants(state);
    const currentBoardId = getCurrentBoardId(state);
    if (isInUnsortedNotes(currentBoardElements, currentBoardId)(element)) return next(action);

    // We need the focus event to happen immediately on iOS otherwise the element won't become editable
    // We don't want to do it on Android otherwise the keyboard might flash up then disappear then show again
    if (action.type === ELEMENT_EDIT_START && isPlatformIos(platformSingleton)) {
        const mobileWorkspaceElement = document.querySelector('.ModernMobileWorkspace');
        focusFakeInput({ x: 0, y: 0 }, mobileWorkspaceElement);
    }

    const elementRect = translateDOMRectIntoScrollableElementCoordinates(
        scrollableElement,
        domElement.getBoundingClientRect(),
    );

    const targetScroll = getTargetScroll(scrollableElement, elementRect, scrollPosition);

    applyScrollExcessPadding({
        scrollableElement,
        toScrollLeft: targetScroll.left,
        toScrollTop: targetScroll.top,
        interpolationFactor: 0.2,
    });

    const animateScrollArgs = {
        scrollableElement,
        fromScrollLeft: scrollableElement.scrollLeft,
        fromScrollTop: scrollableElement.scrollTop,
        toScrollLeft: targetScroll.left,
        toScrollTop: targetScroll.top,
        interpolationFactor: 0.2,
    };

    if (Capacitor.isPluginAvailable('Keyboard')) {
        // On capacitor apps, since we're using Capacitor Keyboard plugin, the built-in browser functionality to
        // scroll to input is disabled. So, we can dispatch ELEMENT_EDIT_START immediately without waiting for
        // animateScroll to finish. This will provide a smoother toolbar animation for the user.
        animateScrollPromise(animateScrollArgs);
    } else {
        // If on mobile web, the screen might shift while scrolling due to built-in browser functionality to scroll to input.
        // Therefore, we should wait for animateScroll to element to finish first before ELEMENT_EDIT_START is dispatched.
        await animateScrollPromise(animateScrollArgs);
    }

    next(action);
};
