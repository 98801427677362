// Lib
import * as Immutable from 'immutable';

// Utils
import { asRect } from '../../../../common/maths/geometry/rect';
import { getElementId } from '../../../../common/elements/utils/elementPropertyUtils';

// Constants
import { CURRENT_BOARD_ID_SET } from '../../../reducers/currentBoardId/currentBoardIdConstants';
import {
    ClipboardOperation,
    ELEMENT_CLIPBOARD_PASTE,
    ELEMENT_CLIPBOARD_SAVE,
    ELEMENT_CLIPBOARD_UNDO,
} from './clipboardConstants';

const initialState = Immutable.fromJS({
    operation: '',
    elements: {},
    cutElementsIdMap: {},
    pasteCount: 0,
    saveId: '',
    visibleOnCanvas: true,
    elementsBoundingRect: {},
});

const handleClipboardSave = (state, action) => {
    const cutElementsIdMap =
        action.operation === ClipboardOperation.cut && action.elements
            ? action.elements.reduce((acc, element) => {
                  const elementId = getElementId(element);
                  acc[elementId] = true;
                  return acc;
              }, {})
            : {};

    const elementsBoundingRect = Immutable.fromJS(asRect(action.elementsBoundingRect));

    return state
        .set('operation', action.operation)
        .set('elements', Immutable.fromJS(action.elements))
        .set('cutElementsIdMap', Immutable.fromJS(cutElementsIdMap))
        .set('pasteCount', action.pasteCount || 0)
        .set('saveId', action.saveId)
        .set('elementsBoundingRect', elementsBoundingRect)
        .set('timestamp', action.timestamp);
};

export default (state = initialState, action) => {
    switch (action.type) {
        // Clear the cutElementsIdMap on clipboard undo so the cut elements
        // won't be hidden any more
        case ELEMENT_CLIPBOARD_UNDO:
            return state.set('cutElementsIdMap', Immutable.fromJS({}));
        case ELEMENT_CLIPBOARD_SAVE:
            return handleClipboardSave(state, action);
        case ELEMENT_CLIPBOARD_PASTE:
            return state
                .set('operation', ClipboardOperation.copy)
                .set('pasteCount', action.pasteCount)
                .set('cutElementsIdMap', Immutable.fromJS({}))
                .set('visibleOnCanvas', action.visibleOnCanvas);
        case CURRENT_BOARD_ID_SET:
            return state.set('pasteCount', -1);
        default:
            return state;
    }
};
