// Lib
import { useMemo } from 'react';

// Extensions
import { Text } from '@tiptap/extension-text';
import { OneLineDocument } from '../../../common/tiptap/extensions/OneLineDocument';
import { Paragraph } from '../../../common/tiptap/extensions/Paragraph';
import { Focus } from '../../../common/tiptap/extensions/focus/Focus';
import Placeholder from '@tiptap/extension-placeholder';
import { EmptyDelete } from '../../../common/tiptap/extensions/EmptyDelete';
import { SearchHighlighter } from '../../../common/tiptap/extensions/SearchHighlighter';
import { KeyboardShortcuts } from '../../../common/tiptap/extensions/KeyboardShortcuts';
import { ExternalStoreSynchroniser } from '../../../common/tiptap/extensions/externalStoreSync/ExternalStoreSynchroniser';
import HardBreak from '@tiptap/extension-hard-break';

// Types
import { Extensions, Command } from '@tiptap/core';
import { TiptapContent } from '../../../common/tiptap/tiptapTypes';

export type UseTitleTiptapEditorExtensionsArgs = {
    placeholder: string;
    onEmptyDelete: () => void;
    onHighlightsChanged?: Function;
    keyboardShortcuts: Record<string, Command>;
    saveContent?: (content: TiptapContent, transactionId?: number) => void;
};

/**
 * Extensions to configure the task list title Tiptap editor.
 */
const useTitleTiptapEditorExtensions = ({
    placeholder,
    onEmptyDelete,
    onHighlightsChanged,
    keyboardShortcuts,
    saveContent,
}: UseTitleTiptapEditorExtensionsArgs): Extensions => {
    return useMemo(
        () => [
            OneLineDocument,
            Paragraph,
            Text,
            Focus,
            HardBreak,
            Placeholder.configure({ placeholder, showOnlyWhenEditable: false }),
            EmptyDelete.configure({
                onEmptyDelete: () => {
                    onEmptyDelete();
                    return true;
                },
            }),
            // We need to create a new instance of this extension for each editor as it uses storage
            //  See: https://github.com/ueberdosis/tiptap/issues/4317#issuecomment-2201035342
            ExternalStoreSynchroniser.extend({}).configure({ onDebouncedUpdate: saveContent }),
            SearchHighlighter.configure({ onHighlightsChanged }),
            KeyboardShortcuts.configure({ keyboardShortcuts }),
        ],
        [onEmptyDelete, onHighlightsChanged, keyboardShortcuts],
    );
};

export default useTitleTiptapEditorExtensions;
