import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';

// Constants
import { TooltipPositions } from '../../components/tooltips/tooltipConstants';

// Components
import useScrollOnListItemFocus from '../../components/lists/useScrollOnListItemFocus';
import FloatingPanel from '../../components/tooltips/FloatingPanel';
import PopupPanelMouseManager from '../../components/popupPanel/PopupPanelMouseManager';
import Button from '../../components/buttons/Button';
import Icon from '../../components/icons/Icon';

// Utils
import { isEmpty } from 'lodash';
import useListFocusManager from '../../components/lists/useListFocusManager';
import { hyperFormulaList } from './manager/hyperFormulaInstance';

// Constants
import { PopupIds } from '../../components/popupPanel/popupConstants';

// Styles
import './FormulaDropdown.scss';

export const getMatchingFormulas = (currentCellContent, allFormulas) => {
    const query = currentCellContent.toUpperCase().replace('=', '');
    if (!query) return ['SUM', 'IF', 'MIN', 'MAX', 'COUNT', 'AVERAGE'];

    return allFormulas.filter((formula) => formula.startsWith(query));
};

const FormulaListItem = (props) => {
    const { currentContent, index, formula, focusedIndex, addFormulaToCell, setShowFormulaDropdown } = props;

    const [focused, listItemRef] = useScrollOnListItemFocus({ focusedIndex, index });

    const searchQuery = currentContent?.toUpperCase().replace('=', '');
    const restOfFormula = formula.substring(searchQuery.length);

    const onClick = (event) => {
        // update the contents of the cell with selected formula
        addFormulaToCell(formula);
        setShowFormulaDropdown(false);

        event.stopPropagation();
        event.preventDefault();
    };

    return (
        <li className={`formula-option ${focused}`} key={index} tabIndex={0} ref={listItemRef}>
            <Button onClick={onClick}>
                {!!searchQuery && (
                    <>
                        <strong>{searchQuery}</strong>
                        {restOfFormula}
                    </>
                )}
                {!searchQuery && <>{formula}</>}
            </Button>
        </li>
    );
};

const FormulaDropdown = forwardRef(function FormulaDropdown(props, ref) {
    const { addFormulaToCell, showFormulaDropdown, setShowFormulaDropdown } = props;

    const [formulaDropdownData, setFormulaDropdownData] = useState(hyperFormulaList);
    const [currentContent, setCurrentContent] = useState('');

    const focusManager = useListFocusManager(true, 0, formulaDropdownData.length);
    const { focusedIndex, setFocusIndex, moveListFocusUp, moveListFocusDown } = focusManager;

    const useFocusedFormula = useCallback(() => {
        const formula = formulaDropdownData[focusedIndex];
        addFormulaToCell(formula);
    }, [formulaDropdownData, focusedIndex]);

    /**
     * Returns false when formula dropdown is not shown, for example when no formula matches the current cell content
     */
    const updateFormulaDropdown = useCallback(
        (show, cellContent) => {
            setShowFormulaDropdown(show);

            if (cellContent === currentContent) return formulaDropdownData.length > 0;

            setFocusIndex(0);
            setCurrentContent(cellContent ?? '');

            if (!cellContent) return false;

            const newFormulaList = getMatchingFormulas(cellContent, hyperFormulaList);
            setFormulaDropdownData(newFormulaList);

            return newFormulaList.length > 0;
        },
        [formulaDropdownData, focusedIndex],
    );

    const showDropdown = showFormulaDropdown && !isEmpty(formulaDropdownData); // eslint-disable-line no-undef

    useImperativeHandle(
        ref,
        () => {
            return {
                updateFormulaDropdown,
                useFocusedFormula,
                moveListFocusUp,
                moveListFocusDown,
                isFormulaDropdownOpen: () => showDropdown,
            };
        },
        [updateFormulaDropdown, useFocusedFormula, moveListFocusUp, moveListFocusDown, showDropdown],
    );

    return (
        <>
            {showDropdown && (
                <FloatingPanel
                    arrowColor="var(--ui-modal-background-primary)"
                    arrowStrokeColor="var(--ui-modal-background-primary-hover)"
                    className="FormulaDropdown"
                    selector=".Table.selected .handsontable-input-holder"
                    position={TooltipPositions.BOTTOM}
                    pollPosition
                >
                    <PopupPanelMouseManager
                        {...props}
                        visible={showDropdown}
                        popupId={PopupIds.FORMULA_DROPDOWN}
                        closePopup={() => setShowFormulaDropdown(false)}
                    >
                        <ul className="formula-list" data-table-util={true}>
                            {formulaDropdownData.map((formula, index) => {
                                return (
                                    <FormulaListItem
                                        formula={formula}
                                        key={index}
                                        index={index}
                                        currentContent={currentContent}
                                        focusedIndex={focusedIndex}
                                        setShowFormulaDropdown={setShowFormulaDropdown}
                                        {...props}
                                    />
                                );
                            })}
                        </ul>
                        <div className="formula-docs-link">
                            <Button
                                onMouseDown={() => {
                                    window.open(
                                        'https://help.milanote.com/en/articles/8584809-formulas-functions-for-tables',
                                        '_blank',
                                    );
                                }}
                                rel="noopener noreferrer"
                            >
                                <span>View formula help</span>
                                <Icon name="chevron-right" />
                            </Button>
                        </div>
                    </PopupPanelMouseManager>
                </FloatingPanel>
            )}
        </>
    );
});

FormulaDropdown.propTypes = {
    addFormulaToCell: PropTypes.func,
    showFormulaDropdown: PropTypes.bool,
    setShowFormulaDropdown: PropTypes.func,
};

FormulaListItem.propTypes = {
    currentCell: PropTypes.object,
    index: PropTypes.number,
    formula: PropTypes.string,
    addFormulaToCell: PropTypes.func,
    focusedIndex: PropTypes.number,
    setShowFormulaDropdown: PropTypes.func,
    currentContent: PropTypes.string,
};

export default FormulaDropdown;
