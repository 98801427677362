import { getSnapPointSheetHeight, initialiseSnapPoints, setSheetHeight } from '../utils/snapPointUtils';
import { RefObject, useEffect, useState } from 'react';

type SheetSnapPointState = {
    snapPointsState: number[];
    goToSnapPoint: (snapPoint: number) => void;
    addSnapPoint: (snapPoint: number) => void;
};

/**
 * Take care of snapPoint and activeSnapPoint state, and expose functions to update it
 * @param snapPoints
 * @param sheetRef
 * @param activeSnapPoint
 * @param dispatchUpdateActiveSnapPoint
 * @param dismissible
 * @param safeWindowHeight
 */
const useSheetSnapPointState = (
    snapPoints: number[],
    sheetRef: RefObject<HTMLDivElement>,
    activeSnapPoint: number,
    dispatchUpdateActiveSnapPoint: (snapPoint: number) => void,
    dismissible: boolean,
    safeWindowHeight: number,
): SheetSnapPointState => {
    const [snapPointsState, setSnapPointsState] = useState<number[]>(initialiseSnapPoints(snapPoints, dismissible));

    const goToSnapPoint = (snapPoint: number) => {
        setSheetHeight(sheetRef, getSnapPointSheetHeight(snapPoint, safeWindowHeight));

        if (snapPoint !== activeSnapPoint) dispatchUpdateActiveSnapPoint(snapPoint);
    };

    const addSnapPoint = (snapPoint: number) => {
        if (snapPointsState?.includes(snapPoint)) return;

        setSnapPointsState([...snapPointsState, snapPoint].sort());
    };

    useEffect(() => {
        goToSnapPoint(activeSnapPoint);
    }, [activeSnapPoint]);

    return { snapPointsState, goToSnapPoint, addSnapPoint };
};

export default useSheetSnapPointState;
