import { Extension, Command } from '@tiptap/core';
import { UniversalCardConverterCommand } from './universalCardTypes';
import { CommandProps } from '@tiptap/react';

// Utils
import { attemptLinkConversion } from './link';
import { attemptTaskListConversion } from './task';
import { attemptColorSwatchConversion } from './swatch';

// Actions
import { DEFAULT_TIPTAP_EXTENSION_PRIORITY } from '../../tiptapTypes';

enum UniversalCardConversionType {
    link = 'link',
    swatch = 'swatch',
    taskList = 'taskList',
}

const ALL_UNIVERSAL_CARD_CONVERSIONS = new Set([
    UniversalCardConversionType.link,
    UniversalCardConversionType.swatch,
    UniversalCardConversionType.taskList,
]);

const TASK_ONLY = new Set([UniversalCardConversionType.taskList]);

const LINK_AND_SWATCH_CONVERSIONS = new Set([UniversalCardConversionType.link, UniversalCardConversionType.swatch]);

interface UniversalCardOptions {
    dispatch: Function;
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        universalCard: {
            switchToLinkElement: () => ReturnType;
            switchToSwatchElement: () => ReturnType;
            switchToTaskListElement: () => ReturnType;
            attemptUniversalCardConversion: (elementTypes: Set<UniversalCardConversionType>) => ReturnType;
        };
    }
}

/**
 * This extension is used to switch to different element types if
 * the user adds specific content.
 * Examples:
 * - If the user types a URL, switch to a "Link"
 * - If the user types a colour hex code, switch to a "Swatch"
 * - If the user types a '[] ', switch to a "TaskList"
 */
export const UniversalCard = Extension.create<UniversalCardOptions>({
    name: 'universalCard',

    priority: DEFAULT_TIPTAP_EXTENSION_PRIORITY + 10,

    addStorage() {
        return {
            // We want to prevent multiple conversion attempts, e.g. if the user
            //  presses return, the editor will blur and thus trigger another conversion attempt
            performedConversion: false,
            // Because the conversion is async, we need to keep track of whether we're currently converting
            currentlyAttemptingConversion: false,
        };
    },

    addOptions() {
        return {
            ...this.parent?.(),
            dispatch: () => {},
        };
    },

    addCommands() {
        const attemptConversion = (converter: UniversalCardConverterCommand) => () => (props: CommandProps) => {
            const { commands } = props;

            const action = converter(props);
            if (!action) return false;

            commands.cancelPendingUpdate();

            this.options.dispatch(action);

            return true;
        };

        const switchToLinkElement = attemptConversion(attemptLinkConversion);
        const switchToSwatchElement = attemptConversion(attemptColorSwatchConversion);
        const switchToTaskListElement = attemptConversion(attemptTaskListConversion);

        const attemptUniversalCardConversion =
            (elementTypes = ALL_UNIVERSAL_CARD_CONVERSIONS) =>
            () => {
                if (this.editor.storage.performedConversion) return false;
                if (this.editor.storage.currentlyAttemptingConversion) return false;

                this.editor.storage.currentlyAttemptingConversion = true;

                this.editor.storage.performedConversion = this.editor.commands.first(
                    ({ commands }) =>
                        [
                            elementTypes.has(UniversalCardConversionType.link) && commands.switchToLinkElement,
                            elementTypes.has(UniversalCardConversionType.swatch) && commands.switchToSwatchElement,
                            elementTypes.has(UniversalCardConversionType.taskList) && commands.switchToTaskListElement,
                        ].filter(Boolean) as Command[],
                );

                // Confusingly - the above doesn't appear to be async (it's not awaited) but I've
                // seen that multiple invocations run concurrently...
                // Using this boolean solves the problem
                this.editor.storage.currentlyAttemptingConversion = false;

                return this.editor.storage.performedConversion;
            };

        return {
            switchToLinkElement,
            switchToSwatchElement,
            switchToTaskListElement,
            attemptUniversalCardConversion,
        };
    },

    onBlur() {
        return this.editor.commands.attemptUniversalCardConversion(LINK_AND_SWATCH_CONVERSIONS);
    },

    addKeyboardShortcuts() {
        return {
            /* eslint-disable @typescript-eslint/naming-convention */
            Enter: () => this.editor.commands.attemptUniversalCardConversion(ALL_UNIVERSAL_CARD_CONVERSIONS),
            Space: () => this.editor.commands.attemptUniversalCardConversion(TASK_ONLY),
            /* eslint-enable @typescript-eslint/naming-convention */
        };
    },
});
