// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

// Utils
import { hasChanged } from '../../utils/react/propsComparisons';

// Components
import Button from './Button';

const onClickHasChanged = hasChanged('onClick');
const onClickFnHasChanged = hasChanged('onClickFn');
const onDoubleClickHasChanged = hasChanged('onDoubleClick');

class DebouncedButton extends React.Component {
    constructor(props) {
        super(props);

        this.createDebouncedMouseHandlers(props);
    }

    componentWillReceiveProps(nextProps) {
        if (
            onClickHasChanged(this.props, nextProps) ||
            onClickFnHasChanged(this.props, nextProps) ||
            onDoubleClickHasChanged(this.props, nextProps)
        ) {
            this.createDebouncedMouseHandlers(nextProps);
        }
    }

    createDebouncedMouseHandlers = (props) => {
        this.onClick = props.onClick && debounce(props.onClick, 250, { leading: true, trailing: false });
        this.onDoubleClick =
            props.onDoubleClick && debounce(props.onDoubleClick, 250, { leading: true, trailing: false });
    };

    render() {
        return (
            <Button
                ref={this.props.forwardedRef}
                {...this.props}
                onClick={this.onClick}
                onDoubleClick={this.onDoubleClick}
            />
        );
    }
}

DebouncedButton.propTypes = {
    forwardedRef: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]).isRequired,
    onClick: PropTypes.func,
};

export default DebouncedButton;
