import React, { FormEvent, ReactElement, useCallback, useState } from 'react';
import { isEmpty } from 'lodash/fp';

import http from '../../utils/services/http';
import delay from '../../../common/utils/lib/delay';

import LoadingSpinner from '../../components/loaders/Spinner';

import '../components/AdminDashboardForm.scss';
import { AxiosError } from 'axios';
import { METHODS } from '../../../common/utils/http/httpConstants';
import { AppVersionRuleModifier, AppVersionRulePlatform } from '../../../common/appVersionRule/appVersionRuleConstants';

type AdminDashboardAppVersionsFormProps = {
    onComplete: Function;
    url: string;
};

export const AdminDashboardAppVersionsForm = ({
    onComplete,
    url,
}: AdminDashboardAppVersionsFormProps): ReactElement => {
    // form data
    const [platform, setPlatform] = useState(AppVersionRulePlatform.ios);
    const [ruleModifier, setRuleModifier] = useState(AppVersionRuleModifier.olderOrEqual);
    const [version, setVersion] = useState('');
    const [reason, setReason] = useState('');

    // submission state
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [error, setError] = useState('');

    const onSubmit = useCallback(
        async (event: FormEvent) => {
            event.preventDefault();

            setError('');
            setIsSubmitting(true);

            try {
                const {
                    data: { referral },
                } = await http({
                    method: METHODS.POST,
                    url,
                    data: {
                        platform: platform,
                        modifier: ruleModifier,
                        version: version,
                        reason: reason,
                    },
                });

                setIsSuccessful(true);
                setIsSubmitting(false);

                await delay(2000);

                onComplete(referral);
            } catch (err) {
                if (err instanceof AxiosError) {
                    setError(err.response?.data.error.message);
                } else if (err instanceof Error) {
                    setError(err.message);
                }
                console.error(err);
                setIsSuccessful(false);
                setIsSubmitting(false);
            }
        },
        [platform, ruleModifier, version, reason],
    );

    return (
        <form className="AdminDashboardForm" onSubmit={onSubmit}>
            <div>
                <label className="input-wrapper">
                    <label>
                        Platform:
                        <select
                            value={platform}
                            onChange={(event) => setPlatform(event.target.value as AppVersionRulePlatform)}
                        >
                            <option value={AppVersionRulePlatform.ios}>{'ios'}</option>
                            <option value={AppVersionRulePlatform.android}>{'android'}</option>
                        </select>
                    </label>
                    <label>
                        Modifier:
                        <select
                            value={ruleModifier}
                            onChange={(event) => setRuleModifier(event.target.value as AppVersionRuleModifier)}
                        >
                            <option value={AppVersionRuleModifier.olderOrEqual}>{'<='}</option>
                            <option value={AppVersionRuleModifier.equal}>{'=='}</option>
                            <option value={AppVersionRuleModifier.newerOrEqual}>{'>='}</option>
                        </select>
                    </label>
                    <label htmlFor="versionNumber">App Version Rule to Add</label>
                    <input
                        id="versionNumber"
                        name="versionNumber"
                        value={version}
                        onChange={(e) => setVersion(e.target.value)}
                    />
                    <label htmlFor="reason">Reason</label>
                    <input id="reason" name="reason" value={reason} onChange={(e) => setReason(e.target.value)} />
                </label>
            </div>

            <div className="submit-wrapper">
                {!isSubmitting && !isSuccessful && (
                    <input disabled={isEmpty(version) || isEmpty(reason)} type="submit" className="submit-button" />
                )}
                {isSubmitting && <LoadingSpinner show />}
                {isSuccessful && <span className="success">✔︎ Rule added</span>}
                {error && <span className="error">{error}</span>}
            </div>
        </form>
    );
};

export default AdminDashboardAppVersionsForm;
