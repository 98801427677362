import { Extension } from '@tiptap/core';
import * as typingBuffer from '../utils/typingBufferSingleton';

/**
 * Extension to put the typing buffer's content into the editor when it receives focus.
 */
export const TypingBuffer = Extension.create({
    name: 'typingBuffer',

    onFocus(): void {
        const chars = typingBuffer.getChars();

        if (!chars) return;

        typingBuffer.clear();
        this.editor.chain().focus('end').insertContent(chars).run();
    },
});
