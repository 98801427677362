// Lib
import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { EditorContent } from '@tiptap/react';
import classNames from 'classnames';

// Utils
import { getCaption, getCaptionModified } from '../../../../common/elements/utils/elementPropertyUtils';
import { getCaptionEditorId, getCaptionEditorKey } from '../../../element/utils/elementEditorUtils';

// Hooks
import usePoiUpdateHandler from '../../pointsOfInterest/usePoiUpdateHandler';
import useTiptapInnerRef from '../../../../common/tiptap/hooks/useTiptapInnerRef';
import useClientElementTiptapEditor from '../../tiptapEditor/useClientElementTiptapEditor';
import { useSuggestionsManager } from '../../../../common/tiptap/extensions/mention/suggestionsManager';

// Extensions
import useCaptionTiptapEditorExtensions from './useCaptionTiptapEditorExtensions';

// Components
import { DraftJsConversionIndicator } from '../../../../common/tiptap/conversion/elementConversion/previewComponents/DraftJsConversionIndicator';
import { SearchHighlightObserver } from '../../../../common/tiptap/extensions/SearchHighlighter';

// Types
import { CaptionProps } from '../captionComponentTypes';
import { TiptapContent } from '../../../../common/tiptap/tiptapTypes';

// Styles
import './CaptionTiptapEditor.scss';

type CaptionTiptapEditorProps = Omit<CaptionProps, 'textContent'> & {
    textContent: TiptapContent | null;
};

const CaptionTiptapEditor = (props: CaptionTiptapEditorProps): ReactElement => {
    const {
        element,
        textContent,
        currentEditorId,
        isEditable,
        isEditing,
        isSingleSelected,
        startEditing,
        saveCaptionContent,
        onEmptyBackspace,
        placeholder,
        padTop,
        style,
    } = props;

    const dispatch = useDispatch();

    const editorId = getCaptionEditorId(props);
    const editorKey = getCaptionEditorKey(props);

    const { innerRef, contentInnerRef } = useTiptapInnerRef();
    const onHighlightsChanged = usePoiUpdateHandler({ contentInnerRef, element });

    const [suggestionsManager, mentionsPopup] = useSuggestionsManager();
    const extensions = useCaptionTiptapEditorExtensions({
        placeholder,
        onEmptyBackspace,
        saveCaptionContent,
        suggestionsManager,
        isEditable,
        dispatch,
        onHighlightsChanged,
    });

    const { editor, onMouseDown, onClick } = useClientElementTiptapEditor({
        persistedContent: textContent,
        extensions,

        editorId,
        editorKey,
        currentEditorId,

        isEditable: !!isEditable,
        isEditing: !!isEditing,
        isSingleSelected: !!isSingleSelected,

        startEditing,
        saveContent: saveCaptionContent,
    });

    const classes = classNames('Caption', {
        // This class is used in a querySelector to avoid changing the text automatically
        // when choosing a custom colour
        'caption-modified': getCaptionModified(element),
        'pad-top': padTop,
    });

    return (
        <div className={classes} style={style} onMouseDown={onMouseDown} onClick={onClick}>
            {mentionsPopup}
            <SearchHighlightObserver editor={editor} />
            <DraftJsConversionIndicator element={element} textContent={getCaption(element)} />
            <EditorContent innerRef={innerRef} className="CaptionTiptapEditor" editor={editor} />
        </div>
    );
};

export default CaptionTiptapEditor;
