/* eslint-disable react/sort-comp */
// Lib
import React from 'react';
import { CSSTransitionGroup } from '../../../../node_module_clones/react-transition-group';
import classNames from 'classnames';

// Components
import CardClipperButton from './CardClipperButton';

// Types
import { CardClipperProps } from './cardClipperTypes';
import { EditorContent } from '../../../../common/elements/elementModelTypes';

// Styles
import './CardClipperTool.scss';

export type CardClipperToolProps = Omit<CardClipperProps, 'isEditing'> & {
    gridSize: number;

    selectionYMidpoint: number;
    selectionHeight: number;

    isClipping: boolean;
    clippedText: EditorContent;

    onClipStart: () => void;
    beforeDragEnd: (dragSuccess: boolean) => void;
    onClipEnd: (dragSuccess: boolean) => void;

    getContextZoomScale: () => number;

    isClipCopy: boolean;
};

class CardClipperTool extends React.Component<CardClipperToolProps> {
    onPointerDown = () => {
        const { onClipStart } = this.props;

        onClipStart();

        // If the mouse up or drag end doesn't end up being received by this component then
        // a double click drag has been performed, so catch the mouse up on the document
        document.addEventListener('mouseup', this.onPointerUp);
        document.addEventListener('touchend', this.onPointerUp);
    };

    onPointerUp = () => {
        // A mouse up is an unsuccessful drag
        this.onDragEnd(false);
    };

    beforeDragStart = () => {
        document.removeEventListener('mouseup', this.onPointerUp);
        document.removeEventListener('touchend', this.onPointerUp);
    };

    onDragEnd = (dragSuccess: boolean) => {
        const { onClipEnd } = this.props;

        onClipEnd(dragSuccess);

        document.removeEventListener('mouseup', this.onPointerUp);
        document.removeEventListener('touchend', this.onPointerUp);
    };

    render() {
        const { isClipping, selectionYMidpoint } = this.props;

        return (
            <CSSTransitionGroup
                transitionName="fade"
                transitionAppear
                transitionAppearTimeout={100}
                transitionEnterTimeout={100}
                transitionLeaveTimeout={100}
            >
                {selectionYMidpoint !== 0 ? (
                    <div className={classNames('CardClipperTool', { clipping: isClipping })}>
                        <CardClipperButton
                            {...this.props}
                            // The pointer events seem to get fired at the wrong time, so we need to use
                            // individual touch and mouse event handlers
                            onTouchStart={this.onPointerDown}
                            onTouchEnd={this.onPointerUp}
                            onMouseDown={this.onPointerDown}
                            onMouseUp={this.onPointerUp}
                            y={selectionYMidpoint}
                            beforeDragStart={this.beforeDragStart}
                            onDragEnd={this.onDragEnd}
                        />
                    </div>
                ) : null}
            </CSSTransitionGroup>
        );
    }
}

export default CardClipperTool;
