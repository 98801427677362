import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ColorDisplayToolOption from './ColorDisplayToolOption';
import ToolbarPopup from '../../toolbarPopup/ToolbarPopup';
import PopupTrigger from '../../../../../components/popupPanel/PopupTrigger';

// Actions
import { updateElementsColorDisplay } from './colorDisplayActions';
import { updateCurrentUser } from '../../../../../user/currentUserActions';

// Utils
import { getColorSpace, getShowColorValue } from '../../../../../../common/elements/utils/elementPropertyUtils';

// Constants
import { COLOR_DISPLAY, COLOR_SPACE } from '../../../../../../common/colors/colorConstants';
import { PopupIds } from '../../../../../components/popupPanel/popupConstants';

// Styles
import './ColorDisplayTool.scss';

const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatchConvertToColorDisplay: (colorDisplay) => {
        dispatch(
            updateElementsColorDisplay({
                elements: ownProps.selectedElements,
                colorDisplay,
            }),
        );
        dispatch(
            updateCurrentUser({
                changes: { settings: { preferredColorDisplay: colorDisplay } },
            }),
        );
    },
});

const ColorDisplayTool = (props) => {
    const { selectedElements, dispatchConvertToColorDisplay } = props;

    const firstSelectedElement = selectedElements.first();

    const showColorValue = getShowColorValue(firstSelectedElement);
    const colorSpace = getColorSpace(firstSelectedElement) || COLOR_SPACE.HEX;
    const colorDisplay = showColorValue ? colorSpace : COLOR_DISPLAY.OFF;

    return (
        <div className="ColorDisplayTool">
            <PopupTrigger popupId={PopupIds.COLOR_DISPLAY}>
                {(popupIsOpen) => (
                    <ColorDisplayToolOption
                        className="color-display-toolbar-option"
                        name="Display"
                        colorDisplay={colorDisplay}
                        isActive={popupIsOpen}
                    />
                )}
            </PopupTrigger>
            <ToolbarPopup
                popupId={PopupIds.COLOR_DISPLAY}
                className="color-display-popup"
                buttonSelector=".ColorDisplayTool .icon"
            >
                <div className="color-display-popup-content">
                    <ColorDisplayToolOption
                        colorDisplay={COLOR_DISPLAY.HEX}
                        onClick={() => dispatchConvertToColorDisplay(COLOR_DISPLAY.HEX)}
                        isActive={colorDisplay === COLOR_DISPLAY.HEX}
                    />
                    <ColorDisplayToolOption
                        colorDisplay={COLOR_DISPLAY.RGB}
                        onClick={() => dispatchConvertToColorDisplay(COLOR_DISPLAY.RGB)}
                        isActive={colorDisplay === COLOR_DISPLAY.RGB}
                    />
                    <ColorDisplayToolOption
                        colorDisplay={COLOR_DISPLAY.HSL}
                        onClick={() => dispatchConvertToColorDisplay(COLOR_DISPLAY.HSL)}
                        isActive={colorDisplay === COLOR_DISPLAY.HSL}
                    />
                    <ColorDisplayToolOption
                        colorDisplay={COLOR_DISPLAY.OFF}
                        onClick={() => dispatchConvertToColorDisplay(COLOR_DISPLAY.OFF)}
                        isActive={colorDisplay === COLOR_DISPLAY.OFF}
                    />
                </div>
            </ToolbarPopup>
        </div>
    );
};

ColorDisplayTool.propTypes = {
    gridSize: PropTypes.number.isRequired,
    popupIsOpen: PropTypes.bool,
    selectedElements: PropTypes.object,
    firstElementId: PropTypes.string,
    openColorDisplayPopup: PropTypes.func,
    closeColorDisplayPopup: PropTypes.func,
    dispatchConvertToColorDisplay: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(ColorDisplayTool);
