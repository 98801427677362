// Lib
import getTextSelectionFocusElement from '../../components/editor/domUtils/getTextSelectionFocusElement';

// Utils
import platformSingleton from '../../platform/platformSingleton';

// Types
import { Rect } from '../../../common/maths/geometry/rect/rectTypes';
import { BrowserEngineType } from '../../platform/platformTypes';

const isChrome =
    platformSingleton.browserEngine === BrowserEngineType.chrome ||
    platformSingleton.browserEngine === BrowserEngineType.androidChrome;

/**
 * The following code is copied from draft-js to get the selection rect.
 * Copying into our codebase to avoid the need to import the entire draft-js library,
 * as it will be removed in the future & to use our platform detection & Rect types.
 *
 * MIT Licence - see draft-js-LICENSE file found in this directory.
 */
/** START - Copied from draft-js */

// In Chrome, the client rects will include the entire bounds of all nodes that
// begin (have a start tag) within the selection, even if the selection does
// not overlap the entire node. To resolve this, we split the range at each
// start tag and join the client rects together.
// https://code.google.com/p/chromium/issues/detail?id=324437
function getRangeClientRectsChrome(range: Range): Rect[] {
    const tempRange = range.cloneRange();
    const clientRects = [];

    for (let ancestor: Node | null = range.endContainer; ancestor != null; ancestor = ancestor.parentNode) {
        // If we've climbed up to the common ancestor, we can now use the
        // original start point and stop climbing the tree.
        const atCommonAncestor = ancestor === range.commonAncestorContainer;
        if (atCommonAncestor) {
            tempRange.setStart(range.startContainer, range.startOffset);
        } else {
            tempRange.setStart(tempRange.endContainer, 0);
        }
        const rects = Array.from(tempRange.getClientRects());
        clientRects.push(rects);
        if (atCommonAncestor) {
            clientRects.reverse();
            return ([] as Rect[]).concat(...clientRects);
        }
        tempRange.setEndBefore(ancestor);
    }

    throw new Error('Found an unexpected detached subtree when getting range client rects.');
}

/**
 * Like range.getClientRects() but normalizes for browser bugs.
 */
const getRangeClientRects = isChrome
    ? getRangeClientRectsChrome
    : function (range: Range): Rect[] {
          return Array.from(range.getClientRects());
      };

/**
 * Like range.getBoundingClientRect() but normalizes for browser bugs.
 */
function getRangeBoundingClientRect(range: Range): Rect {
    // "Return a DOMRect object describing the smallest rectangle that includes
    // the first rectangle in list and all of the remaining rectangles of which
    // the height or width is not zero."
    // http://www.w3.org/TR/cssom-view/#dom-range-getboundingclientrect
    const rects = getRangeClientRects(range);
    let top = 0;
    let right = 0;
    let bottom = 0;
    let left = 0;

    if (rects.length) {
        // If the first rectangle has 0 width, we use the second, this is needed
        // because Chrome renders a 0 width rectangle when the selection contains
        // a line break.
        if (rects.length > 1 && rects[0].width === 0) {
            ({ top, right, bottom, left } = rects[1]);
        } else {
            ({ top, right, bottom, left } = rects[0]);
        }

        for (let ii = 1; ii < rects.length; ii++) {
            const rect = rects[ii];
            if (rect.height !== 0 && rect.width !== 0) {
                top = Math.min(top, rect.top);
                right = Math.max(right, rect.right);
                bottom = Math.max(bottom, rect.bottom);
                left = Math.min(left, rect.left);
            }
        }
    }

    return {
        x: left,
        y: top,
        top,
        right,
        bottom,
        left,
        width: right - left,
        height: bottom - top,
    };
}

/**
 * Return the bounding ClientRect for the visible DOM selection, if any.
 * In cases where there are no selected ranges or the bounding rect is
 * temporarily invalid, return null.
 *
 * When using from an iframe, you should pass the iframe window object
 */
function getVisibleSelectionRect(global: any): Rect | null {
    const selection = global.getSelection();
    if (!selection.rangeCount) {
        return null;
    }

    const range = selection.getRangeAt(0);
    const boundingRect = getRangeBoundingClientRect(range);
    const { top, right, bottom, left } = boundingRect;

    // When a re-render leads to a node being removed, the DOM selection will
    // temporarily be placed on an ancestor node, which leads to an invalid
    // bounding rect. Discard this state.
    if (top === 0 && right === 0 && bottom === 0 && left === 0) {
        return null;
    }

    return boundingRect;
}

/** END - Copied from draft-js */

/**
 * Gets the DOMRect of the current selection.
 */
const getDomSelectionRect = (): Rect | null => {
    const selectionElement = getTextSelectionFocusElement();

    if (!selectionElement) return null;

    return selectionElement.getBoundingClientRect();
};

/**
 * This uses the draft-js getVisibleSelectionRect function to determine the current position of the
 * cursor, and if it can't be found it attempts to calculate it from the native DOM Selection API.
 *
 * It might not be found by draft-js when on a new line as the size of the bounding box of the span will
 * be 0.
 */
const getSelectionRect = (): Rect | null => {
    const textSelectionRect = getVisibleSelectionRect(window);
    if (textSelectionRect) return textSelectionRect;

    return getDomSelectionRect();
};

export default getSelectionRect;
