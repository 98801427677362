import React from 'react';
import DefaultMention, { MentionOptions } from '@tiptap/extension-mention';
import { mergeAttributes, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';

import { NodeViewProps } from '@tiptap/core';
import { getMentionNodeText, getNameFromMentionNode } from './mentionUtils';

export type MentionAttrs = {
    mentionKey: string;
    id: string;
    label: string;
};

// This should be kept 1:1 with the fields of MentionAttrs
const ATTRIBUTES = {
    mentionKey: { default: '' },
    id: { default: '' },
    label: { default: '' },
};

export const MentionDisplay = ({ node }: NodeViewProps) => {
    const name = getNameFromMentionNode(node);

    return (
        <NodeViewWrapper as="span" className="MentionEntity">
            <span>@</span>
            <span>{name}</span>
        </NodeViewWrapper>
    );
};

export const Mention = DefaultMention.extend<MentionOptions<any, MentionAttrs>>({
    addNodeView() {
        return ReactNodeViewRenderer(MentionDisplay);
    },
    addAttributes() {
        return ATTRIBUTES;
    },
}).configure({
    // Inside the app, the MentionDisplay component will be used, but we
    // still need these functions for copypasting, exports, etc
    renderHTML({ node, options }) {
        const name = getNameFromMentionNode(node);
        return ['span', mergeAttributes({ class: 'MentionEntity' }, options.HTMLAttributes), '@' + name];
    },
    renderText({ node }) {
        return getMentionNodeText(node);
    },
});
