import { defer } from 'lodash';
import { RefObject, useCallback, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';

import { ImMNElement } from '../../../common/elements/elementModelTypes';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import { Point } from '../../../common/maths/geometry/pointTypes';
import { useMeasurementsDispatch } from '../measurementsStore/MeasurementsProvider';
import measurementsRegistry from '../../components/measurementsStore/measurementsRegistry';
import { poiAddMultiple, PointOfInterest, poiRemoveMultiple } from './poiActions';
import { PoiType } from './poiConstants';
import { currentBoardIdSelector } from '../../element/board/boardSelector';
import PoiBoardSectionContext from './PoiBoardSectionContext';

const getTranslatedMeasurement = (element: Element, translation: Point) => {
    const domRect = element.getBoundingClientRect();
    const { x, y } = translation;
    return {
        x: domRect.x + x,
        y: domRect.y + y,
        width: domRect.width,
        height: domRect.height,
        top: domRect.top + y,
        right: domRect.right + x,
        bottom: domRect.bottom + y,
        left: domRect.left + x,
    };
};

export default ({ contentInnerRef, element }: { contentInnerRef: RefObject<HTMLElement>; element: ImMNElement }) => {
    const measurementsDispatch = useMeasurementsDispatch();
    const registeredPois = useRef<string[]>([]);
    const boardSection = useContext(PoiBoardSectionContext);
    const currentBoardId = useSelector(currentBoardIdSelector);
    const refreshPois = useCallback(() => {
        const elementId = getElementId(element);

        if (!elementId) return;

        // This callback occurs before the html decorations are applied, but we need them
        // for the measurements! So defer the rest to let Tiptap finish mounting them.
        defer(() => {
            const baseTranslation = measurementsRegistry.getCanvasViewportTranslation();

            const poiElements = contentInnerRef.current?.querySelectorAll('.SearchHighlightSpan');
            const toUnregister = new Set(registeredPois.current);
            const registered: string[] = [];
            const pois: PointOfInterest[] = Array.from(poiElements || []).map((poiElement) => {
                const index = poiElement.getAttribute('data-poi-id');
                const id = `${elementId}-${index}`;
                toUnregister.delete(id);
                registered.push(id);

                const targetRect = getTranslatedMeasurement(poiElement, baseTranslation);

                return {
                    id,
                    poiType: PoiType.search,
                    currentBoardId,
                    section: boardSection!,
                    targetRect,
                    data: { count: 1 },
                };
            });

            if (pois.length) measurementsDispatch(poiAddMultiple({ pois }));
            if (toUnregister.size) measurementsDispatch(poiRemoveMultiple({ ids: Array.from(toUnregister) }));
            registeredPois.current = registered;
        });
    }, []);

    return refreshPois;
};
