/**
 * This file is predominantly the same as Tiptap core's `setNode` command,
 * except for the `removeFromList` invocation and use of `clearNodesInRange` rather than `commands.clearNodes`.
 *
 * The original setNode command uses `clearNodes` to remove any list nodes, however
 * it doesn't work well with [nested lists](https://linear.app/milanote/issue/WEB-11783/improve-tiptaps-clearnodes-function-to-work-with-lists).
 *
 * This version uses our own `removeFromList` command to remove any list nodes, and then clears all nodes
 * within the updated selection, finally setting the block type afterwards.
 *
 * See: @tiptap/core/packages/core/src/commands/setNode.ts v2.9.1 for comparison
 */
// Utils
import { getNodeType } from '@tiptap/react';
import { setBlockType } from '@tiptap/pm/commands';
import { findSelectedListItemContentNodeRanges } from '../list/tiptapListUtils';

// Types
import { RawCommands } from '@tiptap/core';
import { NodeType } from '@tiptap/pm/model';

export const setNode: RawCommands['setNode'] =
    (typeOrName: NodeType | string, attributes = {}) =>
    ({ state, chain }) => {
        const type = getNodeType(typeOrName, state.schema);

        let attributesToCopy: Record<string, any> | undefined;

        if (state.selection.$anchor.sameParent(state.selection.$head)) {
            // only copy attributes if the selection is pointing to a node of the same type
            attributesToCopy = state.selection.$anchor.parent.attrs;
        }

        // TODO: use a fallback like insertContent?
        if (!type.isTextblock) {
            console.warn('[tiptap warn]: Currently "setNode()" only supports text block nodes.');

            return false;
        }

        return (
            chain()
                // MILANOTE-MODIFICATION - start
                .command(({ commands }) => {
                    // If there's list items within the selection, or if can't immediately set the block type
                    // we need to clear the nodes to the basic paragraph first
                    const hasSelectedListItems = findSelectedListItemContentNodeRanges(state).length > 0;

                    if (!hasSelectedListItems) {
                        const canSetBlock = setBlockType(type, { ...attributesToCopy, ...attributes })(state);

                        if (canSetBlock) return true;
                    }

                    return commands.clearBlockNodes();
                })
                // MILANOTE-MODIFICATION - end
                // Finally actually set the block type
                .command(({ state: updatedState, dispatch }) =>
                    setBlockType(type, { ...attributesToCopy, ...attributes })(updatedState, dispatch),
                )
                .run()
        );
    };
