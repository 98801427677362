// Lib
import React, { useEffect } from 'react';

// Utils
import { calculateClipperLocationState } from './cardClipperUtils';
import getSelectionRect from '../../../utils/dom/getSelectionRect';

// Context
import { useZoomContext } from '../../../canvas/zoom/ZoomStateContext';

// Types
import { Selection } from '@tiptap/pm/state';

/**
 * Determines where the card clipper should be positioned based on the current selection
 * and the container it is being rendered in.
 */
export const useCardClipperPositioner = (
    currentSelection: Selection | undefined,
    clipperContainerRef: React.RefObject<HTMLDivElement>,
    isClipping: boolean,
) => {
    const { getContextZoomScale } = useZoomContext();

    const [selectionYMidpoint, setSelectionYMidpoint] = React.useState<number>(0);
    const [selectionHeight, setSelectionHeight] = React.useState<number>(0);

    useEffect(() => {
        if (isClipping) return;
        if (!currentSelection) return;

        const zoomScale = getContextZoomScale();

        const selectionRect = getSelectionRect();
        const containerRect = clipperContainerRef.current?.getBoundingClientRect();

        const state = calculateClipperLocationState(selectionRect, containerRect, zoomScale);

        setSelectionYMidpoint(state?.selectionYMidpoint || 0);
        setSelectionHeight(state?.selectionHeight || 0);
    }, [currentSelection, clipperContainerRef, isClipping]);

    return {
        selectionYMidpoint,
        selectionHeight,
    };
};
