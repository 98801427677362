import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { defer } from 'lodash/fp';

// Utils
import {
    getElementId,
    getIsLineLabelEnabled,
    getLineLabel,
} from '../../../../../../common/elements/utils/elementPropertyUtils';
import { isPlatformIos } from '../../../../../platform/utils/platformDetailsUtils';
import { getMainEditorId, getMainEditorKey } from '../../../../../element/utils/elementEditorUtils';

// Selectors
import { getCurrentlyEditingId } from '../../../../../element/selection/currentlyEditingSelector';
import { getPlatformDetailsSelector } from '../../../../../platform/platformSelector';

// Actions
import { finishEditingElement, startEditingElement } from '../../../../../element/selection/selectionActions';
import { updateElement } from '../../../../../element/actions/elementActions';

// Components
import ToolbarTool from '../../ToolbarTool';
import Icon from '../../../../../components/icons/Icon';

const mapStateToProps = createSelector(
    (state, ownProps) => ownProps.selectedElements.first(),
    getCurrentlyEditingId,
    getPlatformDetailsSelector,
    (selectedElement, currentlyEditingId, platformDetails) => ({
        isActive: !!currentlyEditingId || (getIsLineLabelEnabled(selectedElement) && !!getLineLabel(selectedElement)),
        platformDetails,
    }),
);

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateLineLabel: ({ id, lineLabelEnabled }) =>
        dispatch(
            updateElement({
                id,
                changes: { lineLabelEnabled },
            }),
        ),
    dispatchStartEditing: ({ id, editorKey, editorId }) => dispatch(startEditingElement({ id, editorKey, editorId })),
    dispatchStopEditing: ({ id }) => dispatch(finishEditingElement(id)),
});

const LineLabelTool = (props) => {
    const {
        isActive,
        selectedElements,
        dispatchStartEditing,
        dispatchStopEditing,
        dispatchUpdateLineLabel,
        platformDetails,
    } = props;

    const toggleLabel = React.useCallback(() => {
        const element = selectedElements.first();
        const elementId = getElementId(element);

        if (isActive) {
            dispatchStopEditing({ id: elementId });

            // Wait until the LineLabelContentEditable has finished updating,
            defer(() => dispatchUpdateLineLabel({ id: elementId, lineLabelEnabled: false }));

            return;
        }

        // On iOS we have to start editing immediately otherwise it won't focus or select the text.
        // This seems to be a bug (or security feature?) on iOS
        if (isPlatformIos(platformDetails)) {
            dispatchStartEditing({
                id: elementId,
                editorId: getMainEditorId({ element }),
                editorKey: getMainEditorKey({ element }),
            });
        } else {
            // As this is on mousedown, we need to defer until after the mouse press so that focus isn't lost
            defer(() => {
                dispatchStartEditing({
                    id: elementId,
                    editorId: getMainEditorId({ element }),
                    editorKey: getMainEditorKey({ element }),
                });
            });
        }
    }, [isActive, selectedElements]);

    return (
        <ToolbarTool {...props} onClick={toggleLabel} isActive={isActive}>
            <Icon {...props} name="toolbar-line-label" />
        </ToolbarTool>
    );
};

LineLabelTool.propTypes = {
    name: PropTypes.string,
    isActive: PropTypes.bool,
    selectedElements: PropTypes.object.isRequired,
    platformDetails: PropTypes.object.isRequired,
    dispatchUpdateLineLabel: PropTypes.func,
    dispatchStartEditing: PropTypes.func,
    dispatchStopEditing: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(LineLabelTool);
