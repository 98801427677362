import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MobileToolbarMoreIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon mobile-toolbar-more"
        {...props}
    >
        <path
            fill="#323B4A"
            fillRule="evenodd"
            d="M7 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm5-2a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm9 2a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"
        />
    </svg>
);
const Memo = memo(MobileToolbarMoreIcon);
export default Memo;
