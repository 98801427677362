// Lib
import React, { ReactElement, useCallback } from 'react';
import { Editor } from '@tiptap/react';

// Utils
import { isTiptapEditorEmpty } from '../../../../common/tiptap/utils/isTiptapEditorEmpty';

// Components
import Button from '../../../components/buttons/Button';

// Types
import { TiptapContent } from '../../../../common/tiptap/tiptapTypes';

// Styles
import './CommentPostButton.scss';

interface CommentTiptapPostButtonProps {
    editor: Editor;
    onSubmit: (text: TiptapContent) => void;
}

const CommentTiptapPostButton = (props: CommentTiptapPostButtonProps): ReactElement => {
    const { editor, onSubmit } = props;

    const disabled = isTiptapEditorEmpty(editor);

    const onClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            event.stopPropagation();

            if (isTiptapEditorEmpty(editor as unknown as Editor)) {
                editor.commands.focus();
                return false;
            }

            const currentContent = editor.getJSON();

            onSubmit?.(currentContent as TiptapContent);
        },
        [editor, onSubmit, disabled],
    );

    return (
        <Button className="CommentPostButton CommentTiptapPostButton" disabled={disabled} onClick={onClick}>
            Send
        </Button>
    );
};

export default CommentTiptapPostButton;
