import React from 'react';
import PropTypes from 'prop-types';
import dateformat from 'dateformat';
import HoverReveal from './AdminDashboardHoverReveal';

const formatDate = (date, dateFormat = 'yyyy-mm-dd h:MMtt') => dateformat(date, dateFormat);

const AdminDashboardTimestamp = ({ timestamp, dateFormat }) => {
    if (!timestamp) return <span>-</span>;

    return (
        <HoverReveal className="Timestamp" revealChildren={timestamp}>
            {formatDate(timestamp, dateFormat)}
        </HoverReveal>
    );
};

AdminDashboardTimestamp.propTypes = {
    timestamp: PropTypes.number,
    dateFormat: PropTypes.string,
};

export default AdminDashboardTimestamp;
