import { defer, head, negate } from 'lodash';
import { get, stubFalse } from 'lodash/fp';

// Actions
import { setInboxFromElementCreate } from '../../inbox/inboxActions';
import { togglePopup } from '../../components/popupPanel/popupActions';
import { createBatchAction } from '../../store/reduxBulkingMiddleware';
import { prepareElementAttachment, uploadAttachment } from '../attachments/attachmentActions';
import { setSelectedElements, startEditingElement } from '../selection/selectionActions';
import {
    createElementWithId,
    updateElement as baseUpdateElement,
    updateMultipleElements as baseUpdateMultipleElements,
} from '../../../common/elements/elementActions';

// Selectors
import { getCurrentUserId, getCurrentUserQuickNotesRootBoardId } from '../../user/currentUserSelector';
import { getElements } from '../selectors/elementSelector';
import { getCurrentBoardId } from '../../reducers/currentBoardId/currentBoardIdSelector';
import { elementGraphSelector } from '../selectors/elementGraphSelector';
import { getPlatformDetailsSelector } from '../../platform/platformSelector';
import popupOpenSelector from '../../components/popupPanel/popupOpenSelector';
import { getClientIdSelector, getClientTickSelector } from '../../user/clientDataSelector';

// Util
import { isPlatformIos } from '../../platform/utils/platformDetailsUtils';
import { getApplicationPlatform } from '../../utils/platformUtils';
import { getMainEditorId, getMainEditorKey } from '../utils/elementEditorUtils';
import { asObject, propIn } from '../../../common/utils/immutableHelper';
import { isBoardLike, isCommentThread, isImage, isSkeleton } from '../../../common/elements/utils/elementTypeUtils';
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { getTimestamp } from '../../../common/utils/timeUtil';
import { getNewTransactionId } from '../../utils/undoRedo/undoRedoTransactionManager';
import { getSelectedElements } from '../selection/selectedElementsSelector';
import { updateLocation } from './elementMoveActions';
import createElementId from '../../../common/uid/createElementId';
import { getCanvasElements } from '../../../common/elements/utils/elementGraphUtils';
import { getAliasContentFromBoard } from '../../../common/alias/aliasUtils';
import { ensureElementPositionIsInteger } from '../../../common/elements/utils/elementPositionUtils';
import {
    getElementId,
    getElementLocation,
    getElementType,
    getPhysicalId,
} from '../../../common/elements/utils/elementPropertyUtils';
import {
    getHighestScore,
    isLocationAttached,
    isLocationInbox,
} from '../../../common/elements/utils/elementLocationUtils';

// Analytics
import * as analyticsTimingService from '../../analytics/timingService/analyticsTimingService';
import { NewRelicPageActions } from '../../analytics/newRelicUtils';

// Constants
import { ELEMENT_MOVE_AND_UPDATE } from '../../../common/elements/elementConstants';
import { CURRENT_USER_INCREMENT_TICK } from '../../user/currentUserConstants';
import { DEFAULT_INCREMENT } from '../../../common/elements/listOrderScores/scoreCalculator';
import { BoardSections } from '../../../common/boards/boardConstants';
import { PopupIds } from '../../components/popupPanel/popupConstants';
import { ElementType } from '../../../common/elements/elementTypes';

export {
    createElementWithId,
    setElementTypeAndUpdateElement,
    loadElements,
} from '../../../common/elements/elementActions';

// Need to include here to avoid circular references
const incrementClientTick = (count = 1) => ({ type: CURRENT_USER_INCREMENT_TICK, count, sync: false });

export const getNewElementId =
    (action = {}) =>
    (dispatch, getState) => {
        const { _id } = action;

        const state = getState();
        const clientId = getClientIdSelector(state);
        const clientTick = getClientTickSelector(state);

        const id = _id || createElementId(clientId, clientTick);

        // Force the client tick to increment so that many elements created at the same time will have unique
        // IDs
        dispatch(incrementClientTick());

        return id;
    };

/**
 * Ensures the positions for canvas elements have a score based on the highest score of the current canvas elements,
 * as well as ensuring the position's grid points are integers.
 */
const getCanvasUpdatedLocation = (state, location, currentBoardId, elements) => {
    if (!!get(['position', 'score'], location)) return location;

    const elementGraph = elementGraphSelector(state);
    const canvasElements = getCanvasElements({ elements, elementGraph, parentId: currentBoardId }).valueSeq().toJS();

    const highestScore = getHighestScore(canvasElements);

    const locationWithScore = {
        ...location,
        position: {
            ...location.position,
            score: highestScore + DEFAULT_INCREMENT,
        },
    };

    const { location: updatedLocation } = ensureElementPositionIsInteger({ location: locationWithScore });

    return updatedLocation;
};

/**
 * Creates an element in the given location with the provided content.
 * If the location is a list but it doesn't have a score associated to it, the score will be calculated and the item
 * will be inserted at that location.
 *
 * If attachmentData is provided, it will be passed into the uploadAttachment action and
 * can prevent performing duplicated work.
 */
export const createElement =
    (action, id, attachmentData) =>
    (dispatch, getState, { measurementsStore }) => {
        const {
            elementType,
            content,
            attachment,
            location,
            duplicate,
            duplicatedId,
            creationSource,
            temp,
            sync = true,
            markAsFetched = true,
        } = action;

        const state = getState();
        const userId = getCurrentUserId(state);
        const currentBoardId = getCurrentBoardId(state);
        const elements = getElements(state);
        const now = getTimestamp();
        const transactionId = action.transactionId || getNewTransactionId();

        // Use the element move utility function to calculate the score
        const creates = [{ id, location }];
        creates.map(updateLocation(dispatch, getState, { measurementsStore })(creates));

        const updatedLocation =
            location.section === BoardSections.INBOX
                ? (head(creates) && head(creates).location) || location
                : getCanvasUpdatedLocation(state, location, currentBoardId, elements);

        dispatch(
            createElementWithId({
                id,
                elementType,
                location: updatedLocation,
                content,
                sync,
                timestamp: now,
                meta: {
                    creator: userId,
                    modifiedBy: userId,
                    createdTime: now,
                    modifiedTime: now,
                    platform: getApplicationPlatform(),
                    locationSectionModifiedTime: now,
                },
                temp,
                transactionId,
                duplicate,
                duplicatedId,
                creationSource,
                markAsFetched,
            }),
        );

        dispatch(setInboxFromElementCreate({ location, transactionId }));

        if (attachment) {
            dispatch(uploadAttachment({ id, file: attachment, transactionId, attachmentData }));
        }

        return id;
    };

/**
 * TODO - Maybe the above function can instead use this?
 *
 * This is similar to createElementSync however:
 * - Attachments cannot be created using this method
 * - This can handle the creation of multiple elements within a single batched action.
 *
 * IMPORTANT: This requires each element's element ID to be already provided.
 *
 * Benefits:
 * - This reduces the performance impact of creating multiple elements as a single batched state
 *      update occurs, rather than multiple state updates for each element creation.
 * - This improves the likelihood that the server will correctly handle the element creations.
 *      When duplicating multiple elements in the past it appears that the server could become
 *      overwhelmed if there were many elements and some creations would be lost.
 */
export const createMultipleElementsSync =
    (action) =>
    (dispatch, getState, { measurementsStore }) => {
        const {
            elements,
            sync = true,
            timestamp = getTimestamp(),
            markAsFetched = false,
            transactionId = getNewTransactionId(),
        } = action;

        if (!elements) return;

        const state = getState();
        const userId = getCurrentUserId(state);
        const currentBoardId = getCurrentBoardId(state);
        const elementsState = getElements(state);

        // Use the element move utility function to calculate the score
        const creates = elements.map((el) => ({ id: el.id, location: el.location }));
        creates.map(updateLocation(dispatch, getState, { measurementsStore })(creates));

        const updatedLocations = creates.map((create) =>
            create.location?.section === BoardSections.INBOX
                ? create.location
                : getCanvasUpdatedLocation(state, create.location, currentBoardId, elementsState),
        );

        const creationDefinitions = elements.map((elementDefinition, index) => ({
            ...elementDefinition,
            id: elementDefinition.id || elementDefinition._id,
            location: updatedLocations[index] || elementDefinition.location,
            sync: elementDefinition.sync === undefined ? sync : elementDefinition.sync,
            timestamp: elementDefinition.timestamp || timestamp,
            markAsFetched:
                elementDefinition.markAsFetched === undefined ? markAsFetched : elementDefinition.markAsFetched,
            meta: {
                ...elementDefinition.meta,
                creator: elementDefinition.meta?.creator || userId,
                modifiedBy: elementDefinition.meta?.modifiedBy || userId,
                createdTime: elementDefinition.meta?.createdTime || timestamp,
                modifiedTime: elementDefinition.meta?.modifiedTime || timestamp,
                platform: elementDefinition.meta?.platform || getApplicationPlatform(),
            },
            transactionId,
        }));

        // If any of the elements are created in the inbox, set the inbox state
        const inboxCreateLocation = updatedLocations.reduce((acc, location) => {
            if (acc) return acc;
            return location?.section === BoardSections.INBOX ? location : null;
        }, null);

        inboxCreateLocation && dispatch(setInboxFromElementCreate({ location: inboxCreateLocation, transactionId }));

        // Increment the client tick
        dispatch(incrementClientTick(creationDefinitions.length));

        // Create all the elements in one go
        const creationActions = creationDefinitions.map(createElementWithId);
        return dispatch(createBatchAction({ actions: creationActions, transactionId }));
    };

export const createElementSync = (action) => (dispatch, getState) => {
    const { attachment } = action;

    if (attachment) {
        console.warn(
            'An element is attempting to be created synchronously with an attachment.\n' +
                'This means the attachment will be shown after an empty element is created.',
        );
    }

    const id = dispatch(getNewElementId(action));
    return dispatch(createElement(action, id));
};

export const createElementAsync = (action) => async (dispatch, getState) => {
    const { attachment } = action;

    const id = dispatch(getNewElementId(action));
    // If there's an attachment, read it immediately so it's ready before
    const attachmentData = attachment ? await dispatch(prepareElementAttachment({ file: attachment, id })) : null;

    return dispatch(createElement(action, id, attachmentData));
};

const createEditAndSelectElement = (action) => (dispatch, getState) => {
    action.transactionId = action.transactionId || getNewTransactionId();
    const { transactionId, select = true, edit = true, selection, dispatchSaveEditorSelection } = action;

    if (edit) {
        analyticsTimingService.startOperation(NewRelicPageActions.ELEMENT_CREATION, {
            elementType: action.elementType,
            creationSource: action.creationSource,
        });
    }

    return dispatch(createElementAsync(action)).then((elementId) => {
        const state = getState();

        // can't select an element that doesn't exist
        const elements = getElements(state);
        const createdElement = getElement(elements, elementId);
        if (!createdElement) return elementId;

        if (action.currentBoardId && action.location.parentId !== action.currentBoardId) {
            const targetElementType = getElementType(getElement(elements, action.location.parentId));

            const quickNotesBoardId = getCurrentUserQuickNotesRootBoardId(state);
            const quickNotesPopupOpen = popupOpenSelector(PopupIds.QUICK_NOTES)(state);

            const createdInOpenQuickNotes = quickNotesBoardId === action.location.parentId && quickNotesPopupOpen;

            // Don't select or start editing the element if it's dropped into a board
            if (isBoardLike(targetElementType) && !createdInOpenQuickNotes) return elementId;
        }

        select && dispatch(setSelectedElements({ ids: [elementId], transactionId }));

        // Don't start editing images otherwise captions cannot be written straight away
        if (isImage(action)) return elementId;

        const editorId = getMainEditorId({ element: createdElement });
        const editorKey = getMainEditorKey({ element: createdElement });

        if (selection && dispatchSaveEditorSelection) {
            dispatchSaveEditorSelection({ editorId, selection });
        }

        if (edit) {
            // Open comment thread popups if they're attached on creation
            if (isCommentThread(createdElement) && isLocationAttached(createdElement)) {
                const popupId = `collapsed-comment-${elementId}`;
                dispatch(togglePopup(popupId, stubFalse));
            }

            const platformDetails = getPlatformDetailsSelector(state);
            // on iOS, input focus should be set synchronously so it can correctly pull focus.
            if (isPlatformIos(platformDetails)) {
                dispatch(
                    startEditingElement({
                        id: elementId,
                        editorId,
                        editorKey,
                        transactionId,
                    }),
                );
            } else {
                // TODO: Review if this defer & split is required
                // See issue: https://github.com/Milanote/milanote/issues/11710
                defer(() =>
                    dispatch(
                        startEditingElement({
                            id: elementId,
                            editorId,
                            editorKey,
                            transactionId,
                        }),
                    ),
                );
            }
        }

        return elementId;
    });
};

export const createAndEditElement = createEditAndSelectElement;
export const createAndSelectElement = (action) => createEditAndSelectElement({ ...action, edit: false });

export const updateElement = ({ transactionId = getNewTransactionId(), ...rest }) =>
    baseUpdateElement({
        ...rest,
        transactionId,
    });

export const updateMultipleElements = ({ transactionId = getNewTransactionId(), ...rest }) =>
    baseUpdateMultipleElements({
        ...rest,
        transactionId,
    });

const defaultPredicate = negate(isSkeleton);

export const updateSelectedElements =
    ({ changes, selectedElements = undefined, predicate = defaultPredicate, ...rest }) =>
    (dispatch, getState) => {
        const state = getState();
        const elementsToUpdate = selectedElements?.size
            ? selectedElements.filter(predicate)
            : getSelectedElements(state).filter(predicate);
        const updates = asObject(elementsToUpdate.map((element) => ({ id: getElementId(element), changes })));
        return dispatch(updateMultipleElements({ updates, ...rest }));
    };

const getSimpleMoveData = ({ id, location, getState }) => {
    // Get the element's current location, this will be the 'from' location in the action
    const state = getState();
    const elements = getElements(state);
    const element = getElement(elements, id);
    const from = asObject(getElementLocation(element));

    return {
        id,
        location,
        from,
    };
};

export const atomicMoveAndUpdate =
    ({ id, location, changes, sync = true, transactionId = getNewTransactionId() }) =>
    (dispatch, getState) =>
        dispatch({
            type: ELEMENT_MOVE_AND_UPDATE,
            ...getSimpleMoveData({ id, location, getState }),
            changes,
            sync,
            timestamp: getTimestamp(),
            transactionId,
        });

export const createAliasToElement = (action) => (dispatch, getState) => {
    const { elementId, location, permissionId, selectElement = true } = action;

    const state = getState();
    const elements = getElements(state);
    const existingElement = getElement(elements, elementId);

    // Only create new aliases to boards
    if (!existingElement || !isBoardLike(existingElement)) return;

    const targetId = getPhysicalId(existingElement);

    const existingLocation = asObject(getElementLocation(existingElement));

    let newLocation = location;

    if (!newLocation) {
        if (isLocationInbox(existingElement)) {
            newLocation = {
                ...existingLocation,
                position: {
                    index: (propIn(['position', 'index'], existingElement) || 0) + 1,
                },
            };
        } else {
            newLocation = {
                ...existingLocation,
                position: {
                    x: propIn(['position', 'x'], existingLocation) + 6,
                    y: propIn(['position', 'y'], existingLocation) + 5,
                },
            };
        }
    }

    const aliasContent = {
        ...getAliasContentFromBoard(existingElement),
        permissionId,
        linkTo: targetId,
        // This makes the arrow shortcut icon show
        linkAlias: true,
    };

    if (selectElement) {
        return dispatch(
            createAndSelectElement({
                elementType: ElementType.ALIAS_TYPE,
                content: aliasContent,
                location: newLocation,
            }),
        );
    }

    return dispatch(
        createElementAsync({ elementType: ElementType.ALIAS_TYPE, content: aliasContent, location: newLocation }),
    );
};
