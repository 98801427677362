import { produce } from 'immer';
import { SheetAction, SheetActionTypes } from './SheetActionTypes';
import { SheetReducerState } from './sheetTypes';

const INITIAL_STATE: SheetReducerState = {};

export default (state = INITIAL_STATE, action: SheetAction) => {
    switch (action.type) {
        case SheetActionTypes.SHEET_OPEN:
            return produce(state, (draft) => {
                if (!action.sheetId) return;
                draft[action.sheetId] = { active: true, activeSnapPoint: 0 };
            });
        case SheetActionTypes.SHEET_CLOSE:
            return produce(state, (draft) => {
                delete draft[action.sheetId];
            });
        case SheetActionTypes.SHEET_UPDATE_ACTIVE_SNAP_POINT:
            return produce(state, (draft) => {
                const sheet = draft[action.sheetId];
                if (!sheet) return;

                sheet.activeSnapPoint = action.activeSnapPoint;
            });
        default:
            return state;
    }
};
