// Lib
import { findWrapping } from '@tiptap/pm/transform';
import { findParentNodeClosestToPos } from '@tiptap/core';

// Utils
import { findNodeRangesToChangeToList, findSelectedListItemContentNodeRanges } from './tiptapListUtils';

// Operations
import { clearNodesInRange } from '../../utils/tiptapOperations';
import { doWrapInList, liftOutOfList, liftToOuterList } from './proseMirrorSchemaList';

// Types
import { EditorState } from '@tiptap/pm/state';
import { Attrs, NodeRange, NodeType } from '@tiptap/pm/model';
import { TiptapDispatch, TiptapNodeType } from '../../tiptapTypes';

const VALID_LIST_ITEM_TYPES = new Set([TiptapNodeType.listItem, TiptapNodeType.taskItem]);

/**
 * Iterates up the ancestors until it finds a list item type, if any.
 */
const getListItemType = (range: NodeRange): NodeType | null => {
    const { $from } = range;

    let currentIndex = 0;
    let currentNode = $from.node();

    while (currentNode && !VALID_LIST_ITEM_TYPES.has(currentNode.type.name as TiptapNodeType)) {
        currentIndex--;
        currentNode = $from.node(currentIndex);
    }

    return currentNode?.type ?? null;
};

/**
 * Similar to `liftListItem` from prosemirror-schema-list, however this continuously lifts
 * the range towards the document root until it's a child of the document root.
 */
export const liftListRangeToDoc = (state: EditorState, dispatch: TiptapDispatch, range: NodeRange) => {
    const tr = state.tr;

    let currentDepth = range.depth;

    while (currentDepth > 1) {
        const { $from, $to } = range;

        const listItemType = getListItemType(range);

        if (!listItemType) return false;

        const mappedFrom = tr.mapping.map($from.pos);
        const mappedTo = tr.mapping.map($to.pos);

        const $mappedFrom = tr.doc.resolve(mappedFrom);
        const $mappedTo = tr.doc.resolve(mappedTo);

        const blockRange = $mappedFrom.blockRange(
            $mappedTo,
            (node) => node.childCount > 0 && node.firstChild!.type === listItemType,
        );

        if (!blockRange) return false;
        if (blockRange.depth === currentDepth) return false;
        if (!dispatch) return true;

        currentDepth = blockRange.depth;

        if ($mappedFrom.node(blockRange.depth - 1).type === listItemType) {
            // Inside a parent list
            liftToOuterList(state, dispatch, listItemType, blockRange);
        } else {
            // Outer list node
            liftOutOfList(state, dispatch, blockRange);
        }
    }

    return true;
};

/**
 * Similar to `wrapInList` from prosemirror-schema-list, however this wraps the
 * specified range in a list, rather than the selection.
 */
export const wrapInList =
    (listType: NodeType, attrs: Attrs | null = null) =>
    (state: EditorState, dispatch: TiptapDispatch, range: NodeRange) => {
        const { $from, $to } = range;

        let blockRange = $from.blockRange($to);
        let doJoin = false;
        let outerRange = blockRange;

        if (!blockRange) return false;

        // This is at the top of an existing list item
        if (
            blockRange.depth >= 2 &&
            $from.node(blockRange.depth - 1).type.compatibleContent(listType) &&
            blockRange.startIndex == 0
        ) {
            // Don't do anything if this is the top of the list
            if ($from.index(blockRange.depth - 1) == 0) return false;

            const $insert = state.tr.doc.resolve(blockRange.start - 2);

            outerRange = new NodeRange($insert, $insert, blockRange.depth);

            if (blockRange.endIndex < blockRange.parent.childCount) {
                blockRange = new NodeRange($from, state.tr.doc.resolve($to.end(blockRange.depth)), blockRange.depth);
            }

            doJoin = true;
        }

        const wrap = findWrapping(outerRange!, listType, attrs, blockRange);

        if (!wrap) return false;

        if (dispatch) dispatch(doWrapInList(state.tr, blockRange, wrap, doJoin, listType).scrollIntoView());

        return true;
    };

/**
 * Converts an existing list item's parent to a different list type.
 * E.g. If the parent list type is an ordered list, this will convert it to a bullet list.
 */
export const convertListItemParentListType =
    (listType: NodeType) => (state: EditorState, dispatch: TiptapDispatch, range: NodeRange) => {
        const { $from, $to } = range;

        const blockRange = $from.blockRange($to);

        if (!blockRange) return false;

        const parentListNode = findParentNodeClosestToPos(
            blockRange.$from,
            (node) => !!node.type.spec.group?.includes('list'),
        );

        if (!parentListNode) return false;

        if (!dispatch) return true;

        const { pos } = parentListNode;

        state.tr.setNodeMarkup(pos, listType);

        return true;
    };

/**
 * Merges adjacent lists of the same type.
 *
 * E.g:
 *  Ordered List
 *    List Item
 *      Paragraph
 *  Ordered List
 *    List Item
 *      Paragraph
 *
 * Becomes:
 *  Ordered List
 *    List Item
 *      Paragraph
 *    List Item
 *      Paragraph
 *
 * This is useful because the initial steps in the `changeToList` algorithm create individual
 * wrapping lists, regardless of whether they're adjacent to another list.
 */
export const mergeAdjacentLists = (listType: NodeType) => (state: EditorState, dispatch: TiptapDispatch) => {
    if (!dispatch) return true;

    // First find all the points at which the current list type is adjacent to itself
    const nodeEnds: Record<number, boolean> = {};
    const joinPositions: number[] = [];

    const { from, to, $from, $to } = state.tr.selection;

    // Start from the previous node to the current selection's list, if there is one
    const startParentList = findParentNodeClosestToPos($from, (node) => node.type === listType);
    const searchStartingPosition = startParentList ? Math.max(startParentList.pos - 1, 0) : from;

    const endParentList = findParentNodeClosestToPos($to, (node) => node.type === listType);
    const searchEndingPosition = endParentList
        ? Math.min(endParentList.pos + endParentList.node.nodeSize + 1, state.tr.doc.nodeSize - 2)
        : to;

    state.tr.doc.nodesBetween(searchStartingPosition, searchEndingPosition, (node, pos) => {
        // If we're matching the list type and there's already a list ending at this position
        // then we can join them
        if (node.type === listType) {
            nodeEnds[pos + node.nodeSize] = true;
            if (nodeEnds[pos]) joinPositions.push(pos);
        }

        // Recurse all the way to the bottom of every branch
        return true;
    });

    const initialStepsCount = state.tr.steps.length;

    // Then join the points
    joinPositions.forEach((pos) => {
        // Only map positions that have changed while performing this operation
        const mapping = state.tr.mapping.slice(initialStepsCount);

        const mappedPos = mapping.map(pos);

        state.tr.join(mappedPos);
    });

    return true;
};

/**
 * Toggles lists off for any list items within the current selection.
 */
export const removeFromList = (state: EditorState, dispatch?: TiptapDispatch): boolean => {
    // Select all content nodes within the user's selection
    const selectedListItemContentNodeRanges = findSelectedListItemContentNodeRanges(state);

    if (!selectedListItemContentNodeRanges.length) return false;

    for (const nodeRange of selectedListItemContentNodeRanges) {
        const result = liftListRangeToDoc(state, dispatch, nodeRange);
        if (!result) return false;
    }

    if (!dispatch) return true;

    dispatch(state.tr);

    return true;
};

/**
 * Enables the list type for all nodes within the selection that can
 * be that list type.
 */
export const changeToList = (state: EditorState, dispatch: TiptapDispatch, listType: NodeType): boolean => {
    if (!listType) return false;

    // First get all the nodes in the selection that aren't the same list type
    const nodeRanges = findNodeRangesToChangeToList(state, listType.name);

    // Then change the type of these nodes to the new list type
    for (const nodeRange of nodeRanges) {
        const { $from, $to } = nodeRange;

        let $mappedFrom = state.tr.doc.resolve(state.tr.mapping.map($from.pos));
        let $mappedTo = state.tr.doc.resolve(state.tr.mapping.map($to.pos));
        let mappedNodeRange = new NodeRange($mappedFrom, $mappedTo, $from.depth);

        const shouldClearNodes = $mappedFrom.nodeAfter?.type !== state.schema.nodes.listItem;

        shouldClearNodes
            ? // Change the nodes back to paragraphs
              clearNodesInRange(state, dispatch, mappedNodeRange)
            : // Change the parent list type to be the same as the new list type
              convertListItemParentListType(listType)(state, dispatch, mappedNodeRange);

        $mappedFrom = state.tr.doc.resolve(state.tr.mapping.map($from.pos));
        $mappedTo = state.tr.doc.resolve(state.tr.mapping.map($to.pos));
        mappedNodeRange = new NodeRange($mappedFrom, $mappedTo, $from.depth);

        // Wrap them in lists
        wrapInList(listType)(state, dispatch, mappedNodeRange);
    }

    // Then join the nodes together if the nodes before or after are the same list type
    mergeAdjacentLists(listType)(state, dispatch);

    if (!dispatch) return true;

    dispatch(state.tr);

    return true;
};
