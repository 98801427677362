// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

// Utils
import { getShowAssignments } from '../../../../../../common/elements/utils/elementPropertyUtils';
import { getSelectedTaskIdSelector, getSelectedTaskSelector } from '../../../selector/selectionToolsSelector';

// Actions
import { toggleElementShowAssignments } from '../../../../../element/task/pills/assignment/taskAssignmentActions';

// Components
import Icon from '../../../../../components/icons/Icon';
import ToolbarTool from '../../ToolbarTool';

const isShowingAssignmentsSelector = createSelector(getSelectedTaskSelector, getShowAssignments);

const mapStateToProps = createStructuredSelector({
    selectedTaskId: getSelectedTaskIdSelector,
    isShowingAssignments: isShowingAssignmentsSelector,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchToggleShowAssignments: (id) => dispatch(toggleElementShowAssignments({ id })),
});

const AssignUserTool = (props) => {
    const { isShowingAssignments, dispatchToggleShowAssignments, selectedTaskId } = props;

    const onToggle = React.useCallback(() => {
        dispatchToggleShowAssignments(selectedTaskId);
    }, [selectedTaskId]);

    return (
        <div className="AssignUserTool">
            <ToolbarTool {...props} onClick={onToggle} name="Assign" isActive={isShowingAssignments}>
                <Icon name="toolbar-assign" />
            </ToolbarTool>
        </div>
    );
};

AssignUserTool.propTypes = {
    selectedTaskId: PropTypes.string,
    isShowingAssignments: PropTypes.bool,
    dispatchToggleShowAssignments: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignUserTool);
