import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementTaskListIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={24}
        viewBox="0 0 32 24"
        className="Icon element-task-list"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={24} fill="#FFF" rx={2} />
            <path
                fill="#48505E"
                d="M6.28 7.77a.75.75 0 0 0-1.06 1.06l1.5 1.5a.75.75 0 0 0 1.06 0l3-3a.75.75 0 0 0-1.06-1.06L7.25 8.74l-.97-.97zm0 6.5a.75.75 0 0 0-1.06 1.06l1.5 1.5a.75.75 0 0 0 1.06 0l3-3a.75.75 0 0 0-1.06-1.06l-2.47 2.47-.97-.97zM14.75 8h11.3a.75.75 0 1 1 0 1.5h-11.3a.75.75 0 1 1 0-1.5zm0 6.4h11.3a.75.75 0 1 1 0 1.5h-11.3a.75.75 0 1 1 0-1.5z"
            />
        </g>
    </svg>
);
const Memo = memo(ElementTaskListIcon);
export default Memo;
