import { SheetId, SheetReducerState } from './sheetTypes';

export const getSheet = (state: unknown): SheetReducerState =>
    (state as Immutable.Map<string, object>).getIn(['app', 'sheet']) as SheetReducerState;

export const getIsSheetOpen =
    (sheetId: SheetId) =>
    (state: unknown): boolean =>
        getSheet(state)[sheetId]?.active ?? false;

export const getIsAnySheetOpen = (state: unknown): boolean => {
    const sheetState = getSheet(state);
    return Object.values(sheetState).some((sheet) => sheet?.active);
};

export const getSheetActiveSnapPoint =
    (sheetId: SheetId) =>
    (state: unknown): number =>
        getSheet(state)[sheetId]?.activeSnapPoint ?? 0;
