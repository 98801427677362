// Lib
import { useMemo, useRef, useState } from 'react';
import requestSecondAnimationFrame from '../../../../../common/utils/lib/requestSecondAnimationFrame';

// Hooks
import useSheetInitialisation from './useSheetInitialisation';
import useSheetHandlers, { SheetHandlers } from './useSheetHandlers';
import useSheetSnapPointState from './useSheetSnapPointState';

// Utils
import { runAfterNextTransitionEnds } from '../../../../utils/dom/domEventUtil';
import { setCurrentHeightForAnimation } from '../utils/snapPointUtils';
import { parseCssPx } from '../../../../utils/cssUtil';

// Types
import { SheetProps } from '../SheetContainer';

export const MAX_SHEET_HEIGHT = 1;
export const MAX_SUFFIX = 'max';

const getSafeAreaTopValue = () => getComputedStyle(document.documentElement).getPropertyValue('--safe-area-top');

export interface SheetContextState extends SheetHandlers {
    sheetContentRef: React.RefObject<HTMLDivElement>;
    sheetRef: React.RefObject<HTMLDivElement>;
    snapPointsState: number[];
    isSheetMounted: boolean;
    setIsSheetMounted: (isMounted: boolean) => void;
    closeSheetWithTransition: () => void;
}

const useSheetContextState = (props: SheetProps): SheetContextState => {
    const {
        snapPoints = [],
        defaultSnapPoint,
        isSheetOpen,
        dispatchCloseSheet,
        activeSnapPoint,
        dispatchUpdateActiveSnapPoint,
        onSheetClose,
        dismissible = true,
        onCloseTransitionEnd,
    } = props;

    const safeAreaTop = useMemo(getSafeAreaTopValue, []);
    const safeWindowHeight = window.innerHeight - parseCssPx(safeAreaTop);

    const sheetRef = useRef<HTMLDivElement>(null);
    const sheetContentRef = useRef<HTMLDivElement>(null);

    const [isSheetMounted, setIsSheetMounted] = useState(false);
    const closeAnimationInProgress = useRef(false);

    const { snapPointsState, goToSnapPoint, addSnapPoint } = useSheetSnapPointState(
        snapPoints,
        sheetRef,
        activeSnapPoint,
        dispatchUpdateActiveSnapPoint,
        dismissible,
        safeWindowHeight,
    );

    const closeSheetWithTransition = () => {
        if (!dismissible || !isSheetMounted || closeAnimationInProgress.current) return;

        onSheetClose?.();

        if (sheetRef.current?.style.height === 'auto') {
            setCurrentHeightForAnimation(sheetRef);
        }

        requestSecondAnimationFrame(() => {
            closeAnimationInProgress.current = true;
            goToSnapPoint(0);

            runAfterNextTransitionEnds(sheetRef.current, () => {
                closeAnimationInProgress.current = false;
                setIsSheetMounted(false);
                dispatchCloseSheet();
                onCloseTransitionEnd?.();
            });
        });
    };

    const sheetInitialised = useSheetInitialisation(
        isSheetOpen,
        sheetRef,
        defaultSnapPoint,
        addSnapPoint,
        goToSnapPoint,
        closeSheetWithTransition,
        isSheetMounted,
        closeAnimationInProgress,
        safeWindowHeight,
    );

    const handlers = useSheetHandlers(
        activeSnapPoint,
        sheetRef,
        sheetContentRef,
        sheetInitialised,
        snapPointsState,
        goToSnapPoint,
        closeSheetWithTransition,
        safeWindowHeight,
    );

    return {
        ...handlers,
        sheetContentRef,
        sheetRef,
        snapPointsState,
        closeSheetWithTransition,
        isSheetMounted,
        setIsSheetMounted,
    };
};

export default useSheetContextState;
