// Lib
import { generateJSON } from '@tiptap/core';

// Extensions
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import HardBreak from '@tiptap/extension-hard-break';

// Types
import { TiptapContent } from '../../tiptapTypes';

export const SIMPLE_CONTENT_EXTENSIONS = [Document, Paragraph, Text, HardBreak];

const convertSimpleStringToHtml = (textContent = ''): string => textContent.replace(/\n/g, '<br/>');

const convertSimpleStringToTiptapContent = (textContent = ''): TiptapContent => {
    const htmlContent = convertSimpleStringToHtml(textContent);
    return generateJSON(htmlContent, SIMPLE_CONTENT_EXTENSIONS) as TiptapContent;
};

export default convertSimpleStringToTiptapContent;
