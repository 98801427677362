// Lib
import { ContentState, convertToRaw, RawDraftContentState } from 'draft-js';

// Utils
import globalLogger from '../../logger';
import { getNewTransactionId } from '../../utils/undoRedo/undoRedoTransactionManager';
import { getListChildNewLocation } from '../../../common/elements/utils/elementLocationUtils';
import { getCurrentlyEditingId } from '../selection/currentlyEditingSelector';
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { getCaption, getColor, getShowCaption, getWidth } from '../../../common/elements/utils/elementPropertyUtils';
import convertSimpleStringToTiptapContent from '../../../common/tiptap/utils/createJsonContentUtils/convertSimpleStringToTiptapContent';

// Selectors
import { getIsFeatureEnabledForCurrentUser } from '../feature/elementFeatureSelector';
import { getElements } from '../selectors/elementsSelector';

// Actions
import { createAndEditElement } from '../actions/elementActions';
import { setLinkElementUrl } from '../link/linkActions';
import { setElementTypeAndUpdateElement } from '../../../common/elements/elementActions';
import { setSelectedElements } from '../selection/selectionActions';
import { setElementLocalData } from '../local/elementLocalDataActions';

// Constants
import { ElementType } from '../../../common/elements/elementTypes';
import {
    COLOR_SWATCH_DEFAULT_HEIGHT_GRID,
    COLOR_SWATCH_DEFAULT_WIDTH_GRID,
} from '../../../common/colorSwatches/colorSwatchesConstants';
import { GRID } from '../../utils/grid/gridConstants';
import { TiptapContentNode } from '../../../common/tiptap/tiptapTypes';
import { ColorObject } from '../../../common/colors/colorObjectUtil';
import { ExperimentId } from '../../../common/experiments/experimentsConstants';

const logger = globalLogger.createChannel('universalCardActions');

type LinkConfig = {
    id: string;
    elementType: ElementType;
    data: {
        url: string;
    };
};

const updateElementTypeToLink =
    ({ id, elementType, data }: LinkConfig) =>
    (dispatch: Function, getState: Function) => {
        const transactionId = getNewTransactionId();
        const state = getState();

        const shouldUseTiptapContent = getIsFeatureEnabledForCurrentUser(ExperimentId.tiptapConversion)(state);

        const { url } = data;

        const rawState = shouldUseTiptapContent
            ? convertSimpleStringToTiptapContent(url)
            : convertToRaw(ContentState.createFromText(url));

        const undoChanges = { textContent: rawState, caption: null, showCaption: false, ignoreAutoType: true };

        dispatch(setElementTypeAndUpdateElement({ id, elementType, undoChanges, transactionId, changes: undefined }));
        dispatch(setElementLocalData({ id, data: { url } }));
        dispatch(setLinkElementUrl({ id, url, transactionId }));
    };

type TaskConfig = {
    id: string;
    elementType: ElementType;
    data: {
        content: {
            isComplete: boolean;
        };
    };
};

const updateElementTypeToTask =
    ({ id, data }: TaskConfig) =>
    (dispatch: Function) => {
        const transactionId = getNewTransactionId();

        dispatch(
            setElementTypeAndUpdateElement({
                id,
                elementType: ElementType.TASK_LIST_TYPE,
                transactionId,
                changes: undefined,
                undoChanges: undefined,
            }),
        );
        dispatch(
            createAndEditElement({
                elementType: ElementType.TASK_TYPE,
                location: getListChildNewLocation({ listId: id }),
                content: data.content,
                transactionId,
                select: false,
            }),
        );
        dispatch(setSelectedElements({ ids: [id], rangeAnchors: null, transactionId }));
    };

type SwatchConfig = {
    id: string;
    elementType: ElementType;
    data: {
        originalText: string;
        color: ColorObject;
        // TODO-TIPTAP don't allow the string
        caption: TiptapContentNode | RawDraftContentState | string;
        width?: number;
        media?: {
            height: number;
            width: number;
        };
        showColorValue?: boolean;
        showCaption?: boolean;
    };
};

const updateElementTypeToSwatch =
    ({ id, elementType, data }: SwatchConfig) =>
    (dispatch: Function, getState: Function) => {
        const { originalText } = data;

        const transactionId = getNewTransactionId();
        const state = getState();

        const elements = getElements(state);
        const currentElement = getElement(elements, id);

        const shouldUseTiptapContent = getIsFeatureEnabledForCurrentUser(ExperimentId.tiptapConversion)(state);

        const rawState = shouldUseTiptapContent
            ? convertSimpleStringToTiptapContent(originalText)
            : convertToRaw(ContentState.createFromText(originalText));

        const undoChanges = {
            textContent: rawState,
            caption: getCaption(currentElement),
            showCaption: getShowCaption(currentElement),
            ignoreAutoType: true,
            color: getColor(currentElement),
            media: null,
            width: getWidth(currentElement),
            showColorValue: null,
        };

        return dispatch(
            setElementTypeAndUpdateElement({
                id,
                elementType,
                changes: {
                    width: COLOR_SWATCH_DEFAULT_WIDTH_GRID,
                    media: {
                        height: GRID.LARGE.size * COLOR_SWATCH_DEFAULT_HEIGHT_GRID,
                        width: GRID.LARGE.size * COLOR_SWATCH_DEFAULT_WIDTH_GRID,
                    },
                    showColorValue: true,
                    showCaption: true,
                    ...data,
                },
                undoChanges,
                transactionId,
            }),
        );
    };

type UpdateElementTypeConfig = SwatchConfig | TaskConfig | LinkConfig;

export const updateElementType = (elementConfig: UpdateElementTypeConfig) => (dispatch: Function) => {
    switch (elementConfig.elementType) {
        case ElementType.LINK_TYPE:
            return dispatch(updateElementTypeToLink(elementConfig as LinkConfig));
        case ElementType.TASK_TYPE:
            return dispatch(updateElementTypeToTask(elementConfig as TaskConfig));
        case ElementType.COLOR_SWATCH_TYPE:
            return dispatch(updateElementTypeToSwatch(elementConfig as SwatchConfig));
        default:
            return dispatch(
                setElementTypeAndUpdateElement({
                    id: elementConfig.id,
                    elementType: elementConfig.elementType,
                    changes: elementConfig.data,
                    undoChanges: undefined,
                    transactionId: undefined,
                }),
            );
    }
};

/**
 * Performs an elementType change on the element that's currently being edited.
 */
export const updateCurrentlyEditingElementType =
    (config: Omit<UpdateElementTypeConfig, 'id'>) => (dispatch: Function, getState: Function) => {
        const state = getState();
        const currentlyEditingId = getCurrentlyEditingId(state) as string;

        if (!currentlyEditingId) {
            logger.warn('No currently editing element found');
            return;
        }

        // @ts-ignore Errors relating to config types not matching...
        return dispatch(updateElementType({ id: currentlyEditingId, ...config }));
    };
