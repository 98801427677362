export const UNDO_STACK_LIMIT = 500;
export const UNDO_STACK_SLICE_SIZE = 400;

export const START_OPERATION = 'START_OPERATION';
export const END_OPERATION = 'END_OPERATION';

export const POP_UNDO_STACK_TO = 'POP_UNDO_STACK_TO';
export const UNDO_REDO_FINISH = 'UNDO_REDO_FINISH';
export const UNDO_REDO_REMOVE_TRANSACTION = 'UNDO_REDO_REMOVE_TRANSACTION';

export const IGNORE_UNDO_REDO_TRANSACTION_ID = -1;
