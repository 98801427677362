// Lib
import { Extension } from '@tiptap/react';
import { Plugin, PluginKey } from '@tiptap/pm/state';

// Utils
import keepRangeWithinNode from '../utils/selection/keepRangeWithinNode';
import createSimpleClassDecorationState from './createSimpleClassDecorationState';
import isSelectionSpanningEntireNodes from '../utils/selection/isSelectionSpanningEntireNodes';
import expandRangeToHighestContainingNode from '../utils/selection/expandRangeToHighestContainingNode';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        clipperCommands: {
            applyClippingClass: () => ReturnType;
            clearClippingClass: () => ReturnType;
            startClip: () => ReturnType;
            abortClip: () => ReturnType;
            commitClip: (transactionId: number, wasCopy: boolean) => ReturnType;
        };
    }
}

const PLUGIN_NAME = 'clipper';
const createClipperPlugin = () =>
    new Plugin({
        key: new PluginKey(PLUGIN_NAME),
        state: createSimpleClassDecorationState(PLUGIN_NAME),
        props: {
            decorations(state) {
                return this.getState(state);
            },
        },
    });

/**
 * Commands for card clipper operations.
 */
export const ClipperCommands = Extension.create({
    name: 'clipperCommands',

    addCommands() {
        return {
            applyClippingClass:
                () =>
                ({ tr, dispatch }) => {
                    if (!dispatch) return true;
                    dispatch(tr.setMeta(PLUGIN_NAME, 'clipped-text'));
                    return true;
                },
            clearClippingClass:
                () =>
                ({ tr, dispatch }) => {
                    if (!dispatch) return true;
                    dispatch(tr.setMeta(PLUGIN_NAME, ''));
                    return true;
                },
            startClip:
                () =>
                ({ editor, commands }) => {
                    editor.storage.externalStoreSynchroniser.pauseUpdates(this.name);
                    return commands.applyClippingClass();
                },
            abortClip:
                () =>
                ({ editor, commands }) => {
                    editor.storage.externalStoreSynchroniser.resumeUpdates(this.name);
                    return commands.clearClippingClass();
                },
            commitClip:
                (transactionId, wasCopy) =>
                ({ editor, commands, chain }) => {
                    editor.storage.externalStoreSynchroniser.resumeUpdates(this.name);

                    if (wasCopy) return commands.clearClippingClass();

                    const { selection, doc } = editor.state;

                    // If the selection spans the entire nodes, we should delete the nodes, rather than just the text
                    const shouldDeleteNodes = isSelectionSpanningEntireNodes(selection);

                    const deletionRange = shouldDeleteNodes
                        ? expandRangeToHighestContainingNode(selection, doc)
                        : keepRangeWithinNode(selection, doc);

                    return chain()
                        .clearClippingClass()
                        .deleteRange(deletionRange)
                        .flushPendingUpdate({ transactionId })
                        .run();
                },
        };
    },

    /**
     * Add the clipper plugin so that we can show the clipping highlight.
     */
    addProseMirrorPlugins() {
        return [createClipperPlugin()];
    },
});
