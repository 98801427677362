// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { getIsCollapsed } from '../../../common/elements/utils/elementPropertyUtils';

// Actions
import { forceMeasureAllCanvasElements } from '../../components/measurementsStore/elementMeasurements/elementMeasurementsActions';

// Components
import Icon from '../../components/icons/Icon';

// Styles
import './ColumnCollapseButton.scss';

const mapDispatchToProps = (dispatch) => ({
    dispatchForceMeasureAllCanvasElements: () => dispatch(forceMeasureAllCanvasElements()),
});

const ColumnCollapseButton = (props) => {
    const { show, dispatchUpdateElement, dispatchForceMeasureAllCanvasElements, elementId, element, isEditable } =
        props;

    const collapsed = getIsCollapsed(element);

    const onClick = React.useCallback(
        async (event) => {
            event.stopPropagation();

            await dispatchForceMeasureAllCanvasElements();

            dispatchUpdateElement({
                id: elementId,
                changes: {
                    collapsed: !collapsed,
                },
                sync: isEditable,
            });
        },
        [collapsed],
    );

    if (!show) return null;

    return (
        <div className="ColumnCollapseButton" onClick={onClick}>
            <Icon name="column-collapse" />
        </div>
    );
};

ColumnCollapseButton.propTypes = {
    show: PropTypes.bool,
    elementId: PropTypes.string,
    element: PropTypes.object,
    isEditable: PropTypes.bool,
    dispatchUpdateElement: PropTypes.func,
    dispatchForceMeasureAllCanvasElements: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(ColumnCollapseButton);
