// Lib
import React from 'react';
import classNames from 'classnames';

// Utils
import platformSingleton from '../../../platform/platformSingleton';

// Components
import TooltipSource from '../../../components/tooltips/TooltipSource';

// Constants
import { TooltipPositions } from '../../../components/tooltips/tooltipConstants';

// Styles
import './Tool.scss';

type ToolProps = {
    children: React.ReactNode;
    name?: string;
    iconName?: string;
    isDraggable?: boolean;
    isDragging?: boolean;
    isActive?: boolean;
    onClick?: (event: React.MouseEvent) => void;
    className?: string;
    id?: string;
    tooltip?: string;
    disabled?: boolean;
    tooltipEnabled?: boolean;
    mobileAllowPointerDown?: boolean;
    tooltipText?: string;
    shortcut?: string[];
    preventFocusChange?: boolean;
};

const Tool = (props: ToolProps) => {
    const classes = {
        dragging: props.isDragging,
        active: props.isActive,
        draggable: props.isDraggable,
        hoverable: platformSingleton.features.supportsMouse,
        disabled: props.disabled,
    };

    const toolNameElement = props.name ? <span className="tool-name">{props.name}</span> : null;

    return (
        <div
            id={props.id}
            className={classNames('Tool', props.className, classes)}
            onClick={props.onClick}
            onPointerDown={(event) => props.preventFocusChange && event.preventDefault()}
        >
            <div className="contents">
                <TooltipSource
                    enabled={props.tooltipEnabled}
                    tooltipText={props.tooltipText}
                    shortcut={props.shortcut}
                    position={TooltipPositions.RIGHT}
                    delay={700}
                    pollPosition
                    triggerOnMouseEnter
                >
                    <div className="icon">
                        {props.isDraggable && <div className="draggable-shadow">{props.children}</div>}
                        {props.children}
                    </div>
                </TooltipSource>
                {toolNameElement}
            </div>
        </div>
    );
};

export default Tool;
