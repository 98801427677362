import DefaultOrderedList from '@tiptap/extension-ordered-list';
import { mergeAttributes } from '@tiptap/core';

export const OrderedList = DefaultOrderedList.extend({
    addOptions() {
        return {
            ...this.parent?.(),
            HTMLAttributes: { class: 'list-el' },
        };
    },

    /**
     * WEB-11755 - Due to a Chrome bug, we need to use custom list counters.
     *   In order to allow the "start" attribute to work correctly, we need to set the
     *   `--list-start` CSS variable to the value of the "start" attribute.
     *
     *   So this just overrides the OOTB renderHTML method to set the CSS variable.
     */
    renderHTML({ HTMLAttributes }) {
        const { start, ...attributesWithoutStart } = HTMLAttributes;

        const attributesWithStart = {
            ...HTMLAttributes,
            style: `--list-start: ${start - 1}`,
        };

        return start === 1
            ? ['ol', mergeAttributes(this.options.HTMLAttributes, attributesWithoutStart), 0]
            : ['ol', mergeAttributes(this.options.HTMLAttributes, attributesWithStart), 0];
    },

    addKeyboardShortcuts() {
        return {
            /* eslint-disable @typescript-eslint/naming-convention */
            // Keeping the original Tiptap shortcut, in case it's standard
            'Mod-Shift-7': () => this.editor.commands.toggleOrderedList(),
            'Mod-Shift-O': () => this.editor.commands.toggleOrderedList(),
        };
    },
});
