// Lib
import React, { ReactElement } from 'react';

// Utils
import { getTextContent } from '../../../common/elements/utils/elementPropertyUtils';

// Hooks
import { useElementTiptapContent } from '../../../common/tiptap/conversion/elementConversion/useElementTiptapContent';

// Components
import CardDraftEditor from './CardDraftEditor';
import CardTiptapEditor from './tiptap/CardTiptapEditor';

// Types
import { RawDraftContentState } from 'draft-js';
import { TiptapContent } from '../../../common/tiptap/tiptapTypes';
import { ImMNElement } from '../../../common/elements/elementModelTypes';
import { DocumentType } from '../../../common/tiptap/conversion/elementConversion/elementConversion';

interface CardEditorProps {
    element: ImMNElement;
    isEditing: boolean;
    isEditable: boolean;
    isSingleSelected: boolean;
    isFocusedForegroundElement: boolean;
    currentEditorId: string;
    editorRef: (editor: HTMLDivElement | null) => void;
    saveContent: (content: TiptapContent | RawDraftContentState, transactionId?: number) => void;
    startEditing: () => void;
    moveElementsToTrash: () => void;
    createNewCard: () => void;
}

const CardEditor = (props: CardEditorProps): ReactElement => {
    const {
        element,
        currentEditorId,
        isEditable,
        isEditing,
        isSingleSelected,
        isFocusedForegroundElement,
        saveContent,
        startEditing,
        editorRef,
        moveElementsToTrash,
        createNewCard,
    } = props;

    const textContent = getTextContent(element);
    const tiptapContent = useElementTiptapContent(element, textContent, DocumentType.textContent);

    if (tiptapContent) {
        return (
            <CardTiptapEditor
                element={element}
                textContent={tiptapContent}
                currentEditorId={currentEditorId}
                isEditable={isEditable}
                isEditing={isEditing}
                isSingleSelected={isSingleSelected}
                isFocusedForegroundElement={isFocusedForegroundElement}
                saveContent={saveContent}
                startEditing={startEditing}
                editorRef={editorRef}
                moveElementsToTrash={moveElementsToTrash}
                createNewCard={createNewCard}
            />
        );
    }

    return <CardDraftEditor {...props} textContent={textContent} />;
};

export default CardEditor;
