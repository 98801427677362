// Libs
import React from 'react';
import { head, includes, negate } from 'lodash';
import { createSelector } from 'reselect';
import { compose, withHandlers } from '../../../node_module_clones/recompose';
import { connect } from 'react-redux';

// Components
import ElementContextMenuItems from './ElementContextMenuItems';

// Selectors
import { getElements } from '../../element/selectors/elementSelector';
import { getSelectedElements } from '../../element/selection/selectedElementsSelector';
import { getClipboardElements } from '../shortcuts/clipboard/clipboardSelectors';
import { getUsers } from '../../user/usersSelector';
import { getCurrentUser } from '../../user/currentUserSelector';
import { getCurrentlyEditingId } from '../../element/selection/currentlyEditingSelector';
import { getCurrentBoardId } from '../../reducers/currentBoardId/currentBoardIdSelector';
import { getMeasurementsMap } from '../../components/measurementsStore/elementMeasurements/elementMeasurementsSelector';
import { getPlatformDetailsSelector } from '../../platform/platformSelector';

// Actions
import { pasteElements, saveToClipboard } from '../shortcuts/clipboard/clipboardActions';
import { deselectAllElements, startEditingElement } from '../../element/selection/selectionActions';
import {
    createAliasToElement,
    setElementTypeAndUpdateElement,
    updateMultipleElements,
} from '../../element/actions/elementActions';
import { convertToSketch } from '../../element/drawing/annotation/store/annotationActions';
import { convertColumnToBoard, createColumnWithChildren } from '../../element/column/columnActions';
import { moveMultipleElements } from '../../element/actions/elementMoveActions';
import { navigateToSelectedBoardInNewTab } from '../../reducers/navigationActions';
import { updateSelectedElementsColors } from '../toolbar/components/selectionTools/colorTools/colorActions';
import { connectElementsWithLines } from '../toolbar/components/selectionTools/connectTool/connectionActions';
import { convertBoardToTemplate, revertTemplateToBoard } from '../boardTemplatePickerPopup/boardTemplateActions';
import {
    cloneSelectedElement,
    duplicateSelectedElements,
    moveElementsToTrash,
} from '../../element/actions/elementShortcutActions';
import { convertElementsToDocument } from '../../element/document/documentActions';

// Utils
import platformSingleton from '../../platform/platformSingleton';
import randomString from '../../../common/uid/randomString';
import { triggerDownload } from '../../element/attachments/attachmentDownloadActions';
import { isGlobalDebugEnabled } from '../../debug/debugUtil';
import { downloadAsSvg } from '../../element/drawing/utils/drawingUtils';
import measurementsConnect from '../../components/measurementsStore/measurementsConnect';
import { getNewTransactionId } from '../../utils/undoRedo/undoRedoTransactionManager';
import { getBoundingRectInGridUnits } from '../../canvas/dnd/canvasUtil';
import { sortByScoreProperty, sortBy2dPosition } from '../../../common/elements/utils/elementSortUtils';
import { getChildren, getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { getMainEditorId, getMainEditorKey } from '../../element/utils/elementEditorUtils';
import {
    getHighestScore,
    getLowestScore,
    isLocationCanvas,
    isLocationInbox,
} from '../../../common/elements/utils/elementLocationUtils';
import {
    canBeCloned,
    isAlias,
    isAnnotation,
    isBoard,
    isBoardLike,
    isColumn,
    isCommentThread,
    isContainer,
    isDocument,
    isDocumentLike,
    isFile,
    isImage,
    isLine,
    isLink,
    isCard,
    isTaskList,
    isDrawing,
    isTable,
} from '../../../common/elements/utils/elementTypeUtils';
import {
    getCanvasOriginCoordinates,
    getCreatedTime,
    getCreatorId,
    getDrawingSvg,
    getElementId,
    getElementType,
    getIsLineEndSnapped,
    getIsLineStartSnapped,
    getLinkUrl,
    getLocationParentId,
    getLocationPosition,
    getMediaType,
    getModifiedBy,
    getModifiedTime,
    isElementLocked,
} from '../../../common/elements/utils/elementPropertyUtils';
import { isMediaImageShowing } from '../../../common/elements/utils/elementMediaUtil';
import { isVideoFile } from '../../element/file/utils/fileGetMediaPlayerType';
import { getUserId } from '../../../common/users/utils/userPropertyUtils';
import { canBeAColumnChild } from '../../../common/columns/columnUtils';

import { asObject, prop } from '../../../common/utils/immutableHelper';

// Constants
import { ClipboardOperation } from '../shortcuts/clipboard/clipboardConstants';
import { DEFAULT_INCREMENT } from '../../../common/elements/listOrderScores/scoreCalculator';
import { ELEMENT_DEFAULT_WIDTH, ELEMENT_MOVE_OPERATIONS } from '../../../common/elements/elementConstants';
import { COLUMN_DEFAULT_HEIGHT_IN_GRID_UNIT } from '../../../common/columns/columnConstants';
import { MEDIA_TYPES } from '../../../common/links/richMediaConstants';
import { MINIMUM_CANVAS_ORIGIN } from '../../../common/elements/utils/elementPositionUtils';
import { BrowserEngineType } from '../../platform/platformTypes';
import { ElementType } from '../../../common/elements/elementTypes';

const getFirstCreatorId = (elements) => getCreatorId(elements.first());
const getFirstModifierId = (elements) => getModifiedBy(elements.first());

const sameCreator = (userId) => (element) => userId === getCreatorId(element);
const sameModifier = (userId) => (element) => userId === getModifiedBy(element);

const mapStateToProps = createSelector(
    getElements,
    getSelectedElements,
    getClipboardElements,
    getUsers,
    getCurrentUser,
    getCurrentBoardId,
    getPlatformDetailsSelector,
    (elements, selectedElements, clipboardElements, users, currentUser, currentBoardId, platformDetails) => ({
        elements,
        selectedElements,
        currentUserId: getUserId(currentUser),
        singleBoard: selectedElements.size === 1 && selectedElements.every(isBoardLike),
        singleLink: selectedElements.size === 1 && selectedElements.every(isLink),
        singleFile: selectedElements.size === 1 && selectedElements.every(isFile),
        singleImage: selectedElements.size === 1 && selectedElements.every(isImage),
        singlePopulatedDrawing:
            selectedElements.size === 1 && selectedElements.every((el) => isDrawing(el) && !!getDrawingSvg(el)),
        allImages: selectedElements.every(isImage),
        showPaste: selectedElements.every(isColumn) && !!clipboardElements.size,
        lockToolWillEnableLock: !selectedElements.find(isElementLocked),
        showCutCopy: !selectedElements.some(isCommentThread),
        showReplaceImage: selectedElements.every((el) => isImage(el) || isVideoFile(el) || isMediaImageShowing(el)),
        isVideo: selectedElements.every((el) => isVideoFile(el) || getMediaType(el) == MEDIA_TYPES.VIDEO),
        showRename:
            selectedElements.size === 1 &&
            (isContainer(selectedElements.first()) || isDocumentLike(selectedElements.first())),
        showGroupInColumn:
            // If an element's parent ID isn't the current board then it can't be sent into a column
            selectedElements.every((el) => getLocationParentId(el) === currentBoardId && canBeAColumnChild(el)),
        showConnect: selectedElements.filter((el) => !isLine(el) && isLocationCanvas(el)).size > 1,
        showConvertToSketch: selectedElements.size === 1 && isAnnotation(selectedElements.first()),
        showConvertTemplate: selectedElements.filter(isBoard).size === 1,
        showDuplicateAliasToBoard: selectedElements.filter(isAlias).size === 1,
        showDevTools: isGlobalDebugEnabled(),
        showSendToBack: isLine(selectedElements.first())
            ? !getIsLineStartSnapped(selectedElements.first()) && !getIsLineEndSnapped(selectedElements.first())
            : !selectedElements.some(isLocationInbox),
        showBringToFront: !selectedElements.some(isLocationInbox),
        showClone: selectedElements.size === 1 && selectedElements.every(canBeCloned),
        showColorTool: !selectedElements.some(isTable),
        showConvertToNote: selectedElements.every(isDocument),
        showConvertToDocument: selectedElements.every(isCard),
        showConvertToBoard: selectedElements.every(isColumn),
        createdTime: selectedElements.size === 1 ? getCreatedTime(selectedElements.first()) : undefined,
        modifiedTime: selectedElements.size === 1 ? getModifiedTime(selectedElements.first()) : undefined,
        createdUser: selectedElements.every(sameCreator(getFirstCreatorId(selectedElements)))
            ? users.get(getFirstCreatorId(selectedElements))
            : undefined,
        modifiedUser: selectedElements.every(sameModifier(getFirstModifierId(selectedElements)))
            ? users.get(getFirstModifierId(selectedElements))
            : undefined,
        platformDetails,
    }),
);

const mapDispatchToProps = (dispatch) => ({
    dispatchGetCurrentlyEditingId: () => dispatch((_, getState) => getCurrentlyEditingId(getState())),
    dispatchSaveToClipBoard: (args) => dispatch(saveToClipboard(args)),
    dispatchPasteElements: () => dispatch(pasteElements({})),
    dispatchDuplicateElements: () => dispatch(duplicateSelectedElements({ shouldConvertAliasToBoard: false })),
    dispatchCloneElement: () => dispatch(cloneSelectedElement()),
    dispatchDuplicateAliasToBoard: () => dispatch(duplicateSelectedElements({ shouldConvertAliasToBoard: true })),
    dispatchMoveElementsToTrash: (args) => dispatch(moveElementsToTrash(args)),
    dispatchStartEditingElement: (args) => dispatch(startEditingElement(args)),
    dispatchUpdateElements: (args) => dispatch(updateMultipleElements(args)),
    dispatchGroupInColumn: (args) => dispatch(createColumnWithChildren(args)),
    dispatchConnectWithLines: (elements) => dispatch(connectElementsWithLines(elements)),
    dispatchConvertToSketch: (elementId) => dispatch(convertToSketch({ elementId })),
    dispatchSetColor: (color) => dispatch(updateSelectedElementsColors({ color })),
    dispatchDeselectAll: () => dispatch(deselectAllElements()),
    dispatchMoveMultipleElements: (args) => dispatch(moveMultipleElements(args)),
    dispatchCreateShortcut: (args) => dispatch(createAliasToElement(args)),
    dispatchOpenInNewTab: () => dispatch(navigateToSelectedBoardInNewTab()),
    dispatchConvertBoardToTemplate: (boardId) => dispatch(convertBoardToTemplate(boardId)),
    dispatchConvertColumnToBoard: (elementId) => dispatch(convertColumnToBoard({ elementId })),
    dispatchRevertTemplateToBoard: (boardId) => dispatch(revertTemplateToBoard(boardId)),
    dispatchSetElementTypeAndUpdateElement: (args) => dispatch(setElementTypeAndUpdateElement(args)),
    dispatchConvertElementsToDocument: (args) => dispatch(convertElementsToDocument(args)),
    dispatchTriggerDownload: (args) => dispatch(triggerDownload(args)),
});

const mapMeasurementsStateToProps = (connectionsState) => ({
    measurements: getMeasurementsMap(connectionsState),
});

export const elementContextMenuConnect = compose(
    connect(mapStateToProps, mapDispatchToProps),
    measurementsConnect(mapMeasurementsStateToProps),
    withHandlers({
        cut:
            ({
                isEditable,
                selectedElements,
                measurements,
                dispatchGetCurrentlyEditingId,
                dispatchSaveToClipBoard,
                gridSize,
            }) =>
            (event) => {
                const success = document.execCommand('cut');

                // Fallback functionality from safari
                if (!success) {
                    const currentlyEditingId = dispatchGetCurrentlyEditingId();
                    if (!selectedElements.size || currentlyEditingId) return;

                    event?.preventDefault();
                    const saveId = randomString(16);

                    // On paste start offset at original location (index of -1)
                    const operation = ClipboardOperation.cut;
                    const pasteCount = -1;
                    const elements = selectedElements.toJS();
                    const elementsBoundingRect = getBoundingRectInGridUnits({
                        elements,
                        measurements,
                        gridSize,
                    });
                    dispatchSaveToClipBoard({
                        operation,
                        elements,
                        saveId,
                        pasteCount,
                        elementsBoundingRect,
                        isEditable,
                    });
                }
            },
        copy:
            ({
                isEditable,
                selectedElements,
                measurements,
                dispatchGetCurrentlyEditingId,
                dispatchSaveToClipBoard,
                gridSize,
            }) =>
            (event) => {
                const success = document.execCommand('copy');

                // Fallback functionality from safari
                if (!success) {
                    const currentlyEditingId = dispatchGetCurrentlyEditingId();
                    if (!selectedElements.size || currentlyEditingId) return;

                    event?.preventDefault();
                    const saveId = randomString(16);

                    // On paste start offset at original location (index of -1)
                    const operation = ClipboardOperation.copy;
                    const pasteCount = 0;
                    const elements = selectedElements.toJS();
                    const elementsBoundingRect = getBoundingRectInGridUnits({
                        elements,
                        measurements,
                        gridSize,
                    });
                    dispatchSaveToClipBoard({
                        operation,
                        elements,
                        saveId,
                        pasteCount,
                        elementsBoundingRect,
                        isEditable,
                    });
                }
            },
        paste:
            ({ dispatchPasteElements }) =>
            () =>
                dispatchPasteElements(),
        deleteElement:
            ({ selectedElements, currentBoardId, dispatchMoveElementsToTrash }) =>
            () => {
                const selectedElementIds = selectedElements.map(getElementId).toJS();
                dispatchMoveElementsToTrash({ currentBoardId, elementIds: selectedElementIds });
            },
        rename:
            ({ dispatchStartEditingElement, selectedElements }) =>
            () => {
                const element = selectedElements.first();
                const id = getElementId(element);
                const elementType = getElementType(element);

                const editorId = isTaskList(elementType) ? getMainEditorId({ element }) : null;

                dispatchStartEditingElement({ id, editorKey: getMainEditorKey({ element }), editorId });
            },
        copyLinkToFile:
            ({ selectedElements, dispatchTriggerDownload }) =>
            () =>
                selectedElements.forEach((el) => {
                    const fileUrl = el.getIn(['content', 'file', 'url']);
                    const elementId = getElementId(el);

                    // clipboard writes aren't supported in Safari or Edge
                    const clipboardInsertSupported = !!(navigator.permissions && navigator.clipboard);

                    if (clipboardInsertSupported) {
                        navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
                            if (result.state === 'granted' || result.state === 'prompt') {
                                navigator.clipboard.writeText(fileUrl);
                            }
                        });
                    } else {
                        dispatchTriggerDownload({ url: fileUrl, id: elementId });
                    }
                }),
        copyLinkAddress:
            ({ selectedElements }) =>
            () => {
                // clipboard writes aren't supported in Safari or Edge
                const clipboardInsertSupported =
                    !!(navigator.permissions && navigator.clipboard) &&
                    platformSingleton.browserEngine !== BrowserEngineType.firefox;

                if (!clipboardInsertSupported) return;

                const linkElement = selectedElements.first();
                const linkUrl = getLinkUrl(linkElement);

                navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
                    if (result.state === 'granted' || result.state === 'prompt') {
                        navigator.clipboard.writeText(linkUrl);
                    }
                });
            },
        groupInColumn:
            ({
                elements,
                measurements,
                selectedElements,
                gridSize,
                currentBoardId,
                dispatchGroupInColumn,
                dispatchDeselectAll,
                dispatchMoveElementsToTrash,
            }) =>
            () => {
                const filteredElements = selectedElements.filter(negate(isLine)).filter(negate(isColumn)).toJS();

                const sortedSelectedElements = filteredElements.sort(sortBy2dPosition);
                const childIdArray = sortedSelectedElements.map(getElementId);
                const elementsBoundingRect = getBoundingRectInGridUnits({
                    elements: sortedSelectedElements,
                    measurements,
                    gridSize,
                });
                const columnHeight = sortedSelectedElements.reduce(
                    (acc, el) => acc + measurements.getIn([el.id, 'height']) / gridSize,
                    COLUMN_DEFAULT_HEIGHT_IN_GRID_UNIT,
                );

                const currentBoard = getElement(elements, currentBoardId);
                const canvasOrigin = getCanvasOriginCoordinates(currentBoard);

                const x =
                    (elementsBoundingRect.right + elementsBoundingRect.left) / 2 -
                    ELEMENT_DEFAULT_WIDTH / 2 +
                    prop('x', canvasOrigin);
                const y =
                    (elementsBoundingRect.bottom + elementsBoundingRect.top) / 2 -
                    columnHeight / 2 +
                    prop('y', canvasOrigin);

                const location = {
                    ...head(sortedSelectedElements).location,
                    position: {
                        ...getLocationPosition(head(sortedSelectedElements)),
                        x: Math.max(MINIMUM_CANVAS_ORIGIN, x),
                        y: Math.max(MINIMUM_CANVAS_ORIGIN, y),
                    },
                };

                const linesIdsToBeDeleted = elements
                    .filter((el) => {
                        if (!isLine(el)) return false;

                        const startElementId = el.getIn(['content', 'start', 'elementId']);
                        const endElementId = el.getIn(['content', 'end', 'elementId']);

                        return includes(childIdArray, startElementId) || includes(childIdArray, endElementId);
                    })
                    .map(getElementId)
                    .toArray();

                const transactionId = getNewTransactionId();
                dispatchGroupInColumn({ location, childIdArray, onDrop: false, transactionId });
                dispatchMoveElementsToTrash({ currentBoardId, elementIds: linesIdsToBeDeleted, transactionId });
                dispatchDeselectAll();
            },
        connectWithLines:
            ({ selectedElements, dispatchConnectWithLines }) =>
            () =>
                dispatchConnectWithLines(selectedElements),
        convertToSketch:
            ({ selectedElements, dispatchConvertToSketch }) =>
            () => {
                const elementId = getElementId(selectedElements.first());
                dispatchConvertToSketch(elementId);
            },
        toggleLock:
            ({ selectedElements, lockToolWillEnableLock, dispatchUpdateElements }) =>
            () => {
                dispatchUpdateElements({
                    updates: selectedElements.toArray().map((element) => ({
                        id: getElementId(element),
                        changes: {
                            locked: lockToolWillEnableLock,
                        },
                    })),
                });
            },
        // TODO See if some of this logic can be shared with the other shortcut logic for z-index
        bringToFront:
            ({ selectedElements, currentBoardId, elements, dispatchMoveMultipleElements }) =>
            () => {
                const currentBoardElements = getChildren(elements, currentBoardId).valueSeq().toJS();
                const sortedSelectedElements = selectedElements.sort(sortByScoreProperty).toJS();
                const selectedElementIds = sortedSelectedElements.map(getElementId);
                const filteredCurrentBoardElements = currentBoardElements.filter(
                    (el) => selectedElementIds.indexOf(getElementId(el)) === -1,
                );
                const highestScore = getHighestScore(filteredCurrentBoardElements);

                const moves = sortedSelectedElements.map((el, index) => ({
                    id: el.id,
                    location: {
                        ...el.location,
                        position: {
                            ...el.location.position,
                            score: highestScore + (index + 1) * DEFAULT_INCREMENT,
                        },
                    },
                }));

                dispatchMoveMultipleElements({ moves, moveOperation: ELEMENT_MOVE_OPERATIONS.CHANGE_ORDER });
            },
        sendToBack:
            ({ selectedElements, currentBoardId, elements, dispatchMoveMultipleElements }) =>
            () => {
                const currentBoardElements = getChildren(elements, currentBoardId).valueSeq().toJS();
                const sortedSelectedElements = selectedElements.sort(sortByScoreProperty).toJS();
                const selectedElementIds = sortedSelectedElements.map(getElementId);
                const filteredCurrentBoardElements = currentBoardElements.filter(
                    (el) => selectedElementIds.indexOf(getElementId(el)) === -1,
                );
                const lowestScore = getLowestScore(filteredCurrentBoardElements);

                const moves = sortedSelectedElements.map((el, index) => ({
                    id: el.id,
                    location: {
                        ...el.location,
                        position: {
                            ...el.location.position,
                            score: lowestScore - (sortedSelectedElements.length - index) * DEFAULT_INCREMENT,
                        },
                    },
                }));

                dispatchMoveMultipleElements({ moves, moveOperation: ELEMENT_MOVE_OPERATIONS.CHANGE_ORDER });
            },
        convertBoardToTemplate:
            ({ selectedElements, dispatchConvertBoardToTemplate }) =>
            () => {
                const selectedBoardId = getElementId(selectedElements.filter(isBoard).first());
                if (!selectedBoardId) return;

                dispatchConvertBoardToTemplate(selectedBoardId);
            },
        revertTemplateToBoard:
            ({ selectedElements, dispatchRevertTemplateToBoard }) =>
            () => {
                const selectedBoardId = getElementId(selectedElements.filter(isBoard).first());
                if (!selectedBoardId) return;

                dispatchRevertTemplateToBoard(selectedBoardId);
            },
        convertColumnToBoard:
            ({ selectedElements, dispatchConvertColumnToBoard }) =>
            () => {
                selectedElements.filter(isColumn).forEach((element) => {
                    dispatchConvertColumnToBoard(getElementId(element));
                });
            },
        logSelectedElement: () => (el) => () => {
            console.log(el); // eslint-disable-line no-console
        },
        createShortcut:
            ({ selectedElements, dispatchCreateShortcut }) =>
            () => {
                const element = selectedElements.first();
                const elementId = getElementId(element);
                return dispatchCreateShortcut({ elementId });
            },
        convertToNote:
            ({ selectedElements, dispatchSetElementTypeAndUpdateElement }) =>
            () => {
                selectedElements.forEach((element) => {
                    if (!isDocument(element)) return null;

                    const transactionId = getNewTransactionId();
                    const elementId = getElementId(element);

                    dispatchSetElementTypeAndUpdateElement({
                        id: elementId,
                        elementType: ElementType.CARD_TYPE,
                        transactionId,
                    });
                });
            },
        convertToDocument:
            ({ selectedElements, dispatchConvertElementsToDocument }) =>
            () => {
                const elementIds = asObject(selectedElements.map(getElementId));
                dispatchConvertElementsToDocument(elementIds);
            },
        downloadSvg:
            ({ selectedElements }) =>
            () => {
                const element = selectedElements.first();
                downloadAsSvg({ element });
            },
    }),
);

const ElementContextMenuItemsContainer = (props) => <ElementContextMenuItems {...props} />;

export default elementContextMenuConnect(ElementContextMenuItemsContainer);
