// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import TitleDraftJsEditorWrapper from '../../titleDraftJsEditor/TitleDraftJsEditorWrapper';

// Utils
import { getElementId } from '../../../../common/elements/utils/elementPropertyUtils';

// Plugins
import createDeleteOnEmptyPlugin from '../../../components/editor/plugins/deleteOnEmpty';
import createSearchHighlightPlugin from '../../../components/editor/plugins/searchHighlight';
import createTaskListTitleShortcutsPlugin from '../../../components/editor/plugins/editableTitleShortcuts/taskListShortcuts';

import './TaskListTitleDraftJsEditor.scss';

const deleteOnEmptyPlugin = createDeleteOnEmptyPlugin();
const searchHighlightPlugin = createSearchHighlightPlugin();
const taskListTitleShortcutsPlugin = createTaskListTitleShortcutsPlugin();

const plugins = [searchHighlightPlugin, taskListTitleShortcutsPlugin, deleteOnEmptyPlugin];

const TaskListTitleDraftJsEditor = (props) => {
    const goToNextTask = () => {
        const { goToNextTaskHandler, element } = props;
        goToNextTaskHandler({ elementId: getElementId(element) });
    };

    const hideTitleAndGoToNextTask = () => {
        const { dispatchHideTitle, element } = props;
        dispatchHideTitle({ id: getElementId(element) });
        goToNextTask();
    };
    return (
        <TitleDraftJsEditorWrapper
            {...props}
            className="TaskListTitleDraftJsEditor"
            onEmptyBackspace={hideTitleAndGoToNextTask}
            goToNextTask={goToNextTask}
            placeholder={'New To-do List'}
            plugins={plugins}
        />
    );
};

TaskListTitleDraftJsEditor.propTypes = {
    dispatchHideTitle: PropTypes.func,
    goToNextTaskHandler: PropTypes.func,
    element: PropTypes.object,
};

export default TaskListTitleDraftJsEditor;
