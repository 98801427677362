import { PauseSet } from './PauseSet';
import { Fragment } from '@tiptap/pm/model';
import { EditorState } from '@tiptap/pm/state';
import { Editor } from '@tiptap/react';
import { ExternalStoreSynchroniserOptions } from './externalStoreSynchroniserTypes';

/**
 * An implementation of the storage for the ExternalStoreSynchroniser.
 * This is a class to simplify the management of the state of the extension.
 *
 * NOTE: This class heavily uses `this` internally. As such it must be referenced like `storage.methodName()`
 *  rather than `const { methodName } = storage`.
 */
export class ExternalStoreSynchroniserStorage {
    // The options that the extension was created with
    private options: ExternalStoreSynchroniserOptions;

    // A set of consumers that wish for updates to be paused
    private pausers = new PauseSet();
    private timeoutId = 0;
    private unsyncedUpdateCount = 0;

    // The last content that was stored in the external store
    private lastStoredContent: Fragment | null = null;

    constructor(options: ExternalStoreSynchroniserOptions) {
        this.options = options;
    }

    /**
     * If the content has changed externally to this class, allow the updated
     * content to be referenced here.
     */
    public setLastStoredContent(value: Fragment | null) {
        this.lastStoredContent = value;
    }

    /**
     * Prevents flushing of updates to the external store.
     */
    public pauseUpdates(key: string) {
        this.pausers.pauseUpdates(key);
    }

    /**
     * Resumes flushing of updates to the external store.
     */
    public resumeUpdates(key: string) {
        this.pausers.resumeUpdates(key);
    }

    /**
     * Gets the current state of the editor and sends it to the external store, if:
     * - There are unsynced updates
     * - The syncing is not paused
     * - The content has changed since the last sync
     */
    public flushUpdate({
        transactionId,
        state,
        editor,
    }: {
        transactionId?: number;
        state?: EditorState;
        editor: Editor;
    }) {
        if (!this.lastStoredContent) return;

        if (this.unsyncedUpdateCount === 0) return;

        if (this.pausers.isPaused()) return;

        const updatedDoc = state?.doc ?? editor.state.doc;

        // No new changes, so ignore update
        if (updatedDoc.content.eq(this.lastStoredContent)) return;

        this.unsyncedUpdateCount = 0;
        this.lastStoredContent = updatedDoc.content;

        this.options.onDebouncedUpdate(updatedDoc.toJSON(), transactionId);
    }

    /**
     * Waits until either the debounce time has passed or the debounce count has been reached before
     * triggering an update.
     */
    public debounceUpdate({ editor }: { editor: Editor }) {
        if (!this.lastStoredContent) return;

        // Don't queue any updates if they're paused
        if (this.pausers.isPaused()) return;

        if (this.timeoutId) clearTimeout(this.timeoutId);

        // If the number of lines change, flush immediately
        const hasLineCountChanged = this.lastStoredContent.childCount !== editor.state.doc.content.childCount;

        this.unsyncedUpdateCount++;

        if (hasLineCountChanged) return this.flushUpdate({ editor });

        if (this.unsyncedUpdateCount >= this.options.debounceCount) return this.flushUpdate({ editor });

        this.timeoutId = setTimeout(() => this.flushUpdate({ editor }), this.options.debounceTime) as unknown as number;
    }

    /**
     * If there's a pending update - cancel it and reset the unsynced update count.
     * Another update will need to be triggered in order for the content to be synced.
     */
    public cancelPendingUpdate() {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.unsyncedUpdateCount = 0;
        return true;
    }

    /**
     * Resets this class back to its initial state.
     */
    public cleanupExtensionStorage({ editor }: { editor: Editor }) {
        if (this.timeoutId) clearTimeout(this.timeoutId);

        this.flushUpdate({ editor });

        this.lastStoredContent = null;
        this.pausers.reset();
        this.unsyncedUpdateCount = 0;
    }

    public beforeUnloadListener: (() => void) | undefined;
}
