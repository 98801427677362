import React, { ReactElement, useState } from 'react';
import useColorToolState from '../hooks/useColorToolState';
import ColorPopupContent from '../ColorPopupContent';
import CustomColorInput from '../../CustomColorInput';
import MobileToolbarSheet from '../../../../mobileToolbarSheet/MobileToolbarSheet';
import { Color } from '../../../../../../../../common/colors/colorConstants';

import './MobileColorToolContent.scss';
import { ColorToolSelectorReturnType } from '../../colorToolSelector';
import { ImMNElement } from '../../../../../../../../common/elements/elementModelTypes';
import { UseSheetReturnType } from '../../../../../../../mobile/structural/sheet/hooks/useSheet';

const MOBILE_MAX_SUGGESTED_COLORS = 7;

type MobileColorToolContentProps = ColorToolSelectorReturnType & {
    customDefaultColors?: Color[];
    customColorHandler?: (color: string | null, secondaryColor?: string | null | undefined) => void;
    customTempColorHandler?: (color: string | null, secondaryColor?: string | null | undefined) => void;
    selectedElements: Immutable.List<ImMNElement>;
    dispatchOpenSheet: () => void;
    dispatchCloseSheet: () => void;
    sheetProps: UseSheetReturnType;
};

const MobileColorToolContent = (props: MobileColorToolContentProps): ReactElement => {
    const { customDefaultColors, dispatchOpenSheet, dispatchCloseSheet, sheetProps } = props;
    const { isSheetOpen } = sheetProps;

    const {
        popupColor,
        popupColorHex,
        supportColorBar,
        openCustomColorPicker,
        actualPopupMode,
        setColorPopupMode,
        supportDualColors,
        defaultColors,
        customColorInputRef,
        setColor,
        setTempColor,
        suggestedColors,
    } = useColorToolState({ ...props, isPopupOpen: isSheetOpen });

    const [isCustomPickerOpen, setIsCustomPickerOpen] = useState(false);

    const onCustomInputBlur = () => {
        dispatchOpenSheet();
        setIsCustomPickerOpen(false);
    };

    const onCustomInputFocus = () => {
        dispatchCloseSheet();
        setIsCustomPickerOpen(true);
    };

    const showCustomColorPickerInput = isSheetOpen || isCustomPickerOpen;

    return (
        <>
            <MobileToolbarSheet {...sheetProps} className="color-tool">
                <ColorPopupContent
                    {...props}
                    defaultColors={customDefaultColors || defaultColors}
                    supportColorBar={supportColorBar}
                    setColor={setColor}
                    selectedColor={popupColor}
                    openCustomColorPicker={openCustomColorPicker}
                    colorPopupMode={actualPopupMode}
                    setColorPopupMode={setColorPopupMode}
                    supportDualColors={supportDualColors}
                    suggestedColors={suggestedColors}
                    maxSuggestedColors={MOBILE_MAX_SUGGESTED_COLORS}
                />
            </MobileToolbarSheet>
            {showCustomColorPickerInput && (
                <CustomColorInput
                    className="custom-color-picker-input"
                    ref={customColorInputRef}
                    initialColor={popupColorHex}
                    setTempColor={setTempColor}
                    setColor={setColor}
                    onBlur={onCustomInputBlur}
                    onFocus={onCustomInputFocus}
                />
            )}
        </>
    );
};

export default MobileColorToolContent;
