// Lib
import { isString } from 'lodash';

// Utils
import { getEditorType } from '../../editorTypeUtils';
import { asObject } from '../../../../utils/immutableHelper';
import mergeRawState from '../../../../utils/editor/rawUtils/mergeRawState';
import convertSimpleStringToTiptapContent from '../../createJsonContentUtils/convertSimpleStringToTiptapContent';
import { convertDraftContentToTiptap } from '../../../conversion/editorConversion/toTiptap/convertDraftContentToTiptap';

// Types
import { TiptapContent, TiptapNodeType } from '../../../tiptapTypes';
import { ElementEditor } from '../../../../elements/elementEditorConstants';
import { EditorContent, ImEditorContent } from '../../../../elements/elementModelTypes';
import { RawDraftContentState } from 'draft-js';

type EditorJson = ImEditorContent | EditorContent | string | undefined;

const convertToTiptap = (editorJson: EditorJson): TiptapContent => {
    if (!editorJson) return asObject(convertSimpleStringToTiptapContent(''));

    if (isString(editorJson)) return convertSimpleStringToTiptapContent(editorJson);

    const editorType = getEditorType(editorJson);
    if (editorType !== ElementEditor.DraftJs) return asObject(editorJson);

    const editorJsonPojo = asObject(editorJson) as RawDraftContentState;
    return convertDraftContentToTiptap(editorJsonPojo) as TiptapContent;
};

const mergeTiptapDocs = (initialTiptapDoc: TiptapContent, additionalTiptapDoc: TiptapContent): TiptapContent => {
    const initialTiptapContent = initialTiptapDoc.content;
    const additionalTiptapContent = additionalTiptapDoc.content;

    const initialLastBlock = initialTiptapContent[initialTiptapContent.length - 1];
    const initialEndsWithText =
        initialLastBlock.content?.[initialLastBlock.content?.length - 1]?.type === TiptapNodeType.text;

    const additionalFirstBlock = additionalTiptapContent[0];
    const additionalStartsWithText = additionalFirstBlock.content?.[0]?.type === TiptapNodeType.text;

    // If there's no reason to merge the end & start text nodes, just concatenate the content.
    if (!initialEndsWithText || !additionalStartsWithText) {
        return {
            ...initialTiptapDoc,
            content: [...initialTiptapContent, ...additionalTiptapContent],
        };
    }

    // Otherwise, merge the last block of the initial content with the first block of the additional content.
    // Then concatenate the rest of the content.
    const mergedContent = [
        ...initialTiptapContent.slice(0, -1),
        {
            ...initialLastBlock,
            content: [...initialLastBlock.content!, ...additionalFirstBlock.content!],
        },
        ...additionalTiptapContent.slice(1),
    ];

    return {
        ...initialTiptapDoc,
        content: mergedContent,
    };
};

/**
 * If the two editor JSONs are Draft.js content - then use the Draft.js merge algorithm.
 * Otherwise, convert both to Tiptap content and merge them.
 */
const mergeEditorJson = (initialEditorJson: EditorJson, additionalEditorJson: EditorJson): EditorJson => {
    const initialEditorType = getEditorType(initialEditorJson);
    const additionalEditorType = getEditorType(additionalEditorJson);

    if (!additionalEditorJson) return initialEditorJson;
    if (!initialEditorJson) return additionalEditorJson;

    if (initialEditorType === ElementEditor.DraftJs && additionalEditorType === ElementEditor.DraftJs) {
        return mergeRawState(initialEditorJson, additionalEditorJson);
    }

    const initialTiptapDoc = convertToTiptap(initialEditorJson);
    const additionalTiptapDoc = convertToTiptap(additionalEditorJson);

    return mergeTiptapDocs(initialTiptapDoc, additionalTiptapDoc);
};

export default mergeEditorJson;
