import DefaultBlockquote, { inputRegex } from '@tiptap/extension-blockquote';
import { wrappingInputRule } from '@tiptap/core';

export const Blockquote = DefaultBlockquote.extend({
    content: 'paragraph+',

    addCommands() {
        return {
            ...this.parent?.(),
            /**
             * We want to ensure that any list nodes are removed before setting the blockquote.
             */
            toggleBlockquote:
                () =>
                ({ chain }) =>
                    chain()
                        // First remove any nodes from lists and clear their current block types
                        .clearBlockNodes()
                        .toggleWrap(this.name)
                        .run(),
        };
    },

    addKeyboardShortcuts() {
        return {
            /* eslint-disable @typescript-eslint/naming-convention */
            // Keeping the original Tiptap shortcut, in case it's standard
            'Mod-Shift-b': () => this.editor.commands.toggleBlockquote(),
            "Mod-'": () => this.editor.commands.toggleBlockquote(),
            'Mod-"': () => this.editor.commands.toggleBlockquote(),
        };
    },

    /**
     * Overrides the default behaviour to ensure that new blockquotes created underneath
     * each other aren't merged.
     */
    addInputRules() {
        return [
            wrappingInputRule({
                find: inputRegex,
                type: this.type,
                // Never join to a previous blockquote
                joinPredicate: () => false,
            }),
        ];
    },
});
