import { RefObject } from 'react';
import { getDefaultSnapPointDetails } from './snapPointUtils';
import { MAX_SHEET_HEIGHT, MAX_SUFFIX } from '../hooks/useSheetContextState';

const setInitialSheetHeight = (
    sheetRef: RefObject<HTMLDivElement>,
    defaultSnapPoint: number | string | undefined,
    addSnapPoint: (snapPoint: number) => void,
    goToSnapPoint: (snapPoint: number) => void,
    setSheetInitialised: (sheetInitialised: boolean) => void,
    closeAnimationInProgress: React.MutableRefObject<boolean>,
    safeWindowHeight: number,
) => {
    if (!sheetRef.current || closeAnimationInProgress.current) return;

    const { point, suffix } = getDefaultSnapPointDetails(defaultSnapPoint);
    const defaultSnapIsMax = suffix && point && suffix === MAX_SUFFIX;

    // --- Set the specified default snap point ---

    if (point && !defaultSnapIsMax) {
        sheetRef.current.style.height = `0px`;

        requestAnimationFrame(() => {
            if (!sheetRef.current || closeAnimationInProgress.current) return;

            addSnapPoint(point);
            goToSnapPoint(point);
            setSheetInitialised(true);
        });
        return;
    }

    // --- Set the initial snap point based on the content height ---

    sheetRef.current.style.height = 'auto';
    sheetRef.current.style.opacity = '0';

    requestAnimationFrame(() => {
        if (!sheetRef.current || closeAnimationInProgress.current) return;

        const contentHeightAsDecimal = sheetRef.current.clientHeight / safeWindowHeight;
        const maxInitialSnapPoint = defaultSnapIsMax ? point : MAX_SHEET_HEIGHT;
        const initialSnapPoint = Math.min(maxInitialSnapPoint, contentHeightAsDecimal);
        addSnapPoint(initialSnapPoint);

        sheetRef.current.style.height = `0px`;
        sheetRef.current.style.opacity = '1';

        requestAnimationFrame(() => {
            if (!sheetRef.current || closeAnimationInProgress.current) return;

            goToSnapPoint(initialSnapPoint);
            setSheetInitialised(true);
        });
    });
};

export default setInitialSheetHeight;
