// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { onlyUpdateForPropTypes } from '../../../node_module_clones/recompose';
import classNames from 'classnames';

// Utils
import { getTranslationStyles } from '../../utils/cssUtil';
import { getFirstName } from '../userUtil';
import { isDocument, isSketch } from '../../../common/elements/utils/elementTypeUtils';
import { markEventAsHandled } from '../../utils/react/reactEventUtil';

// Components
import elementUserTagConnect, { TAG_TYPE_ACTIVE, TAG_TYPE_FILTER } from './elementUserTagConnect';
import ElementTag from '../../components/elements/ElementTag';
import UserAvatar from '../../components/userAvatar/UserAvatar';
import Icon from '../../components/icons/Icon';

// Constants
import { USER_AVATAR_SIZES } from '../../../common/users/userConstants';

// Styles
import './ElementUserTag.scss';

const getTagStyle = ({ top, left }) => {
    if (!top || !left) return null;

    return getTranslationStyles({ x: left, y: top });
};

@elementUserTagConnect
class ElementUserTag extends React.Component {
    componentWillReceiveProps(nextProps) {
        const { user, userId, loadUser, isUserFetched } = nextProps;

        if (user && !isUserFetched) {
            loadUser(userId);
        }
    }

    render() {
        const { user, userId, elementId, element, type, top, left, forceLeave, forceRemoteDeselectElement } =
            this.props;

        const userHasImage = user && !!user.get('image');

        const isActive = type === TAG_TYPE_ACTIVE;
        const hasFilter = type === TAG_TYPE_FILTER;

        const classes = classNames('ElementUserTag', {
            active: isActive,
            pulse: isActive,
            filter: hasFilter,
            'animate-name': userHasImage && isActive,
        });

        // Don't allow force deselect for elements whose editing state is inside a modal, e.g. Sketch and Documents.
        const allowForceDeselectElement = !isSketch(element) && !isDocument(element);

        const forceDeselectElement = (event) => {
            markEventAsHandled(event);
            forceRemoteDeselectElement(userId, elementId);
        };

        const component = user ? (
            <div className="tag-contents">
                {userHasImage && (
                    <UserAvatar
                        user={user}
                        size={USER_AVATAR_SIZES.SMALL}
                        pulse={isActive}
                        active={isActive}
                        filter={hasFilter}
                    />
                )}
                <div className="name-container">
                    <div className="name">{getFirstName(user)}</div>
                </div>
                {allowForceDeselectElement && (
                    <div className="force-deselect-element" onClick={forceDeselectElement}>
                        <Icon name="close-x-small" />
                    </div>
                )}
            </div>
        ) : null;

        return (
            <ElementTag className={classes} style={getTagStyle({ top, left })} forceLeave={forceLeave}>
                {component}
            </ElementTag>
        );
    }
}

ElementUserTag.propTypes = {
    remoteSelectionData: PropTypes.object,
    lastModifiedUser: PropTypes.object,
    isRemotelySelected: PropTypes.bool,
    filterQuery: PropTypes.string,
    user: PropTypes.object,
    userId: PropTypes.string,
    element: PropTypes.object,
    elementId: PropTypes.string,
    type: PropTypes.string,
    isUserFetched: PropTypes.bool,
    top: PropTypes.number,
    left: PropTypes.number,
    forceLeave: PropTypes.bool,
    loadUser: PropTypes.func,
    forceRemoteDeselectElement: PropTypes.func,
};

export default onlyUpdateForPropTypes(ElementUserTag);
