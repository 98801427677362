// Lib
import { generateJSON } from '@tiptap/core';

// Constants
import { TiptapContent } from '../../tiptapTypes';
import { CAPTION_STATIC_TIPTAP_EXTENSIONS } from '../../extensions/extensionLists';

const createCaptionJsonContent = (html: string, text: string): TiptapContent | null => {
    if (!text && !html) return null;

    if (html) return generateJSON(html, CAPTION_STATIC_TIPTAP_EXTENSIONS) as TiptapContent;

    return generateJSON(text, CAPTION_STATIC_TIPTAP_EXTENSIONS) as TiptapContent;
};

export default createCaptionJsonContent;
