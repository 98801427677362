import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './AdminDashboardHoverReveal.scss';

const HoverReveal = ({ children, revealChildren, className }) => {
    const [reveal, setReveal] = useState(false);
    const [fixedReveal, setFixedReveal] = useState(false);

    return (
        <div
            className={classNames('AdminDashboardHoverReveal', className, { fixed: fixedReveal })}
            onMouseEnter={() => setReveal(true)}
            onMouseLeave={() => setReveal(false)}
        >
            <span className="trigger" onClick={() => setFixedReveal(!fixedReveal)}>
                {children}
            </span>
            {(reveal || fixedReveal) && <div className="hover-popup">{revealChildren}</div>}
        </div>
    );
};

HoverReveal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string, PropTypes.number]),
    revealChildren: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
};

export default HoverReveal;
