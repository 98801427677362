import { EditorContent } from '@tiptap/react';
import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';

import { ImMNElement } from '../../../../common/elements/elementModelTypes';
import { getIgnoreAutoType, getTextContent } from '../../../../common/elements/utils/elementPropertyUtils';
import { DraftJsConversionIndicator } from '../../../../common/tiptap/conversion/elementConversion/previewComponents/DraftJsConversionIndicator';
import { useSuggestionsManager } from '../../../../common/tiptap/extensions/mention/suggestionsManager';
import { SearchHighlightObserver } from '../../../../common/tiptap/extensions/SearchHighlighter';
import { TiptapContent } from '../../../../common/tiptap/tiptapTypes';
import usePoiUpdateHandler from '../../../components/pointsOfInterest/usePoiUpdateHandler';
import useClientElementTiptapEditor from '../../../components/tiptapEditor/useClientElementTiptapEditor';
import { DispatchCreateNewElementFunction } from '../../actions/elementCreateActionTypes';
import { getMainEditorId, getMainEditorKey } from '../../utils/elementEditorUtils';
import useCardTiptapEditorExtensions from './useCardTiptapEditorExtensions';
import useTiptapInnerRef from '../../../../common/tiptap/hooks/useTiptapInnerRef';

import './CardTiptapEditor.scss';

interface CardTiptapEditorProps {
    element: ImMNElement;

    currentEditorId: string;

    isEditable: boolean;
    isEditing: boolean;
    isSingleSelected: boolean;
    isFocusedForegroundElement: boolean;

    textContent: TiptapContent | null;
    saveContent: (textContent: TiptapContent, transactionId?: number) => void;
    startEditing: (args: { editorId: string; editorKey: string }) => void;
    editorRef: (elem: HTMLDivElement | null) => void;

    moveElementsToTrash: () => void;
    createNewCard?: DispatchCreateNewElementFunction;
}

const CardTiptapEditor = (props: CardTiptapEditorProps): ReactElement => {
    const {
        element,
        editorRef,
        textContent,
        currentEditorId,
        saveContent,
        isEditable,
        isEditing,
        isSingleSelected,
        startEditing,
        moveElementsToTrash,
        createNewCard,
    } = props;

    const dispatch = useDispatch();
    const editorId = getMainEditorId(props);
    const editorKey = getMainEditorKey(props);

    const { innerRef, contentInnerRef } = useTiptapInnerRef(editorRef);

    const onHighlightsChanged = usePoiUpdateHandler({ contentInnerRef, element });

    const [suggestionsManager, mentionsPopup] = useSuggestionsManager();
    const extensions = useCardTiptapEditorExtensions({
        moveElementsToTrash,
        saveContent,
        suggestionsManager,
        createNewCard,
        isEditable,
        dispatch,
        onHighlightsChanged,
        universalCardConversion: !getIgnoreAutoType(element),
    });

    const { editor, onMouseDown, onClick } = useClientElementTiptapEditor({
        persistedContent: textContent,
        extensions,

        editorId,
        editorKey,
        currentEditorId,

        isEditable,
        isEditing,
        isSingleSelected,

        startEditing,
        saveContent,
    });

    // NOTE: onMouseDown & onClick needed to be added to the containing div for them to work with
    //  the React version of addNodeViews in Tiptap extensions, otherwise click events etc that
    //  occur within the node views wouldn't trigger those methods
    return (
        <div className="TiptapEditorContainer" onMouseDown={onMouseDown} onClick={onClick}>
            {mentionsPopup}
            <SearchHighlightObserver editor={editor} />
            <DraftJsConversionIndicator element={element} textContent={getTextContent(element)} />
            <EditorContent innerRef={innerRef} className="CardTiptapEditor" editor={editor} />
        </div>
    );
};

export default CardTiptapEditor;
