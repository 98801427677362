// Lib
import { EditorState } from '@tiptap/pm/state';
import { Slice, Schema } from '@tiptap/pm/model';
import { TiptapContent } from '../../tiptapTypes';

/**
 * Creates a new document JSON from a slice.
 */
export const createJsonContentFromSlice = (slice?: Slice, schema?: Schema): TiptapContent | null => {
    if (!slice || !schema) return null;

    const state = EditorState.create({ schema });

    // NOTE: replaceRange will attempt to maintain the structure within the slice, whereas
    //  replace will strip back the structure.
    //  E.g. When pasting an orderedList, replaceRange will maintain the orderedList structure,
    //   while replace will only get the paragraph within
    const tr = state.tr.replaceRange(0, 2, slice);
    const updatedState = state.apply(tr);
    return updatedState.doc.toJSON() as TiptapContent;
};

export default createJsonContentFromSlice;
