// Lib
import { Command } from '@tiptap/react';
import { removeFromList } from '../list/tiptapListOperations';
import { clearNodesInRange } from '../../utils/tiptapOperations';

/**
 * Similar to Tiptap's `clearNodes` command, however this correctly handles nested lists.
 * `clearNodes` will cause nested list ancestors to lose their depth, whereas this will
 * only impact the nodes within the selection.
 */
export const clearBlockNodes =
    (): Command =>
    ({ chain }) =>
        chain()
            //  First remove any nodes from lists
            .command(({ state: updatedState, dispatch }) => removeFromList(updatedState, dispatch))
            // Then clear all the nodes within the selection
            .command(({ state: updatedState, dispatch }) =>
                // NOTE: We use the 'clearNodesInRange' function, because this will ensure that the mapping
                //  only occurs for operations that are performed within this function,
                //  rather than the entire transaction - which could cause issues after removing nested lists
                clearNodesInRange(
                    updatedState,
                    dispatch,
                    updatedState.selection.$from.blockRange(updatedState.selection.$to),
                ),
            )
            .run();
