import { useEffect } from 'react';
import { Extension, Editor } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import createSimpleClassDecorationState from './createSimpleClassDecorationState';

const NAME = 'virtualSelection';

function clearVirtualSelection(view: EditorView) {
    view.dispatch(view.state.tr.setMeta(NAME, ''));
}

export const usePreservedSelection = (editor: Editor, isSingleSelected: boolean) => {
    useEffect(() => {
        // When the card loses focus, we want to preserve the selection, but when
        // the card _itself_ gets deselected (not just unfocused), we want to clear it.
        if (!editor || isSingleSelected) return;
        clearVirtualSelection(editor.view);
    }, [editor, isSingleSelected]);
};

const createPlugin = () =>
    new Plugin({
        key: new PluginKey(NAME),
        state: createSimpleClassDecorationState(NAME),

        props: {
            decorations(state) {
                return this.getState(state);
            },
            handleDOMEvents: {
                // setMeta isn't really modifying any document metadata here, we're just using it
                // to trigger a custom transaction that we respond to in `apply` above.

                blur: (view) => {
                    const { tr } = view.state;

                    // blur occurred with no selection; do nothing
                    if (tr.selection.from === tr.selection.to) return;

                    view.dispatch(tr.setMeta(NAME, 'virtual-selection'));
                },

                focus: clearVirtualSelection,
            },
        },
    });

export const VirtualSelection = Extension.create({
    name: NAME,

    addProseMirrorPlugins() {
        return [createPlugin()];
    },
});
