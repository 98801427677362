// Lib
import React, { ReactElement, useCallback } from 'react';
import classNames from 'classnames';

// Utils
import { getMainEditorId, getMainEditorKey } from '../utils/elementEditorUtils';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import getEditorJsonText from '../../../common/tiptap/utils/jsonContentUtils/getEditorJsonText';

// Hooks
import useTiptapInnerRef from '../../../common/tiptap/hooks/useTiptapInnerRef';
import usePoiUpdateHandler from '../../components/pointsOfInterest/usePoiUpdateHandler';
import useTitleTiptapEditorExtensions, { UseTitleTiptapEditorExtensionsArgs } from './useTitleTiptapEditorExtensions';
import useClientElementTiptapEditor from '../../components/tiptapEditor/useClientElementTiptapEditor';

// Components
import { SearchHighlightObserver } from '../../../common/tiptap/extensions/SearchHighlighter';

// Types
import { EditorContent } from '@tiptap/react';
import { Point } from '../../../common/maths/geometry/pointTypes';
import { TiptapContent } from '../../../common/tiptap/tiptapTypes';
import { ImMNElement } from '../../../common/elements/elementModelTypes';

// Styles
import './TitleTiptapEditor.scss';

export type TitleTiptapEditorProps = UseTitleTiptapEditorExtensionsArgs & {
    className?: string;

    element: ImMNElement;
    title: TiptapContent;

    isFocusedForegroundElement: boolean;
    isEditable: boolean;
    isEditing: boolean;
    isSingleSelected: boolean;
    spellCheck: boolean;

    currentEditorId: string;

    startEditing: ({
        editorId,
        editorKey,
        editorFocusClientCoords,
    }: {
        editorId: string;
        editorKey: string;
        editorFocusClientCoords: Point;
    }) => void;
    dispatchUpdateTitle: (args: { id: string; title: string }) => void;
};

const TitleTiptapEditor = (props: TitleTiptapEditorProps): ReactElement => {
    const {
        className,
        element,
        title,
        placeholder,
        isEditable,
        isEditing,
        isSingleSelected,
        spellCheck,
        currentEditorId,
        keyboardShortcuts,
        onEmptyDelete,
        startEditing,
        dispatchUpdateTitle,
    } = props;

    const { innerRef, contentInnerRef } = useTiptapInnerRef();
    const onHighlightsChanged = usePoiUpdateHandler({ contentInnerRef, element });

    const elementId = getElementId(element);
    const editorId = getMainEditorId(props);
    const editorKey = getMainEditorKey(props);

    const saveContent = useCallback(
        (textContent: TiptapContent) => {
            const title = getEditorJsonText(textContent);
            dispatchUpdateTitle({ id: elementId, title });
        },
        [dispatchUpdateTitle, elementId],
    );

    const extensions = useTitleTiptapEditorExtensions({
        placeholder,
        onEmptyDelete,
        onHighlightsChanged,
        keyboardShortcuts,
        saveContent,
    });

    const { editor, onMouseDown, onClick } = useClientElementTiptapEditor({
        persistedContent: title,
        extensions,

        editorId,
        editorKey,
        currentEditorId,

        isEditable,
        isEditing,
        isSingleSelected,

        startEditing,
        saveContent,
    });

    return (
        <div className={classNames('TitleEditor', className)} onMouseDown={onMouseDown} onClick={onClick}>
            <SearchHighlightObserver editor={editor} />
            <EditorContent innerRef={innerRef} className="TitleTiptapEditor" editor={editor} spellCheck={spellCheck} />
        </div>
    );
};

export default TitleTiptapEditor;
