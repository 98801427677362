// Lib
import React, { useEffect, useState } from 'react';

// Utils
import http from '../../utils/services/http';

// Constants
import { METHODS } from '../../../common/utils/http/httpConstants';

// Components
import LinkButton from '../../components/buttons/LinkButton';
import AdminDashboardTimestamp from '../components/AdminDashboardTimestamp';
import AppVersionForm from './AdminDashboardAppVersionsForm';

// Styles
import './AdminDashboardAppVersions.scss';

const ENDPOINT_URL = 'admin/app-version-rules/rules';

const AdminDashboardAppVersions = () => {
    const [showAddRuleForm, setShowAddRuleForm] = useState(false);
    const [rules, setRules] = useState([]);

    const fetchRules = async () => {
        const { data } = await http({
            method: METHODS.GET,
            url: ENDPOINT_URL,
        });

        setRules(data.rules);
    };

    const removeRule = async (ruleId) => {
        await http({
            method: METHODS.DELETE,
            url: ENDPOINT_URL,
            params: {
                id: ruleId,
            },
        });

        await fetchRules();
    };

    useEffect(() => {
        fetchRules();
    }, []);

    return (
        <div className="AdminDashboardAppVersions">
            <p className="info">
                This is a list of rules for blacklisting app versions.
                <br />
                The rules dictate which versions are BLOCKED. e.g. the rule &quot;ios &lt;= 3.0.0&quot; means versions
                older than 3.0.0 are blocked.
                <br />
                <br />
                The actual blocking happens client side, the client hits an endpoint that tells it if its version is
                blocked.
            </p>
            <div>
                {showAddRuleForm ? (
                    <AppVersionForm
                        url={ENDPOINT_URL}
                        onComplete={() => {
                            fetchRules();
                            setShowAddRuleForm(false);
                        }}
                    />
                ) : (
                    <div className="AdminDashboardForm">
                        <LinkButton className="secondary" onClick={() => setShowAddRuleForm(true)}>
                            Add new app version rule
                        </LinkButton>
                    </div>
                )}
            </div>

            <div className="table">
                <div className="header">
                    <div>Platform</div>
                    <div>Modifier</div>
                    <div>Version</div>
                    <div>Reason</div>
                    <div>Added By</div>
                    <div>Added At</div>
                    <div className="action">Action</div>
                </div>

                {rules.map(({ id, platform, modifier, version, reason, addedBy, addedAt }) => (
                    <div key={`${platform}${modifier}${version}`} className="row">
                        <div>{platform}</div>
                        <div>{modifier}</div>
                        <div>{version}</div>
                        <div>{reason}</div>
                        <div>{addedBy}</div>
                        <div>
                            <AdminDashboardTimestamp timestamp={addedAt} />
                        </div>
                        <div className="action">
                            <LinkButton className="secondary" onClick={() => removeRule(id)}>
                                Remove rule
                            </LinkButton>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdminDashboardAppVersions;
