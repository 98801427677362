import React from 'react';
import Button from '../../../../components/buttons/Button';
import { useSheetContext } from '../SheetContextProvider';

import './SheetMenu.scss';

export type SheetMenuItem = {
    label: string;
    icon?: React.ReactNode;
    onClick: (event: React.MouseEvent) => void;
    dismissSheetOnSelect?: boolean;
    visible?: boolean; // defaults to true
};

export type SheetMenuProps = {
    menuItems: SheetMenuItem[];
};

const SheetMenu = ({ menuItems }: SheetMenuProps) => {
    const { closeSheetWithTransition } = useSheetContext();

    return (
        <ul className="SheetMenu">
            {menuItems
                .filter((item) => item.visible !== false)
                .map((item) => (
                    <li key={item.label}>
                        <Button
                            onClick={(event) => {
                                event.preventDefault();
                                item.onClick(event);

                                if (item.dismissSheetOnSelect) {
                                    closeSheetWithTransition();
                                }
                            }}
                        >
                            {item.icon}
                            <span>{item.label}</span>
                        </Button>
                    </li>
                ))}
        </ul>
    );
};

export default SheetMenu;
