// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getMainEditorId, getMainEditorKey } from '../utils/elementEditorUtils';

// Components
import createRichTextEditor from '../../components/editor/richText/createRichTextEditor';

const RichTextEditor = createRichTextEditor({ clickableLinks: false });

const CardDraftEditor = (props) => {
    const {
        element,
        isEditable,
        isEditing,
        isClone,
        isSelected,
        filterQuery,
        saveContent,
        startEditing,
        stopEditing,
        moveElementsToTrash,
        editorRef,
        currentEditorKey,
        currentEditorId,
        updateElementType,
        createNewCard,
        spellCheck,
        placeholder,
        localEditorState,
        onAppUndo,
        onAppRedo,
        textContent,
        originalEditorId,
        shouldFocusOnlyWhenSelected,
        isFocusedBackgroundElement,
        isSingleSelected,
    } = props;

    const cardEditorKey = getMainEditorKey(props);
    const cardEditorId = getMainEditorId(props);

    // If the editor is focused, then we don't want it to be treated as isEditing, otherwise it won't update
    //  when the text content changes
    const treatEditorAsEditing = isEditing && !isFocusedBackgroundElement;

    return (
        <RichTextEditor
            className="CardEditor"
            placeholder={placeholder || 'Start typing...'}
            element={element}
            localEditorState={localEditorState}
            textContent={textContent}
            isEditing={treatEditorAsEditing}
            shouldFocusOnlyWhenSelected={shouldFocusOnlyWhenSelected}
            isSingleSelected={isSingleSelected}
            isSelected={isSelected}
            isClone={isClone}
            filterQuery={filterQuery}
            saveContent={saveContent}
            startEditing={startEditing}
            stopEditing={stopEditing}
            isEditable={isEditable}
            onEmptyBackspace={moveElementsToTrash}
            editorId={cardEditorId}
            originalEditorId={originalEditorId}
            editorKey={cardEditorKey}
            editorRef={editorRef}
            currentEditorKey={currentEditorKey}
            currentEditorId={currentEditorId}
            updateElementType={updateElementType}
            createNewCard={createNewCard}
            spellCheck={spellCheck}
            onAppUndo={onAppUndo}
            onAppRedo={onAppRedo}
        />
    );
};

CardDraftEditor.propTypes = {
    element: PropTypes.object.isRequired,
    isEditable: PropTypes.bool,
    isEditing: PropTypes.bool,
    isClone: PropTypes.bool,
    isSelected: PropTypes.bool,
    isSingleSelected: PropTypes.bool,
    shouldFocusOnlyWhenSelected: PropTypes.bool,
    filterQuery: PropTypes.string,
    saveContent: PropTypes.func,
    startEditing: PropTypes.func,
    stopEditing: PropTypes.func,
    moveElementsToTrash: PropTypes.func,
    editorRef: PropTypes.func,
    currentEditorKey: PropTypes.string,
    currentEditorId: PropTypes.string,
    updateElementType: PropTypes.func,
    createNewCard: PropTypes.func,
    spellCheck: PropTypes.bool,
    placeholder: PropTypes.string,
    localEditorState: PropTypes.object,
    originalEditorId: PropTypes.string,
    onAppUndo: PropTypes.func,
    onAppRedo: PropTypes.func,
    textContent: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    isFocusedBackgroundElement: PropTypes.bool,
};

export default CardDraftEditor;
