// Lib
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { defer } from 'lodash';

// Utils
import stripEmptyTrailingNode from '../../../../common/tiptap/utils/jsonContentUtils/manipulation/stripEmptyTrailingNode';
import createJsonContentFromSlice from '../../../../common/tiptap/utils/createJsonContentUtils/createJsonContentFromSlice';

// Selectors
import {
    getCurrentlyEditingEditorId,
    getCurrentlyEditingEditorKey,
    getCurrentlyEditingId,
} from '../../selection/currentlyEditingSelector';

// Hooks
import { useActiveTiptapEditorCallback } from '../../../components/tiptapEditor/store/tiptapEditorStoreHooks';

// Actions
import { startEditingElement } from '../../selection/selectionActions';
import { clipperClear, clipperOperationComplete, clipperSet } from './store/clipperActions';
import { getNextUndoElementCreateTransactionId } from '../../../utils/undoRedo/undoRedoActions';

// Types
import { EditorContent } from '../../../../common/elements/elementModelTypes';
import { Editor } from '@tiptap/react';

type EditorDetails = {
    id: string;
    editorId: string;
    editorKey: string;
};

const getCurrentEditorDetailsThunk =
    () =>
    (_: Function, getState: Function): EditorDetails => {
        const state = getState();
        const id = getCurrentlyEditingId(state);
        const editorId = getCurrentlyEditingEditorId(state);
        const editorKey = getCurrentlyEditingEditorKey(state);

        return {
            id,
            editorId,
            editorKey,
        };
    };

type CardClipperEventHandler = {
    beforeDragEnd: (dragSuccess: boolean) => void;
    onClipStart: () => void;
    onClipEnd: (clipWasSuccessful: boolean) => void;
};

export const useCardClipperEventHandler = (elementId: string, isClipCopy: boolean): CardClipperEventHandler => {
    const wasCopyRef = React.useRef(false);
    const activeEditorRef = React.useRef<Editor | null>(null);
    const initialEditorDetailsRef = React.useRef<EditorDetails | null>(null);

    const dispatch = useDispatch();

    const dispatchGetElementCreateTransactionId = () => dispatch(getNextUndoElementCreateTransactionId());

    const dispatchStartEditingElement = (editorDetails: EditorDetails) => dispatch(startEditingElement(editorDetails));

    const dispatchClipperSet = ({
        clippedElementId,
        clippedText,
    }: {
        clippedElementId: string;
        clippedText: EditorContent;
    }) => dispatch(clipperSet({ clippedElementId, clippedText }));

    const dispatchGetCurrentEditorIds = (): EditorDetails =>
        dispatch(getCurrentEditorDetailsThunk()) as unknown as EditorDetails;

    const dispatchClipperClear = () => dispatch(clipperClear());

    const dispatchClipperOperationComplete = (transactionId: number) =>
        dispatch(clipperOperationComplete(transactionId));

    //****************** Event Handlers - start ******************//

    /**
     * On clip start get the selected content and set it to the clipper state.
     */
    const onClipStart = useActiveTiptapEditorCallback((activeEditor) => {
        if (!activeEditor) return;

        activeEditorRef.current = activeEditor;

        initialEditorDetailsRef.current = dispatchGetCurrentEditorIds();

        wasCopyRef.current = false;

        let clippedText = createJsonContentFromSlice(activeEditor.state.selection.content(), activeEditor.schema);
        clippedText = stripEmptyTrailingNode(clippedText);

        dispatchClipperSet({ clippedElementId: elementId, clippedText });

        // Pause the syncing to prevent an ELEMENT_UPDATE from being created
        //  and added to the undo stack
        activeEditor.commands.startClip();
    }, []);

    /**
     * Check the copy state before the drag ends, because we can't access this within onClipEnd.
     */
    const beforeDragEnd = useCallback(() => {
        wasCopyRef.current = isClipCopy;
    }, [isClipCopy]);

    /**
     * On clip end:
     * - Return to editing the clipped element, if the clip was not successful
     * - Clear the clipped state if it was successful
     */
    const onClipEnd = useCallback((clipWasSuccessful: boolean) => {
        if (!activeEditorRef.current) return;

        dispatchClipperClear();

        // Revert the clip
        if (!clipWasSuccessful) {
            if (initialEditorDetailsRef.current) {
                dispatchStartEditingElement(initialEditorDetailsRef.current);
            }

            activeEditorRef.current.commands.abortClip();
            activeEditorRef.current = null;

            return;
        }

        // Unfortunately need to defer here due to the element create event happening asynchronously
        defer(() => {
            // We need to force the ELEMENT_UPDATE of the clipped element to be on the same transaction
            //  as the ELEMENT_CREATE, to ensure they can be undone together
            const transactionId = dispatchGetElementCreateTransactionId() as unknown as number;
            dispatchClipperOperationComplete(transactionId);

            activeEditorRef.current?.commands.commitClip(transactionId, wasCopyRef.current);
            activeEditorRef.current = null;
        });
    }, []);

    //****************** Event Handlers - end ******************//

    return {
        onClipStart,
        beforeDragEnd,
        onClipEnd,
    };
};
