// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '../../../../../../node_module_clones/recompose';

// Utils
import editorStoreConnect from '../../../../../components/editor/store/components/editorStoreConnect';

// Actions
import { closePopup } from '../../../../../components/popupPanel/popupActions';

// Selectors
import { popupOpenSelector } from '../../../../../components/popupPanel/popupOpenSelector';
import { getEditorState } from '../../../../../components/editor/store/reducers/editorStoreSelector';
import getFirstEntityInSelection from '../../../../../components/editor/store/reducers/getFirstEntityInSelection';

// Components
import hyperlinkOpenPopupDecorator from '../../../../../components/editor/plugins/hyperlink/hyperlinkOpenPopupDecorator';
import ToolbarPopup from '../../toolbarPopup/ToolbarPopup';
import RichTextTool from './RichTextTool';
import HyperlinkPopup from './hyperlinkPopup/DraftHyperlinkPopup';

// Constants
import { PopupIds } from '../../../../../components/popupPanel/popupConstants';

const mapEditorStateToProps = (state) => {
    const editorState = getEditorState(state);

    if (!editorState) return { disabled: true };

    const firstEntityKey = getFirstEntityInSelection(editorState);
    const selection = editorState.getSelection();

    if (selection.isCollapsed() && !firstEntityKey) return { disabled: true };

    if (!firstEntityKey) return { isActive: false, url: '' };

    const contentState = editorState.getCurrentContent();
    const firstEntity = contentState.getEntity(firstEntityKey);

    const url = (firstEntity && firstEntity.get('data') && firstEntity.get('data').url) || '';
    return { isActive: !!url, url };
};

const mapStateToProps = (state) => ({
    isPopupOpen: popupOpenSelector(PopupIds.HYPERLINK)(state),
});

const mapDispatchToProps = (dispatch) => ({
    closeHyperlinkPopup: () => dispatch(closePopup(PopupIds.HYPERLINK)),
});

const HyperLinkRichTextToolContainer = ({ closeHyperlinkPopup, openHyperlinkPopup, isPopupOpen, ...rest }) => (
    <div className="HyperLinkRichTextToolContainer">
        <RichTextTool {...rest} onClick={openHyperlinkPopup} isOpen={isPopupOpen} tooltipEnabled={!isPopupOpen}>
            <ToolbarPopup
                popupId={PopupIds.HYPERLINK}
                className="hyperlink-popup"
                buttonSelector=".RichTextTool.link .icon"
                closePopup={closeHyperlinkPopup}
            >
                <HyperlinkPopup {...rest} />
            </ToolbarPopup>
        </RichTextTool>
    </div>
);

HyperLinkRichTextToolContainer.propTypes = {
    isPopupOpen: PropTypes.bool,
    openHyperlinkPopup: PropTypes.func,
    closeHyperlinkPopup: PropTypes.func,
};

const enhancer = compose(
    editorStoreConnect(mapEditorStateToProps),
    hyperlinkOpenPopupDecorator,
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhancer(HyperLinkRichTextToolContainer);
