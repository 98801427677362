import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementCardIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={24}
        viewBox="0 0 32 24"
        className="Icon element-card"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={24} fill="#FFF" rx={2} />
            <path
                fill="#48505E"
                d="M5.75 5h20.5a.75.75 0 1 1 0 1.5H5.75a.75.75 0 0 1 0-1.5zm0 5h20.5a.75.75 0 1 1 0 1.5H5.75a.75.75 0 1 1 0-1.5zm0 5h18.143a.75.75 0 1 1 0 1.5H5.75a.75.75 0 1 1 0-1.5z"
            />
        </g>
    </svg>
);
const Memo = memo(ElementCardIcon);
export default Memo;
