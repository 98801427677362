// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from '../../../../node_module_clones/react-transition-group';

// Utils
import { getHasClones } from '../../../../common/elements/utils/elementPropertyUtils';

// Components
import Icon from '../../../components/icons/Icon';
import ElementTip from '../../suggestion/ElementTip';
import CardCloneInstancesPopup from './CardCloneInstancesPopup';
import PopupTrigger from '../../../components/popupPanel/PopupTrigger';

// Styles
import './CardCloneSection.scss';

const CardCloneSection = (props) => {
    const { isClone, elementId, element } = props;

    const [popupIsOpen, setPopupIsOpen] = React.useState(false);

    const onToggle = React.useCallback((isOpen) => {
        setPopupIsOpen(isOpen);
    }, []);

    const popupId = `card-clone-popup-${elementId}`;

    const contents =
        isClone || getHasClones(element) || popupIsOpen ? (
            <>
                <ElementTip icon={<Icon name="clone" />}>
                    <div className="text">
                        There are&nbsp;
                        <PopupTrigger popupId={popupId} onToggle={onToggle}>
                            {(isOpen) => <span>other copies</span>}
                        </PopupTrigger>
                        &nbsp;of this card
                    </div>
                </ElementTip>

                <CardCloneInstancesPopup popupId={popupId} {...props} />
            </>
        ) : null;

    return (
        <div className="CardCloneSection">
            <CSSTransitionGroup
                transitionName="slide"
                transitionAppear
                transitionAppearTimeout={3000}
                transitionEnterTimeout={3000}
                transitionLeaveTimeout={3000}
            >
                {contents}
            </CSSTransitionGroup>
        </div>
    );
};

CardCloneSection.propTypes = {
    elementId: PropTypes.string,
    element: PropTypes.object,
    isClone: PropTypes.bool,
};

export default CardCloneSection;
