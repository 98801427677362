import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { getElementId, getMediaType } from '../../../../../../common/elements/utils/elementPropertyUtils';
import { isEmpty } from '../../../../../../common/utils/immutableHelper';

// Actions
import { navigateToElement } from '../../../../../reducers/navigationActions';
import { deselectAllElements } from '../../../../../element/selection/selectionActions';

// Components
import ToolbarTool from '../../ToolbarTool';
import Icon from '../../../../../components/icons/Icon';

// Constants
import { MEDIA_TYPES } from '../../../../../../common/links/richMediaConstants';

const mapDispatchToProps = (dispatch, ownProps) => ({
    onClick: (event) => {
        const elementId = getElementId(ownProps.selectedElements.first());

        dispatch(deselectAllElements());

        dispatch(
            navigateToElement({
                elementId,
                params: { editing: true },
            }),
        );
    },
});

const ImageEditTool = (props) => {
    const { selectedElements } = props;

    if (!selectedElements || isEmpty(selectedElements)) return null;

    if (getMediaType(selectedElements.first()) === MEDIA_TYPES.NO_MEDIA) return null;

    return (
        <ToolbarTool {...props} name={props.name} isActive={false}>
            <Icon {...props} name="toolbar-image-edit" />
        </ToolbarTool>
    );
};

ImageEditTool.propTypes = {
    selectedElements: PropTypes.object,
    name: PropTypes.string,
    isActive: PropTypes.bool,
};

export default connect(null, mapDispatchToProps)(ImageEditTool);
