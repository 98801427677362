// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import { connectElementsWithLines } from './connectionActions';

// Components
import ToolbarTool from '../../ToolbarTool';
import Icon from '../../../../../components/icons/Icon';

const mapDispatchToProps = (dispatch, ownProps) => ({
    onClick: (event) => dispatch(connectElementsWithLines(ownProps.selectedElements)),
});

const ConnectTool = (props) => (
    <ToolbarTool {...props} name={props.name}>
        <Icon name="toolbar-connect" />
    </ToolbarTool>
);

ConnectTool.propTypes = {
    name: PropTypes.string,
    isActive: PropTypes.bool,
};

export default connect(null, mapDispatchToProps)(ConnectTool);
