import { Extension, Command, CommandProps } from '@tiptap/core';

export interface KeyboardShortcutsOptions {
    /**
     * An object specifying the keyboard shortcuts and their handlers to be added to the editor.
     */
    keyboardShortcuts: Record<string, Command>;
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        keyboardShortcuts: {
            /**
             * Invokes
             * @example editor.commands.triggerKeyboardShortcut()
             */
            triggerKeyboardShortcut: (shortcut: string) => ReturnType;
        };
    }
}

/**
 * Allows custom keyboard shortcuts to be added to the editor, as part of the configuration of this extension.
 */
export const KeyboardShortcuts = Extension.create<KeyboardShortcutsOptions>({
    name: 'keyboardShortcuts',

    addCommands() {
        return {
            triggerKeyboardShortcut: (shortcut: string) => (args) => {
                const { keyboardShortcuts } = this.options;

                if (!keyboardShortcuts) return false;

                if (!keyboardShortcuts[shortcut]) return false;

                // FIXME-TYPES - This is likely due to our tsconfig using node resolution rather than module resolution
                //  It can't determine that the two CommandProps types are the same.
                //  Revisit this when we fix the tsconfig
                return keyboardShortcuts[shortcut](args as unknown as CommandProps);
            },
        };
    },

    addKeyboardShortcuts() {
        const { keyboardShortcuts } = this.options;

        if (!keyboardShortcuts) return {};

        // Dynamically hook up the keyboard shortcut to the "triggerKeyboardShortcut" command
        return Object.fromEntries(
            Object.keys(keyboardShortcuts).map((shortcut) => [
                shortcut,
                () => this.editor.commands.triggerKeyboardShortcut(shortcut),
            ]),
        );
    },
});
