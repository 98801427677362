// Lib
import { generateJSON } from '@tiptap/core';

// Constants
import { TiptapContent } from '../../tiptapTypes';
import { CARD_STATIC_TIPTAP_EXTENSIONS } from '../../extensions/extensionLists';

const createCardJsonContent = (html: string, text: string): TiptapContent | null => {
    if (!text && !html) return null;

    if (html) return generateJSON(html, CARD_STATIC_TIPTAP_EXTENSIONS) as TiptapContent;

    return generateJSON(text, CARD_STATIC_TIPTAP_EXTENSIONS) as TiptapContent;
};

export default createCardJsonContent;
