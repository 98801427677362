/**
 * Finds the closest parent element with the attribute `data-scrollable="true"`.
 * This is a Milanote specific attribute used to identify scrollable sections.
 */
const getParentScrollableSection = (domElement?: HTMLElement | null): HTMLElement | null => {
    if (!domElement) return null;

    return domElement.closest("[data-scrollable='true']");
};

export default getParentScrollableSection;
