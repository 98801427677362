import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Components
import ToolbarTool from '../../ToolbarTool';
import Icon from '../../../../../components/icons/Icon';

// Utils
import { isSelectingSingleSelectionAndCell } from '../../../../../element/table/utils/tableCellSelectionUtils';

// Selectors
import { getCurrentCellSelections } from '../../../../../element/table/tableSelector';
import { getElementId } from '../../../../../../common/elements/utils/elementPropertyUtils';
import HotTableManager from '../../../../../element/table/manager/HotTableManager';

const mapStateToProps = createStructuredSelector({
    currentCellSelections: getCurrentCellSelections,
});

const TableCellFormulaTool = (props) => {
    const { element, currentCellSelections } = props;

    const elementId = getElementId(element);

    const triggerFormula = () => {
        const hotTable = HotTableManager.getHotTableComponent(elementId);
        if (!hotTable?.tableOperationsRef?.current) return;

        hotTable.tableOperationsRef.current.addFormulaToCell();
    };

    return (
        <div className="TableCellFormulaTool">
            <ToolbarTool
                {...props}
                onClick={triggerFormula}
                name="Formula"
                isActive={false}
                disabled={!isSelectingSingleSelectionAndCell(currentCellSelections)}
            >
                <Icon name="toolbar-formula" />
            </ToolbarTool>
        </div>
    );
};

TableCellFormulaTool.propTypes = {
    iconName: PropTypes.string,
    element: PropTypes.object,
    currentCellSelections: PropTypes.object,
};

export default connect(mapStateToProps)(TableCellFormulaTool);
