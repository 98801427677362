// Lib
import React from 'react';
import { useSelector } from 'react-redux';

// Components
import PageLoaderMain from './PageLoaderMain';

// Selectors
import { getIsLocalCacheHydrated } from '../../offline/cache/localCacheSelector';
import { getLegacyHybridUseCaseSelector } from '../../platform/platformSelector';

// Types
import { LegacyHybridUseCase } from '../../platform/platformTypes';

// Styles
import './PageLoader.scss';

const isAppHeaderVisible = (legacyHybridUseCase: LegacyHybridUseCase | null) => {
    switch (legacyHybridUseCase) {
        case LegacyHybridUseCase.IOS_BOARD_LIST:
        case LegacyHybridUseCase.IOS_CANVAS:
            return false;
        case LegacyHybridUseCase.IPAD_OS:
            return true;
        default:
            return true;
    }
};

const PageLoader = () => {
    const isLocalCacheHydrated = useSelector(getIsLocalCacheHydrated);
    const legacyHybridUseCase = useSelector(getLegacyHybridUseCaseSelector);

    return (
        <div className="PageLoader">
            <div className="WorkspaceWrapper">
                {isAppHeaderVisible(legacyHybridUseCase) && (
                    <header className="AppHeader">
                        <div className="WorkspaceToolsHeader header-tools" />
                    </header>
                )}
                <div className="Workspace">
                    <div className={'workspace-positioner'}>
                        <div id="toolbar" className="Toolbar" />
                        {!isLocalCacheHydrated && <PageLoaderMain />}
                    </div>
                </div>
                <div className="MobileToolbar" />
            </div>
        </div>
    );
};

export default PageLoader;
