// Lib
import { useMemo } from 'react';
import { Extensions } from '@tiptap/core';

// Extensions
import { OneLineDocument } from '../../../../common/tiptap/extensions/OneLineDocument';
import { AppUndo } from '../../../../common/tiptap/extensions/AppUndo';

// Hooks
import useCommonTiptapEditorExtensions, {
    UseCommonTiptapEditorExtensionsArgs,
} from '../../../../common/tiptap/hooks/useCommonTiptapEditorExtensions';
import useTaskTiptapEditorKeyboardShortcutsExtension, {
    TaskTiptapEditorKeyboardShortcutHandlerArgs,
} from './useTaskTiptapEditorKeyboardShortcutsExtension';

type UseTaskTiptapEditorExtensionsArgs = UseCommonTiptapEditorExtensionsArgs &
    TaskTiptapEditorKeyboardShortcutHandlerArgs;

const useTaskTiptapEditorExtensions = ({
    saveContent,
    isEditable,
    dispatch,
    placeholder,
    onHighlightsChanged,
    onEnter,
    onKeyboardDelete,
    changeIndentation,
    toggleComplete,
    dispatchOpenDueDatePopup,
    dispatchOpenAssignmentPopup,
    goToPreviousTask,
    goToNextTask,
    onStartBackspace,
    onEndDelete,
}: UseTaskTiptapEditorExtensionsArgs): Extensions => {
    const commonExtensions = useCommonTiptapEditorExtensions({
        saveContent,
        placeholder,
        isEditable,
        dispatch,
        onHighlightsChanged,
    });

    const KeyboardExtension = useTaskTiptapEditorKeyboardShortcutsExtension({
        onEnter,
        onKeyboardDelete,
        changeIndentation,
        toggleComplete,
        dispatchOpenDueDatePopup,
        dispatchOpenAssignmentPopup,
        goToPreviousTask,
        goToNextTask,
        onStartBackspace,
        onEndDelete,
    });

    return useMemo(
        () => [OneLineDocument, KeyboardExtension, AppUndo.extend({}).configure({ dispatch }), ...commonExtensions],
        [commonExtensions, KeyboardExtension],
    );
};

export default useTaskTiptapEditorExtensions;
