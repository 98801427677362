import { StateField } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';

/**
 * Creates the state field for a simple class ProseMirror decoration plugin.
 *
 * This will apply a class to the current selection if the transaction has a meta
 * property with the provided decoration name.
 *
 * It's up to the plugin where this is included to manage the adding & clearing of the decoration.
 */
const createSimpleClassDecorationState = (decorationName: string): StateField<DecorationSet> => ({
    init: () => DecorationSet.empty,

    apply: (transaction, oldState) => {
        const { selection, doc } = transaction;
        const highlightClass = transaction.getMeta(decorationName);

        // When the provided class is empty, clear all decorations (this happens on blur)
        if (highlightClass === '') return DecorationSet.empty;

        // If the class is otherwise falsy, the transaction isn't related to this plugin
        if (!highlightClass) return oldState;

        // Grab the already-applied decorations' classes
        const classes = [...oldState.find().map((deco: Decoration) => deco.spec.class), highlightClass];
        const className = classes.join(' ');

        // Apply the decoration to the selection
        return DecorationSet.create(doc, [
            Decoration.inline(
                selection.from,
                selection.to,
                // add the data to the decoration's attrs (to actually style things)
                { class: className },
                // add the same data to the spec (for reading in subsequent transactions)
                { class: className },
            ),
        ]);
    },
});

export default createSimpleClassDecorationState;
