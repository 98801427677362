// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { getIsCollapsed } from '../../../../../../common/elements/utils/elementPropertyUtils';
import { isLocationAttached, isLocationInbox } from '../../../../../../common/elements/utils/elementLocationUtils';

// Actions
import { toggleCollapseSelectedCommentThreads } from '../../../../../element/comment/store/commentCollapseActions';

// Components
import Icon from '../../../../../components/icons/Icon';
import ToolbarTool from '../../ToolbarTool';

const mapDispatchToProps = (dispatch) => ({
    dispatchToggleCollapseSelectedCommentThreads: (nowCollapsed) =>
        dispatch(toggleCollapseSelectedCommentThreads(nowCollapsed)),
});

const mapStateToProps = (state, ownProps) => ({
    show: !isLocationAttached(ownProps.selectedElements.first()) && !isLocationInbox(ownProps.selectedElements.first()),
    isActive: getIsCollapsed(ownProps.selectedElements.first()),
});

const CollapseTool = (props) => {
    const { show, isActive, dispatchToggleCollapseSelectedCommentThreads } = props;

    const setLineStyle = React.useCallback(() => {
        dispatchToggleCollapseSelectedCommentThreads(!isActive);
    }, [isActive]);

    if (!show) return null;

    return (
        <ToolbarTool {...props} onClick={setLineStyle} isActive={isActive}>
            <Icon {...props} name="toolbar-collapse" />
        </ToolbarTool>
    );
};

CollapseTool.propTypes = {
    name: PropTypes.string,
    show: PropTypes.bool,
    isActive: PropTypes.bool,
    selectedElements: PropTypes.object.isRequired,

    dispatchToggleCollapseSelectedCommentThreads: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CollapseTool);
