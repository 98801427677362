// Constants
import { DRAG_START, DRAG_END, REPLACE_MODE_START, REPLACE_MODE_END } from './draggingConstants';

export const dragStart = (
    { ids, source, finishEditing = true }: { ids?: string[]; source?: string; finishEditing?: boolean } = {
        ids: undefined,
        source: undefined,
        finishEditing: true,
    },
) => ({
    type: DRAG_START,
    ids,
    source,
    finishEditing,
});

type DropState = {
    // TODO: flesh this out with valid values
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
};

export const dragEnd = (dropState: DropState) => ({ type: DRAG_END, dropState });
export const startReplaceMode = (elementId: string) => ({ type: REPLACE_MODE_START, elementId });
export const endReplaceMode = () => ({ type: REPLACE_MODE_END });
