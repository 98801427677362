// Lib
import React, { ReactElement, ReactNode, useContext } from 'react';
import { createPortal } from 'react-dom';

// Styles
import './SheetPortalMountPoint.scss';

type SheetPortalContextArgs = {
    mountPointRef: React.RefObject<HTMLDivElement>;
};

const SheetPortalContext = React.createContext<SheetPortalContextArgs>({
    mountPointRef: React.createRef<HTMLDivElement>(),
});

const useSheetPortalContext = () => useContext(SheetPortalContext);

/**
 * This is the component that should be used by most other components to render their content
 * within the toolbar portal.
 */
interface SheetPortalProps {
    children: ReactNode;
}

/**
 * This allows the content to be rendered within the portal.
 */
const SheetPortal = (props: SheetPortalProps): ReactElement | null => {
    const { children } = props;

    const { mountPointRef } = useSheetPortalContext();

    if (!mountPointRef.current) return null;

    return createPortal(children, mountPointRef.current);
};

export default SheetPortal;

/**
 * This creates the div that will be used to mount the portal content.
 */
export const SheetPortalMountPoint = () => {
    const { mountPointRef } = useSheetPortalContext();

    return <div ref={mountPointRef} className="SheetPortalMountPoint" />;
};
