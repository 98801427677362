// Types
import { Selection } from '@tiptap/pm/state';
import { Node } from '@tiptap/pm/model';

/**
 * Finds the range of the highest node that fully contains the current selection.
 *
 * Examples:
 *  - If the selection is wrapping text inside a paragraph, it will return the range of the paragraph.
 *  - If the selection is wrapping a paragraph inside a list item, of an ordered list with only 1 item,
 *     it will return the ordered list.
 *  - If the same ordered list contains two list items, then it would only return the range of the list item.
 *
 *  The purpose of this is to remove clipped nodes, rather than just clipping the text that's selected.
 */
const expandRangeToHighestContainingNode = (selection: Selection, doc: Node): { from: number; to: number } => {
    const { $from, $to } = selection;

    let $start = $from;
    let $end = $to;

    while ($start.depth > 0 && $start.parent.type !== doc.type) {
        const $beforeStart = doc.resolve($start.pos - 1);
        const $afterEnd = doc.resolve($end.pos + 1);

        // Parents are not the same, so end the loop
        if (!$beforeStart.sameParent($afterEnd)) break;

        // Keep moving up until we find the common parent
        $start = $beforeStart;
        $end = $afterEnd;
    }

    return { from: $start.pos, to: $end.pos };
};

export default expandRangeToHighestContainingNode;
