// Local clone of the Tiptap core focus command.
// Unedited from `(tiptap)/core/src/commands/focus.ts`. Cloned just because we need to be able to
// call it directly from an override of the Focus plugin, and the `focus` command is not exported.

import { isTextSelection, resolveFocusPosition, RawCommands, isiOS } from '@tiptap/core';

export const focus: RawCommands['focus'] =
    (position = null, options = {}) =>
    ({ editor, view, tr, dispatch }) => {
        options = {
            scrollIntoView: true,
            ...options,
        };

        const delayedFocus = () => {
            // focus within `requestAnimationFrame` breaks focus on iOS
            // so we have to call this
            if (isiOS()) {
                // UPDATED: pass in preventScroll option to prevent ios browsers from
                //      auto scrolling to the focused element
                (view.dom as HTMLElement).focus({ preventScroll: !options.scrollIntoView });
            }

            // For React we have to focus asynchronously. Otherwise wild things happen.
            // see: https://github.com/ueberdosis/tiptap/issues/1520
            requestAnimationFrame(() => {
                if (!editor.isDestroyed) {
                    view.focus();

                    if (options?.scrollIntoView) {
                        editor.commands.scrollIntoView();
                    }
                }
            });
        };

        if ((view.hasFocus() && position === null) || position === false) {
            return true;
        }

        // we don’t try to resolve a NodeSelection or CellSelection
        if (dispatch && position === null && !isTextSelection(editor.state.selection)) {
            delayedFocus();
            return true;
        }

        // pass through tr.doc instead of editor.state.doc
        // since transactions could change the editors state before this command has been run
        const selection = resolveFocusPosition(tr.doc, position) || editor.state.selection;
        const isSameSelection = editor.state.selection.eq(selection);

        if (dispatch) {
            if (!isSameSelection) {
                tr.setSelection(selection);
            }

            // `tr.setSelection` resets the stored marks
            // so we’ll restore them if the selection is the same as before
            if (isSameSelection && tr.storedMarks) {
                tr.setStoredMarks(tr.storedMarks);
            }

            delayedFocus();
        }

        return true;
    };
