import { Rect } from '../../../../common/maths/geometry/rect/rectTypes';

type ClipperLocationState = {
    selectionHeight: number;
    selectionYMidpoint: number;
};

/**
 * Determines the current location of the clipper tool based on the
 * selection rect, container rect and zoom.
 */
export const calculateClipperLocationState = (
    selectionRect: Rect | null,
    containerRect: Rect | undefined,
    zoomScale = 1,
): ClipperLocationState | null => {
    if (!selectionRect || !containerRect) return null;

    const selectionHeight = selectionRect.bottom - selectionRect.top;
    const selectionMidpoint = (selectionRect.bottom + selectionRect.top) / 2;
    // Need to divide by the scale to convert from rendered pixels to unscaled pixels
    const relativeMidpoint = Math.round(selectionMidpoint - containerRect.top) / zoomScale;

    return {
        selectionHeight,
        selectionYMidpoint: relativeMidpoint,
    };
};
