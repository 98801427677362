// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import hyperlinkOpenPopupDecorator from '../../components/editor/plugins/hyperlink/hyperlinkOpenPopupDecorator';
import MilanoteEditor from '../../components/editor/milanoteEditor/MilanoteEditor';

// Styles
import './TitleDraftJsEditor.scss';

const TitleDraftJsEditor = (props) => (
    <MilanoteEditor {...props} className={classNames('TitleDraftJsEditor', props.className)} />
);

TitleDraftJsEditor.propTypes = {
    className: PropTypes.string,
};

export default hyperlinkOpenPopupDecorator(TitleDraftJsEditor);
