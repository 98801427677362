// Lib
import { defer } from 'lodash';
import { useMemo } from 'react';

// Utils
import { isTiptapEditorEmpty } from '../../../../common/tiptap/utils/isTiptapEditorEmpty';

// Hooks
import useCommonTiptapEditorExtensions, {
    UseCommonTiptapEditorExtensionsArgs,
} from '../../../../common/tiptap/hooks/useCommonTiptapEditorExtensions';

// Custom extensions
import { KeyboardShortcuts } from '../../../../common/tiptap/extensions/KeyboardShortcuts';
import { OneLineDocument } from '../../../../common/tiptap/extensions/OneLineDocument';

// Types
import { Editor } from '@tiptap/react';
import { Extensions, Command } from '@tiptap/core';
import { TiptapContent } from '../../../../common/tiptap/tiptapTypes';
import { MentionPluginKey } from '@tiptap/extension-mention';

type UseCommentTiptapEditorExtensionsArgs = UseCommonTiptapEditorExtensionsArgs & {
    onSubmit?: (text: TiptapContent) => void;
};

/**
 * Gets the extensions for comment Tiptap editors.
 */
const useCommentTiptapEditorExtensions = ({
    placeholder,
    onEmptyBackspace,
    saveContent,
    suggestionsManager,
    isEditable,
    dispatch,
    onSubmit,
    onHighlightsChanged,
}: UseCommentTiptapEditorExtensionsArgs): Extensions => {
    const commonExtensions = useCommonTiptapEditorExtensions({
        saveContent,
        onEmptyBackspace,
        placeholder,
        suggestionsManager,
        isEditable,
        dispatch,
        onHighlightsChanged,
    });

    return useMemo(() => {
        const onReturn: Command = ({ editor }) => {
            if (isTiptapEditorEmpty(editor as unknown as Editor)) return false;

            // Don't submit the comment if the mention popup is active
            const mentionPluginState = MentionPluginKey.getState(editor.state);

            if (mentionPluginState.active) return false;

            // We need to defer the handling of this to prevent the Mousetrap
            //  event handler from being triggered - because the element would be
            //  editable and selected while the event gets propagated upwards
            defer(() => {
                const currentContent = editor.getJSON();
                onSubmit?.(currentContent as TiptapContent);
            });

            return !!onSubmit;
        };

        return [
            OneLineDocument,
            ...commonExtensions,
            onSubmit &&
                KeyboardShortcuts.configure({
                    keyboardShortcuts: {
                        // Submit the caption on return or enter
                        Return: onReturn,
                        Enter: onReturn,
                    },
                }),
        ].filter(Boolean) as Extensions;
    }, [commonExtensions, suggestionsManager]);
};

export default useCommentTiptapEditorExtensions;
