import React from 'react';
import SheetContainer, {
    CloseButton,
    Overlay,
    Content,
    Header,
    Heading,
    Sheet,
} from '../../../mobile/structural/sheet';
import useSheet from '../../../mobile/structural/sheet/hooks/useSheet';
import { SheetId } from '../../../mobile/structural/sheet/sheetTypes';

interface MobileDocumentModalProps {
    close: () => void;
}

const MobileDocumentModal = (props: MobileDocumentModalProps) => {
    const { close } = props;
    const sheetProps = useSheet(SheetId.MobileDocumentModal);

    return (
        <SheetContainer
            {...sheetProps}
            openByDefault
            defaultSnapPoint={1}
            snapPoints={[0.7, 1]}
            onCloseTransitionEnd={close}
        >
            <Overlay />
            <Sheet>
                <Header>
                    <Heading>Document</Heading>
                    <CloseButton />
                </Header>
                <Content>
                    <div>Document content</div>
                </Content>
            </Sheet>
        </SheetContainer>
    );
};

export default MobileDocumentModal;
