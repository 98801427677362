// Lib
import { cloneDeep } from 'lodash';

// Utils
import * as pointsLib from '../../../../common/maths/geometry/point';
import { positionPixelsToGridPoints } from '../../../utils/grid/gridUtils';
import { isLocationCanvas } from '../../../../common/elements/utils/elementLocationUtils';

// Constants
import { DEFAULT_INCREMENT } from '../../../../common/elements/listOrderScores/scoreCalculator';

const translateDropResultIntoElementCanvasLocation = ({
    dropResult,
    unscaledElementOffsetsMap,
    elementId,
    gridSize,
    draggedElementIds,
    highestScore,
    scaledCustomDragOffset = { x: 0, y: 0 },
}) => {
    const unscaledElementOffsetPx = unscaledElementOffsetsMap?.[elementId] || { x: 0, y: 0 };
    const unscaledElementOffsetGridPoints = positionPixelsToGridPoints(unscaledElementOffsetPx, gridSize);

    const unscaledCustomDragOffset = pointsLib.reverseScale(dropResult.zoomScaleOnDropTarget, scaledCustomDragOffset);

    const position = pointsLib.translate(
        pointsLib.translate(dropResult.location.position, unscaledElementOffsetGridPoints),
        positionPixelsToGridPoints(unscaledCustomDragOffset, gridSize),
    );

    // For multi-moves
    if (draggedElementIds) {
        position.score = highestScore + (draggedElementIds.indexOf(elementId) + 1) * DEFAULT_INCREMENT;
    }

    return {
        ...dropResult.location,
        position,
    };
};

/**
 * Translates an element
 */
export default ({
    dropResult,
    unscaledElementOffsetsMap,
    elementId,
    gridSize,
    draggedElementIds = undefined,
    highestScore = undefined,
    scaledCustomDragOffset,
}) =>
    isLocationCanvas(dropResult)
        ? translateDropResultIntoElementCanvasLocation({
              dropResult,
              unscaledElementOffsetsMap,
              elementId,
              gridSize,
              draggedElementIds,
              highestScore,
              scaledCustomDragOffset,
          })
        : cloneDeep(dropResult.location);
