// Lib
import React, { ReactElement } from 'react';

// Hooks
import useBooleanRatchet from '../../../utils/react/useBooleanRatchet';
import { useTiptapActiveEditorSelector } from '../../../components/tiptapEditor/store/tiptapEditorStoreHooks';

// Components
import CardClipperDraftJsContents from './CardClipperDraftJsContents';
import CardClipperTiptapContents from './CardClipperTiptapContents';

// Types
import { CardClipperContentProps } from './cardClipperTypes';

const CardClipperContentsSwitch = (props: CardClipperContentProps): ReactElement | null => {
    const { elementId, isClipping, isClipCopy, isClipDragging, clippedText, getContextZoomScale } = props;

    const hasActiveTiptapEditor = useTiptapActiveEditorSelector((editor) => !!editor);

    // NOTE: Because the editor will stop editing on creation of the clipped element, we'd lose the active editor
    //  So in this case, we want to continue editing the Tiptap clipper, otherwise we'd lose the drag preview too
    const showTiptapClipper = useBooleanRatchet(hasActiveTiptapEditor);

    if (showTiptapClipper) {
        return (
            <CardClipperTiptapContents
                getContextZoomScale={getContextZoomScale}
                isClipping={isClipping}
                isClipCopy={isClipCopy}
                isClipDragging={isClipDragging}
                clippedText={clippedText}
                elementId={elementId}
            />
        );
    }

    return <CardClipperDraftJsContents {...props} />;
};

export default CardClipperContentsSwitch;
