import { useRef, MutableRefObject } from 'react';

type UseTiptapInnerRef = {
    innerRef: (elem: HTMLDivElement | null) => void;
    contentInnerRef: MutableRefObject<HTMLDivElement | null>;
};

const useTiptapInnerRef = (editorRef?: (elem: HTMLDivElement | null) => void): UseTiptapInnerRef => {
    const contentInnerRef = useRef<HTMLDivElement | null>(null);
    const innerRef = (elem: HTMLDivElement | null) => {
        // just to avoid calling querySelector every render
        if (contentInnerRef.current === elem) return;
        contentInnerRef.current = elem;

        // EditorContent's innerRef actually gives us the _parent_ of the actual content div,
        // which throws off the height values that editorRef is used to calculate.
        // (theoretically we can just read elem.childNodes[0] but querySelector is proof against
        // a future plugin adding a wrapper div or something)
        const actualContent = elem?.querySelector('.ProseMirror') as HTMLDivElement;
        editorRef?.(actualContent || null);
    };

    return {
        // The property to set on the @tiptap/react - EditorContent component
        innerRef,
        contentInnerRef,
    };
};

export default useTiptapInnerRef;
