// Selectors
import { getSelectedElementIds } from './selection/selectedElementsSelector';
import { getPresentationModeIsFocusModeAllowed } from '../workspace/presentation/presentationSelector';
import { getCurrentlyEditingId } from './selection/currentlyEditingSelector';

// Actions
import { updateElement } from './actions/elementActions';
import { showGuestAppRegistrationForm } from '../reducers/guestNavigationActions';
import { focusElementAppearing } from '../workspace/presentation/presentationActions';
import { deselectAllElements, finishEditingElement, startEditingElement } from './selection/selectionActions';
import { handleSelectionModeSelectElement } from './actions/elementSelectionActions';

export default (dispatch) => ({
    dispatchUpdateElement: ({ id, changes, sync, analyticsEvent, transactionId }) =>
        dispatch(updateElement({ id, changes, sync, analyticsEvent, transactionId })),
    dispatchGetSelectedElementIds: () => dispatch((_, getState) => getSelectedElementIds(getState())),
    dispatchGetCurrentlyEditingElement: () => dispatch((_, getState) => getCurrentlyEditingId(getState())),
    dispatchHandleSelectionModeSelectElement: (id, selectionMode, transactionId) =>
        dispatch(handleSelectionModeSelectElement(id, selectionMode, transactionId)),
    dispatchStartEditingElement: ({ id, editorId, editorKey, editorFocusClientCoords }) =>
        dispatch(startEditingElement({ id, editorId, editorKey, editorFocusClientCoords })),
    dispatchStopEditing: (id) => dispatch(finishEditingElement(id)),
    dispatchShowGuestAppRegistrationForm: () => dispatch(showGuestAppRegistrationForm()),
    dispatchFocusElementStart: (elementId, initialZoomScale, elementProps) => {
        dispatch(focusElementAppearing(elementId, initialZoomScale, elementProps));
        dispatch(finishEditingElement(elementId));
    },
    dispatchGetPresentationModeIsFocusModeAllowed: () =>
        dispatch((_, getState) => getPresentationModeIsFocusModeAllowed(getState())),
    dispatchDeselectAllElements: () => dispatch(deselectAllElements()),
});
