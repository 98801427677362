import { Extension, resolveFocusPosition } from '@tiptap/core';
import { focus } from './focusCommand';

/**
 * Extension to override the builtin focus command so that it doesn't scroll into view by default.
 */
export const Focus = Extension.create({
    name: 'focus',

    onCreate() {
        const selection = resolveFocusPosition(this.editor.state.doc, 'end')?.head || 0;
        if (selection > 1) return;

        // When creating an empty document, avoid a Chrome bug where it will appear to
        // select a non-existent space character,
        this.editor.commands.setTextSelection(0);
    },

    addCommands() {
        return {
            // just a thin wrapper around tiptap's builtin focus command, but override the scrollIntoView option
            focus: (position, options) => (context) => {
                focus(position, {
                    scrollIntoView: false,
                    ...options,
                })(context);
                return true;
            },
        };
    },
});
