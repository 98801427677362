import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementLinkIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={24}
        viewBox="0 0 32 24"
        className="Icon element-link"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={24} fill="#FFF" rx={2} />
            <path
                fill="#48505E"
                fillRule="nonzero"
                d="M17.986 10.014a4.285 4.285 0 0 1 0 6.06l-1.67 1.67a4.285 4.285 0 0 1-6.06-6.06.741.741 0 0 1 1.048 1.05 2.802 2.802 0 0 0 3.963 3.962l1.67-1.67a2.802 2.802 0 0 0 0-3.964.741.741 0 1 1 1.049-1.048zm3.759-3.759a4.285 4.285 0 0 1 0 6.06.741.741 0 0 1-1.049-1.048 2.802 2.802 0 0 0-3.963-3.963l-1.67 1.67a2.802 2.802 0 0 0 0 3.964.741.741 0 1 1-1.049 1.048 4.285 4.285 0 0 1 0-6.06l1.67-1.67a4.285 4.285 0 0 1 6.06 0z"
            />
        </g>
    </svg>
);
const Memo = memo(ElementLinkIcon);
export default Memo;
