import { animateScrollPromise } from '../../../utils/animation/animateScrollIntoView';
import { MOBILE_EDITING_TOOLBAR_HEIGHT, MOBILE_TOOLBAR_HEIGHT } from '../../toolbar/MobileToolbarConstants';

export let animateScrollToResetScrollExcessPadding = () => {};

/**
 * When animating to element at the bottom of the screen, there are situations where toScrollTop is
 * not accessible as it exceeds the possible scrollable area.
 *
 * In this case, we want to add in extra margin to the bottom of scrollableElement to allow for the scroll
 */
export const applyScrollExcessPadding = ({
    scrollableElement,
    toScrollLeft,
    toScrollTop,
    interpolationFactor,
}: {
    scrollableElement: HTMLElement;
    toScrollLeft: number;
    toScrollTop: number;
    interpolationFactor: number;
}): number => {
    if (!scrollableElement.parentElement) return 0;

    const mobileToolbarHeightChange = MOBILE_TOOLBAR_HEIGHT - MOBILE_EDITING_TOOLBAR_HEIGHT;
    const maxScrollTop = scrollableElement.scrollHeight - scrollableElement.parentElement.scrollHeight;

    // When the toolbar is in editing mode, the toolbar height will decrease, and visible canvas height will increase.
    // The max scrollTop need to account for this change.
    const maxScrollTopWithEditingToolbar = maxScrollTop - mobileToolbarHeightChange;
    const scrollExcess = toScrollTop - maxScrollTopWithEditingToolbar;

    if (scrollExcess <= 0) {
        animateScrollToResetScrollExcessPadding = () => {};
        return 0;
    }

    scrollableElement.style.paddingBottom = `${scrollExcess}px`;

    // Set animateScrollToResetScrollExcess function to animate back to position that resets the scrollExcess back to 0
    // - This function will be called on finish editing an element
    animateScrollToResetScrollExcessPadding = () =>
        animateScrollPromise({
            scrollableElement,
            interpolationFactor,
            fromScrollLeft: toScrollLeft,
            fromScrollTop: toScrollTop,

            // Scroll to the bottom of the page (without scrollExcess)
            toScrollLeft: toScrollLeft,
            toScrollTop: maxScrollTop,
        }).then(() => {
            scrollableElement.style.paddingBottom = ``;
        });

    return scrollExcess;
};
