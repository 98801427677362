// Lib
import { useEffect } from 'react';
import { Editor } from '@tiptap/react';

/**
 * When the editor starts editing, ensure the caret is placed in the editor.
 */
const useTiptapEditorFocus = (editor: Editor | null, isEditing: boolean) => {
    useEffect(() => {
        if (!isEditing) return;

        editor?.commands.focus();
    }, [isEditing]);
};

export default useTiptapEditorFocus;
