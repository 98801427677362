import { Extension } from '@tiptap/core';
import { TiptapEditorContent } from '../../tiptapTypes';
import { ExternalStoreSynchroniserOptions } from './externalStoreSynchroniserTypes';
import { ExternalStoreSynchroniserStorage } from './ExternalStoreSynchroniserStorage';

const DEFAULT_DEBOUNCE_TIME = 5000;
const DEFAULT_DEBOUNCE_COUNT = 80;

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        externalStoreSynchroniser: {
            /**
             * Cancel a pending update - a future update will be required for any changes to be synced.
             */
            cancelPendingUpdate: () => ReturnType;
            /**
             * Immediately flushes an update, if the content has changed since the last save.
             */
            flushPendingUpdate: ({ transactionId }: { transactionId?: number }) => ReturnType;
            /**
             * Sync the editor content from the external store.
             */
            syncFromExternalStore: (content: TiptapEditorContent) => ReturnType;
        };
    }
}

/**
 * This extension debounces saves to the editor content to avoid excessive
 * Redux store updates.
 */
export const ExternalStoreSynchroniser = Extension.create<
    ExternalStoreSynchroniserOptions,
    ExternalStoreSynchroniserStorage
>({
    name: 'externalStoreSynchroniser',

    addOptions() {
        return {
            onDebouncedUpdate: () => {},
            debounceTime: DEFAULT_DEBOUNCE_TIME,
            debounceCount: DEFAULT_DEBOUNCE_COUNT,
        };
    },

    addStorage() {
        return new ExternalStoreSynchroniserStorage(this.options);
    },

    /**
     * Initialise the last saved content & unload listener.
     */
    onCreate() {
        const { editor, storage } = this;

        storage.setLastStoredContent(editor.state.doc.content);
        storage.beforeUnloadListener = () => storage.flushUpdate({ editor });

        window.addEventListener('beforeunload', storage.beforeUnloadListener);
    },

    /**
     * Trigger a debounced update.
     */
    onUpdate() {
        const { editor, storage } = this;
        storage.debounceUpdate({ editor });
    },

    /**
     * Immediately update the content.
     */
    onBlur() {
        const { editor, storage } = this;
        storage.flushUpdate({ editor });
    },

    /**
     * Cleanup & flush the stored content and remove listeners.
     */
    onDestroy() {
        const { editor, storage } = this;

        storage.cleanupExtensionStorage({ editor });
        window.removeEventListener('beforeunload', storage.beforeUnloadListener!);
    },

    addCommands() {
        return {
            /**
             * Cancel a pending update.
             * A new update will need to be triggered in order for the content to be stored.
             */
            cancelPendingUpdate: () => () => this.storage.cancelPendingUpdate(),
            /**
             * Immediately flushes an update, if the content has changed since the last sync.
             */
            flushPendingUpdate:
                ({ transactionId }) =>
                ({ editor, state }) => {
                    this.storage.flushUpdate({ transactionId, state, editor });
                    return true;
                },
            /**
             * When content changes in the external store, it needs to be synced with the editor.
             */
            syncFromExternalStore:
                (content) =>
                ({ chain }) =>
                    chain()
                        .setContent(content)
                        .command(({ state }) => {
                            this.storage.setLastStoredContent(state.doc.content);
                            return true;
                        })
                        .run(),
        };
    },
});
