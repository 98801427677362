import DefaultBulletList from '@tiptap/extension-bullet-list';

export const BulletList = DefaultBulletList.extend({
    addOptions() {
        return {
            ...this.parent?.(),
            HTMLAttributes: { class: 'list-el' },
        };
    },

    addKeyboardShortcuts() {
        return {
            /* eslint-disable @typescript-eslint/naming-convention */
            // Keeping the original Tiptap shortcut, in case it's standard
            'Mod-Shift-8': () => this.editor.commands.toggleBulletList(),
            'Mod-Shift-U': () => this.editor.commands.toggleBulletList(),
        };
    },
});
