import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { getElementId } from '../../../../../../common/elements/utils/elementPropertyUtils';
import { getMainEditorId, getMainEditorKey } from '../../../../../element/utils/elementEditorUtils';

// Actions
import { updateSelectedElements } from '../../../../../element/actions/elementActions';
import { startEditingElementWithDelay } from '../../../../../element/selection/selectionActions';

// Selectors
import { getShowTitle } from '../../../../../element/taskList/taskListSelector';

// Components
import ToolbarTool from '../../ToolbarTool';
import Icon from '../../../../../components/icons/Icon';

// Constants
import { SHOW_TITLE_PROPERTY } from '../../../../../../common/taskLists/taskListConstants';
import { getSelectedElements } from '../../../../../element/selection/selectedElementsSelector';

const mapStateToProps = (state, ownProps) => ({
    selectedElements: getSelectedElements(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatchToggleTitle: (element) => {
        const showingTitle = getShowTitle(element);
        const changes = { [SHOW_TITLE_PROPERTY]: !showingTitle };
        dispatch(updateSelectedElements({ changes }));

        if (!showingTitle && element) {
            dispatch(
                startEditingElementWithDelay({
                    id: getElementId(element),
                    editorId: getMainEditorId({ element: element }),
                    editorKey: getMainEditorKey({ element: element }),
                    editDelay: 0,
                }),
            );
        }
    },
});

const TitleTool = ({ selectedElements, dispatchToggleTitle, ...rest }) => {
    const firstSelectedElement = selectedElements?.first();

    const isActive = getShowTitle(firstSelectedElement);

    const handleClick = () => {
        if (!firstSelectedElement) return;

        dispatchToggleTitle(firstSelectedElement);
    };

    return (
        <ToolbarTool {...rest} isActive={isActive} onClick={handleClick}>
            <Icon name="toolbar-title" />
        </ToolbarTool>
    );
};

TitleTool.propTypes = {
    name: PropTypes.string,
    isActive: PropTypes.bool,
    selectedElements: PropTypes.object,
    dispatchToggleTitle: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(TitleTool);
