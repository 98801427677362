/**
 * NOTE: This class has been separated from the CardClipper class so that the text editor store is only
 *      connected to when its required.
 */
// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import getSelectionRect from '../../../utils/dom/getSelectionRect';
import { stayedFalse } from '../../../utils/react/propsComparisons';
import { calculateClipperLocationState } from './cardClipperUtils';

// Components
import textEditorStoreConnector from '../../../components/editor/store/components/textEditorStoreConnector';
import cardClipperEditorStateManager from './cardClipperEditorStateManager';
import CardClipperTool from './CardClipperTool';

// Styles
import './CardClipper.scss';

const stayedNotClipping = stayedFalse('isClipping');

@textEditorStoreConnector
@cardClipperEditorStateManager
class CardClipperDraftJsContents extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectionYMidpoint: 0,
        };
    }

    componentDidMount() {
        // This is used when the clip attempt is cancelled / reverted
        if (
            this.props.editorState &&
            this.props.editorState.getSelection() &&
            !this.props.editorState.getSelection().isCollapsed()
        ) {
            // Find the clipper location after the browser gets the chance to render the selection again
            requestAnimationFrame(() => this.findClipperLocation(this.props));
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.editorState || !nextProps.editorState) return;

        // Don't update the clipper tool location if we're currently in the process of clipping
        if (
            stayedNotClipping(this.props, nextProps) &&
            this.props.editorState.getSelection() !== nextProps.editorState.getSelection()
        ) {
            this.findClipperLocation(nextProps);
        }
    }

    findClipperLocation = ({ editorState, getContextZoomScale }) => {
        if (!editorState || !editorState.getSelection) return;

        const zoomScale = getContextZoomScale();

        const selection = editorState.getSelection();
        if (selection.isCollapsed()) {
            this.setState({ selectionYMidpoint: 0 });
            return;
        }

        if (!document.activeElement) return;

        // NOTE: If this component ends up being used outside of Cards, this will cause issues.
        // It's purpose is to prevent the clipper from changing location when another text field gets focus
        // such as the hyperlink popup text input
        if (!document.activeElement.closest('.CardEditor')) return;

        const selectionRect = getSelectionRect();
        const containerRect = this.clipperContainerElement.getBoundingClientRect();

        const state = calculateClipperLocationState(selectionRect, containerRect, zoomScale);
        this.setState(
            state || {
                selectionYMidpoint: 0,
                selectionHeight: 0,
            },
        );
    };

    clipperContainerRef = (c) => {
        this.clipperContainerElement = c;
    };

    render() {
        const { selectionYMidpoint, selectionHeight } = this.state;

        return (
            <div className="CardClipper" ref={this.clipperContainerRef}>
                <CardClipperTool
                    {...this.props}
                    selectionYMidpoint={selectionYMidpoint}
                    selectionHeight={selectionHeight}
                />
            </div>
        );
    }
}

CardClipperDraftJsContents.propTypes = {
    isClipping: PropTypes.bool,
    isEditing: PropTypes.bool,
    editorState: PropTypes.object,
    getContextZoomScale: PropTypes.func,

    beforeDragStart: PropTypes.func,
};

export default CardClipperDraftJsContents;
