import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementTableIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={24}
        viewBox="0 0 32 24"
        className="Icon element-table"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path fill="#FFF" d="M2 0h28a2 2 0 0 1 2 2v20a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z" />
            <path fill="#D1D3D6" d="M2 0h28a2 2 0 0 1 2 2v3H0V2a2 2 0 0 1 2-2z" />
            <path
                fill="#1B2536"
                fillOpacity={0.1}
                d="M11 0v10h10V0h1v10h10v1H22v6h10v1H22v6h-1v-6H11v6h-1v-6H0v-1h10v-6H0v-1h10V0h1zm10 17v-6H11v6h10z"
            />
        </g>
    </svg>
);
const Memo = memo(ElementTableIcon);
export default Memo;
