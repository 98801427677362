import { useState, useEffect } from 'react';

/**
 * Once the value becomes true it won't become false again (as long as the component remains mounted)
 */
export default function useBooleanRatchet(bool) {
    // State and setters for debounced value
    const [ratchetBool, setRatchetBool] = useState(bool);

    useEffect(() => {
        if (bool) {
            setRatchetBool(bool);
        }
    }, [bool]);

    return ratchetBool;
}
