// Types
import { Selection } from '@tiptap/pm/state';

/**
 * Determines if the current selection encapsulates the text of an entire node.
 * E.g. We can use this to determine if the entire node should be removed, or just the text, while clipping.
 */
const isSelectionSpanningEntireNodes = (selection: Selection): boolean => {
    const { $from, $to } = selection;

    const blockRange = $from.blockRange($to);

    if (!blockRange) return false;

    return $from.pos - 1 === blockRange.start && $to.pos + 1 === blockRange.end;
};

export default isSelectionSpanningEntireNodes;
