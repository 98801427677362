// Lib
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Selectors
import { getMilanoteApplicationModeSelector } from '../../platform/platformSelector';

// Components
import FullScreenModal from './FullScreenModal';
import FullScreenErrorModal from './FullScreenErrorModal';

// Types
import { MilanoteApplicationMode } from '../../platform/platformTypes';

const RouteModalRoot = ({ children, isError, ...props }) => {
    const isMobile = useSelector(getMilanoteApplicationModeSelector) === MilanoteApplicationMode.mobile;

    if (isError) {
        return (
            <FullScreenErrorModal overlay {...props}>
                {children}
            </FullScreenErrorModal>
        );
    }

    // On mobile we render a sheet for each modal
    if (isMobile) {
        return <>{children}</>;
    }

    return (
        <FullScreenModal overlay {...props}>
            {children}
        </FullScreenModal>
    );
};

RouteModalRoot.propTypes = {
    children: PropTypes.node,

    isError: PropTypes.bool,
};

export default RouteModalRoot;
