// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { getLineStyle } from '../../../../../../common/elements/utils/elementPropertyUtils';

// Actions
import { updateSelectedElements } from '../../../../../element/actions/elementActions';

// Components
import Icon from '../../../../../components/icons/Icon';
import ToolbarTool from '../../ToolbarTool';

// Constants
import { LINE_STYLE } from '../../../../../../common/lines/lineConstants';

import './LineStyleTool.scss';

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateSelectedElements: ({ changes }) => dispatch(updateSelectedElements({ changes })),
});

const mapStateToProps = (state, ownProps) => ({
    isActive: getLineStyle(ownProps.selectedElements.first()) === LINE_STYLE.DASHED,
});

const LineStyleTool = (props) => {
    const { isActive, dispatchUpdateSelectedElements } = props;

    const setLineStyle = React.useCallback(() => {
        const newLineStyle = isActive ? LINE_STYLE.SOLID : LINE_STYLE.DASHED;

        dispatchUpdateSelectedElements({
            changes: {
                lineStyle: newLineStyle,
            },
        });
    }, [isActive]);

    return (
        <ToolbarTool className="LineStyleTool" {...props} onClick={setLineStyle} isActive={isActive}>
            <Icon {...props} name="toolbar-line-style-dash" />
        </ToolbarTool>
    );
};

LineStyleTool.propTypes = {
    name: PropTypes.string,
    isActive: PropTypes.bool,
    selectedElements: PropTypes.object.isRequired,

    dispatchUpdateSelectedElements: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(LineStyleTool);
