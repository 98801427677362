// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import TitleDraftJsEditorWrapper from '../titleDraftJsEditor/TitleDraftJsEditorWrapper';

// Utils
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';

// Plugins
import createDeleteOnEmptyPlugin from '../../components/editor/plugins/deleteOnEmpty';
import createSearchHighlightPlugin from '../../components/editor/plugins/searchHighlight';
import createTableTitleShortcutsPlugin from '../../components/editor/plugins/editableTitleShortcuts/tableShortcuts';

const deleteOnEmptyPlugin = createDeleteOnEmptyPlugin();
const searchHighlightPlugin = createSearchHighlightPlugin();
const tableShortcutsPlugin = createTableTitleShortcutsPlugin();

const plugins = [searchHighlightPlugin, tableShortcutsPlugin, deleteOnEmptyPlugin];

const TableTitleEditor = React.forwardRef(function TableTitleEditor(props, ref) {
    const hideTitleAndGoToFirstCell = () => {
        const { dispatchHideTitle, jumpToFirstCell, element } = props;

        dispatchHideTitle({ id: getElementId(element) });
        jumpToFirstCell();
    };

    return (
        <div ref={ref}>
            <TitleDraftJsEditorWrapper
                {...props}
                onEmptyBackspace={hideTitleAndGoToFirstCell}
                placeholder={'New Table'}
                textAlignment={'center'}
                plugins={plugins}
            />
        </div>
    );
});

TableTitleEditor.propTypes = {
    dispatchHideTitle: PropTypes.func,
    jumpToFirstCell: PropTypes.func,
    element: PropTypes.object,
};

export default TableTitleEditor;
