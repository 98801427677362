import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MobileToolbarAddElementIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon mobile-toolbar-add-element"
        {...props}
    >
        <path
            fill="#323B4A"
            fillRule="evenodd"
            d="M13 5v6h6a1 1 0 0 1 0 2h-6v6a1 1 0 0 1-2 0l-.001-6H5a1 1 0 0 1 0-2h5.999V5A1 1 0 0 1 13 5z"
        />
    </svg>
);
const Memo = memo(MobileToolbarAddElementIcon);
export default Memo;
