import { useDispatch, useSelector } from 'react-redux';

import { getIsSheetOpen, getSheetActiveSnapPoint } from '../sheetSelectors';
import { closeSheet, openSheet, updateSheetActiveSnapPoint } from '../SheetActions';

import { SheetId } from '../sheetTypes';

export type UseSheetReturnType = {
    isSheetOpen: boolean;
    activeSnapPoint: number;
    dispatchOpenSheet: () => void;
    dispatchCloseSheet: () => void;
    dispatchUpdateActiveSnapPoint: (activeSnapPoint: number) => void;
};

const useSheet = (sheetId: SheetId, options: { scrollToElementOnOpen?: boolean } = {}): UseSheetReturnType => {
    const isSheetOpen = useSelector(getIsSheetOpen(sheetId));
    const activeSnapPoint = useSelector(getSheetActiveSnapPoint(sheetId));

    const dispatch = useDispatch();

    const dispatchOpenSheet = () => dispatch(openSheet(sheetId, options?.scrollToElementOnOpen));
    const dispatchCloseSheet = () => dispatch(closeSheet(sheetId));

    const dispatchUpdateActiveSnapPoint = (activeSnapPoint: number) =>
        dispatch(updateSheetActiveSnapPoint(sheetId, activeSnapPoint));

    return {
        isSheetOpen,
        activeSnapPoint,
        dispatchOpenSheet,
        dispatchCloseSheet,
        dispatchUpdateActiveSnapPoint,
    };
};

export default useSheet;
