// Lib
import React, { ReactElement } from 'react';

// Utils
import { getTitle } from '../../../../common/elements/utils/elementPropertyUtils';

// Hooks
import { useElementTiptapContent } from '../../../../common/tiptap/conversion/elementConversion/useElementTiptapContent';

// Components
import TaskListTitleTiptapEditor, { TaskListTitleTiptapEditorProps } from './TaskListTitleTiptapEditor';
import TaskListTitleDraftJsEditor from './TaskListTitleDraftJsEditor';

// Types
import { DocumentType } from '../../../../common/tiptap/conversion/elementConversion/elementConversion';

export type TaskListTitleSwitchProps = TaskListTitleTiptapEditorProps;

const TaskListTitleSwitch = (props: TaskListTitleSwitchProps): ReactElement => {
    const {
        element,
        isFocusedForegroundElement,
        isEditable,
        isEditing,
        isSingleSelected,
        spellCheck,
        currentEditorId,
        startEditing,
        goToNextTaskHandler,
        dispatchHideTitle,
        dispatchUpdateTitle,
    } = props;

    const title = getTitle(element);

    const tiptapContent = useElementTiptapContent(element, title, DocumentType.textContent);

    if (tiptapContent) {
        return (
            <TaskListTitleTiptapEditor
                element={element}
                title={tiptapContent}
                isFocusedForegroundElement={isFocusedForegroundElement}
                isEditable={isEditable}
                isEditing={isEditing}
                isSingleSelected={isSingleSelected}
                spellCheck={spellCheck}
                currentEditorId={currentEditorId}
                startEditing={startEditing}
                goToNextTaskHandler={goToNextTaskHandler}
                dispatchHideTitle={dispatchHideTitle}
                dispatchUpdateTitle={dispatchUpdateTitle}
            />
        );
    }

    return <TaskListTitleDraftJsEditor {...props} />;
};

export default TaskListTitleSwitch;
