import { SheetActionTypes, SheetCloseAction, SheetOpenAction } from './SheetActionTypes';
import { SheetId } from './sheetTypes';

export const openSheet = (sheetId: SheetId, scrollToElement: boolean = false): SheetOpenAction => ({
    type: SheetActionTypes.SHEET_OPEN,
    sheetId,
    scrollToElement,
});

export const closeSheet = (sheetId: SheetId): SheetCloseAction => ({
    type: SheetActionTypes.SHEET_CLOSE,
    sheetId,
});

export const updateSheetActiveSnapPoint = (sheetId: SheetId, activeSnapPoint: number) => ({
    type: SheetActionTypes.SHEET_UPDATE_ACTIVE_SNAP_POINT,
    sheetId,
    activeSnapPoint,
});
