import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarCameraIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={40}
        viewBox="0 0 40 40"
        className="Icon toolbar-camera"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={40} height={40} fill="#EBEDEE" rx={4} />
            <path
                fill="#48505E"
                d="M22.93 8a2 2 0 0 1 1.664.89L26 11h3a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H11a4 4 0 0 1-4-4V15a4 4 0 0 1 4-4h3l1.406-2.11A2 2 0 0 1 17.07 8h5.86zm0 2h-5.86l-1.406 2.11A2 2 0 0 1 14 13h-3a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V15a2 2 0 0 0-2-2h-3a2 2 0 0 1-1.664-.89L22.93 10zM20 14.5a6 6 0 1 1 0 12 6 6 0 0 1 0-12zm0 2a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarCameraIcon);
export default Memo;
