// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from '../../../../node_module_clones/recompose';

// Utils
import { getCardDimensionPropertiesPx, getIsAutoHeight } from '../cardSizeUtil';
import { getElementId, getLinkedElementId } from '../../../../common/elements/utils/elementPropertyUtils';

// Components
import temporaryEditorStateDecorator from './temporaryEditorStateDecorator';
import editorOverflowObserver from './editorOverflowObserver';
import suggestDocumentDecorator from '../suggestDocumentDecorator';
import suggestDocumentHeightObserver from './suggestDocumentHeightObserver';
import CardEditorSwitch from '../CardEditorSwitch';
import CardOverflowOverlay from '../CardOverflowOverlay';
import CardClipper from '../clipper/CardClipper';

// Constants
import { ElementType } from '../../../../common/elements/elementTypes';

// Styles
import './ResizableCardEditorContainer.scss';

const HeightObservedCardEditor = suggestDocumentHeightObserver(CardEditorSwitch);

/**
 * Determines the minHeight, maxHeight and height properties.
 */
const cardContainerStyle = (props) => {
    const { element, gridSize, tempContainerHeight, isEditing, currentEditorKey } = props;

    if (tempContainerHeight) {
        return {
            height: tempContainerHeight,
        };
    }

    // If auto height, then don't set the max or min height
    const isAutoHeight = getIsAutoHeight(element);
    if (isAutoHeight) return;

    const dimensionsProperties = getCardDimensionPropertiesPx({ element, gridSize });

    // If we're editing the card then don't set the max height
    const allowHeightRestriction = !(isEditing && currentEditorKey === ElementType.CARD_TYPE);

    return {
        ...dimensionsProperties,
        maxHeight: allowHeightRestriction ? dimensionsProperties.maxHeight : null,
    };
};

const getOriginalEditorId = ({ element, elementId, isClone }) => {
    const originalElementId = isClone ? getLinkedElementId(element) : elementId;
    return `${originalElementId}-${ElementType.CARD_TYPE}`;
};

const ResizableCardEditorContainer = (props) => {
    const { element, isClone } = props;

    const elementId = getElementId(element);
    const originalEditorId = getOriginalEditorId({ element, elementId, isClone });

    return (
        <div className="ResizableCardEditorContainer" style={cardContainerStyle(props)} ref={props.containerRef}>
            <div className="card-editor-container-contents">
                <HeightObservedCardEditor {...props} originalEditorId={originalEditorId} />
                <CardOverflowOverlay show={!props.transparent && props.editorOverflowed} />
            </div>
            <CardClipper elementId={elementId} {...props} />
        </div>
    );
};

const enhancer = compose(editorOverflowObserver, suggestDocumentDecorator, temporaryEditorStateDecorator);

ResizableCardEditorContainer.propTypes = {
    element: PropTypes.object.isRequired,
    gridSize: PropTypes.number.isRequired,
    tempContainerHeight: PropTypes.number,
    isClone: PropTypes.bool,
    editorOverflowed: PropTypes.bool,
    containerRef: PropTypes.func,
    isEditing: PropTypes.bool,
    transparent: PropTypes.bool,
};

export default enhancer(ResizableCardEditorContainer);
