// Lib
import React, { ReactElement } from 'react';
import { Editor } from '@tiptap/react';

// Utils
import { getElementDefaultColors } from './textStylePopupUtils';

// Hooks
import { dangerouslyUseTiptapActiveEditor } from '../../../../../../components/tiptapEditor/store/tiptapEditorStoreHooks';

// Components
import TextStylePopupContent from './TextStylePopupContent';
import TiptapTextColorButton from './TiptapTextColorButton';
import TiptapTextHighlightButton from './TiptapTextHighlightButton';
import TextStyleEntry from './TextStyleEntry';
import TiptapClearTextColorButton from './TiptapClearTextColorButton';

// Constants
import { MILANOTE_HIGHLIGHT_COLORS } from '../../../../../../components/editor/plugins/textColor/textColorConstants';
import { VALID_MILANOTE_TEXT_COLORS } from '../../../../../../../common/tiptap/extensions/TextColor';

// Types
import { ImMNElement } from '../../../../../../../common/elements/elementModelTypes';

const MILANOTE_TEXT_COLORS = [...VALID_MILANOTE_TEXT_COLORS];
const STYLED_TEXT_BLOCK_TYPES = ['heading', 'smallText', 'codeBlock', 'blockquote'];

const isNormalTextActive = (activeEditor?: Editor | null) =>
    !activeEditor || STYLED_TEXT_BLOCK_TYPES.every((type) => !activeEditor.isActive(type));

interface TiptapTextStylePopupContentProps {
    element: ImMNElement;
    showBlockStyleTools: boolean;
}

const TiptapTextStylePopupContent = (props: TiptapTextStylePopupContentProps): ReactElement => {
    const { showBlockStyleTools, element } = props;

    const { defaultTextColor, defaultBackgroundColor } = getElementDefaultColors(element) || {};

    // This is running within a popup, so performance is not an issue and using the active
    // editor means we don't have to define individual selectors for each active state
    const activeEditor = dangerouslyUseTiptapActiveEditor();

    /**
     * When selecting a text style, we need to handle the special case of blockquote
     * because it wraps the existing nodes instead of replacing them.
     */
    const onTextStyleSelect = (nodeType: string, attributes?: Record<string, any>) => () => {
        if (!activeEditor) return;

        let currentOperation = activeEditor.chain();

        // Blockquote is a special case because it wraps the existing nodes
        if (activeEditor.isActive('blockquote')) {
            currentOperation = currentOperation.unsetBlockquote();
        }

        currentOperation.setNode(nodeType, attributes).run();
    };

    /**
     * When selecting a blockquote, we need special handling for lists.
     */
    const onBlockquoteSelect = () => {
        if (!activeEditor) return;

        activeEditor.commands.toggleBlockquote();
    };

    /**
     * When converting to code blocks, we need special handling to combine multiple
     * lines into a single code block.
     */
    const onCodeBlockSelect = () => {
        if (!activeEditor) return;

        activeEditor.commands.toggleCodeBlock();
    };

    return (
        <TextStylePopupContent
            showBlockStyleTools={showBlockStyleTools}
            blockStyleComponents={
                <>
                    <TextStyleEntry
                        active={activeEditor?.isActive('heading', { level: 1 })}
                        blockClassname="header-one"
                        shortcut={['command', 'shift', '1']}
                        onTextStyleSelect={onTextStyleSelect('heading', { level: 1 })}
                    >
                        Large heading
                    </TextStyleEntry>
                    <TextStyleEntry
                        active={activeEditor?.isActive('heading', { level: 2 })}
                        blockClassname="header-two"
                        shortcut={['command', 'shift', '2']}
                        onTextStyleSelect={onTextStyleSelect('heading', { level: 2 })}
                    >
                        Normal heading
                    </TextStyleEntry>
                    <TextStyleEntry
                        active={isNormalTextActive(activeEditor)}
                        shortcut={['command', 'shift', '0']}
                        onTextStyleSelect={onTextStyleSelect('paragraph')}
                    >
                        Normal text
                    </TextStyleEntry>
                    <TextStyleEntry
                        active={activeEditor?.isActive('smallText')}
                        blockClassname="small-text"
                        shortcut={['command', 'shift', '9']}
                        onTextStyleSelect={onTextStyleSelect('smallText')}
                    >
                        Small text
                    </TextStyleEntry>
                    <TextStyleEntry
                        active={activeEditor?.isActive('codeBlock')}
                        blockClassname="code-block"
                        shortcut={['command', '>']}
                        onTextStyleSelect={onCodeBlockSelect}
                    >
                        Code block
                    </TextStyleEntry>
                    <TextStyleEntry
                        active={activeEditor?.isActive('blockquote')}
                        blockClassname="blockquote"
                        shortcut={['command', '"']}
                        onTextStyleSelect={onBlockquoteSelect}
                    >
                        Quote block
                    </TextStyleEntry>
                </>
            }
            textColorComponents={[
                <TiptapClearTextColorButton
                    key="default"
                    customTextColor={defaultTextColor}
                    customBackgroundColor={defaultBackgroundColor}
                />,
                MILANOTE_TEXT_COLORS.map((color) => (
                    <TiptapTextColorButton key={color} color={color} customBackgroundColor={defaultBackgroundColor} />
                )),
            ]}
            textHighlightComponents={MILANOTE_HIGHLIGHT_COLORS.map((color) => (
                <TiptapTextHighlightButton key={color} color={color} />
            ))}
        />
    );
};

export default TiptapTextStylePopupContent;
