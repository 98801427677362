import { NodeType } from '@tiptap/pm/model';
import { CommandProps } from '@tiptap/react';
import { asNodeType } from '../../utils/asNodeType';
import { isListTypeClosestActive } from '../list/tiptapListUtils';
import { TiptapDispatch } from '../../tiptapTypes';
import { changeToList, removeFromList } from '../list/tiptapListOperations';

/**
 * Determines whether the list type is currently active, and disables it if it is,
 * or enables it if it is not.
 */
export const toggleList =
    (
        listTypeOrName: string | NodeType,
        // NOTE: The following parameters aren't used at the moment, they're just
        //  here to match the OOTB list command signature
        itemTypeOrName: string | NodeType,
        keepMarks?: boolean | undefined,
        attributes?: Record<string, any> | undefined,
    ) =>
    ({ editor, state, dispatch }: CommandProps) => {
        if (keepMarks || attributes) {
            throw new Error('keepMarks and attributes params are not implemented for toggleList');
        }

        const listType = asNodeType(listTypeOrName, state);

        if (!listType) return false;

        const isCurrentlyActive = isListTypeClosestActive(listType.name)(editor);

        return isCurrentlyActive
            ? removeFromList(state, dispatch as TiptapDispatch)
            : changeToList(state, dispatch as TiptapDispatch, listType);
    };
