/**
 * These are operations that are similar to those found in Tiptap's commands,
 * however most of those are performed on the editor's selection, whereas
 * these are performed on specific ranges.
 */
import { NodeRange } from '@tiptap/pm/model';
import { EditorState } from '@tiptap/pm/state';
import { liftTarget } from '@tiptap/pm/transform';
import { TiptapDispatch, TiptapNodeType } from '../tiptapTypes';

/**
 * This function is similar to the clearNodes command in Tiptap, however it
 * operates on a specific range rather than the editor's selection.
 *
 * It also ensures that the "mapping" only occurs for operations that are performed
 * within this function, rather than the entire transaction.
 */
export const clearNodesInRange = (state: EditorState, dispatch: TiptapDispatch, range: NodeRange | null) => {
    if (!range) return false;
    if (!dispatch) return true;

    const { $from, $to } = range;

    const initialStepsCount = state.tr.steps.length;

    state.tr.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
        if (node.type.isText) return false;

        const { doc } = state.tr;

        // Only map positions that have changed while performing this operation
        const mapping = state.tr.mapping.slice(initialStepsCount);

        const $mappedFrom = doc.resolve(mapping.map(pos));
        const $mappedTo = doc.resolve(mapping.map(pos + node.nodeSize));

        const nodeBlockRange = $mappedFrom.blockRange($mappedTo);

        if (!nodeBlockRange) return false;

        const targetLiftDepth = liftTarget(nodeBlockRange);

        if (node.type.isTextblock) {
            const { defaultType } = $mappedFrom.parent.contentMatchAt($mappedFrom.index());
            state.tr.setNodeMarkup(nodeBlockRange.start, defaultType);
        }

        if (targetLiftDepth || targetLiftDepth === 0) {
            state.tr.lift(nodeBlockRange, targetLiftDepth);
        }
    });

    return true;
};

/**
 * Finds all the positions within the range where two blocks of a given type are adjacent.
 */
export const findAdjacentPositions = (
    state: EditorState,
    blockType: TiptapNodeType,
    nodeRange: NodeRange,
): number[] => {
    const { tr } = state;

    // Find all the points within the selection where two code blocks are adjacent
    const blockTypeEnds = new Set<number>();
    const adjacentPositions: number[] = [];

    tr.doc.nodesBetween(nodeRange.$from.pos, nodeRange.$to.pos, (node, pos) => {
        if (node.type.name !== blockType) return false;

        if (blockTypeEnds.has(pos)) adjacentPositions.push(pos);
        blockTypeEnds.add(pos + node.nodeSize);

        return false;
    });

    return adjacentPositions;
};
