/**
 * Keeps a set of keys that are currently pausing updates.
 * When the set is empty, updates are allowed to be synced.
 */
export class PauseSet {
    private pausers = new Set<string>();

    pauseUpdates(key: string) {
        this.pausers.add(key);
    }

    resumeUpdates(key: string) {
        this.pausers.delete(key);
    }

    isPaused() {
        return this.pausers.size > 0;
    }

    reset() {
        this.pausers.clear();
    }

    toString() {
        return Array.from(this.pausers).join(', ');
    }
}
