// Types
import { TiptapContent } from '../../../tiptapTypes';

/**
 * Removes the last node from the content if it's empty.
 */
const stripEmptyTrailingNode = <T extends TiptapContent | null | undefined>(editorContent: T): T => {
    const docContent = editorContent?.content;

    if (!docContent) return editorContent;

    const lastNode = docContent[docContent.length - 1];

    if ((lastNode.content?.length || 0) > 0) return editorContent;

    return {
        ...editorContent,
        content: docContent.slice(0, -1),
    };
};

export default stripEmptyTrailingNode;
