// Lib
import { EditorState } from '@tiptap/pm/state';

export const isTiptapSelectionCollapsedAtEnd = (state: EditorState) => {
    const { selection, doc } = state;
    const { to, from } = selection;

    if (to !== from) return false;

    // Subtract the closing doc node
    return to === doc.content.size - 1;
};
