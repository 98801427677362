// Lib
import { useMemo } from 'react';
import { defer } from 'lodash';

// Utils
import { isTiptapEditorEmpty } from '../../../../common/tiptap/utils/isTiptapEditorEmpty';
import createJsonContentFromSlice from '../../../../common/tiptap/utils/createJsonContentUtils/createJsonContentFromSlice';

// Hooks
import { useActiveTiptapEditorCallback } from '../../../components/tiptapEditor/store/tiptapEditorStoreHooks';

// Extensions
import { KeyboardShortcuts } from '../../../../common/tiptap/extensions/KeyboardShortcuts';

// Types
import { EditorContent } from '../../../../common/elements/elementModelTypes';
import { SelectionState } from 'draft-js';
import { Command } from '@tiptap/core';
import { Editor } from '@tiptap/react';
import { TiptapContent } from '../../../../common/tiptap/tiptapTypes';

export type TaskTiptapEditorKeyboardShortcutHandlerArgs = {
    onEnter: (args: {
        hasText: boolean;
        insertBefore: boolean;
        mergeTransactions: boolean;

        newTaskTextContent: EditorContent;
        // NOTE: Tiptap doesn't use the SelectionState. Instead, the event handler here manages the focus
        newTaskSelection: SelectionState | null;
    }) => void;
    onKeyboardDelete: (args: { forward: boolean }) => void;
    changeIndentation: (args: { increaseIndentation: boolean }) => void;
    toggleComplete: () => void;
    dispatchOpenDueDatePopup: () => void;
    dispatchOpenAssignmentPopup: () => void;
    goToPreviousTask: (isTiptapEditor: boolean) => boolean;
    goToNextTask: (isTiptapEditor: boolean) => boolean;
    onStartBackspace: (args: { textContent: EditorContent }) => void;
    onEndDelete: (args: { textContent: EditorContent }) => void;
};

const useTaskTiptapEditorKeyboardShortcutsExtension = (args: TaskTiptapEditorKeyboardShortcutHandlerArgs) => {
    const {
        onEnter,
        onKeyboardDelete,
        changeIndentation,
        toggleComplete,
        dispatchOpenDueDatePopup,
        dispatchOpenAssignmentPopup,
        goToPreviousTask,
        goToNextTask,
        onStartBackspace,
        onEndDelete,
    } = args;
    /**
     * Focuses the active editor and sets the selection to the given positions.
     * NOTE: Supports negative offsets, which will be relative to the end of the document.
     */
    const focusActiveEditor = useActiveTiptapEditorCallback(
        (activeEditor, selectionStartPos: number, selectionEndPos: number) => {
            if (!activeEditor) return;

            // Both offsets must be positive or negative
            if (selectionStartPos * selectionEndPos < 0) return;

            const selection = { from: selectionStartPos, to: selectionEndPos };

            // If the selection is negative, it's relative to the end of the document
            if (selectionStartPos < 0) {
                const contentSize = activeEditor.state.doc.content.size;
                selection.from += contentSize;
                selection.to += contentSize;
            }

            return activeEditor.commands.setTextSelection(selection);
        },
        [],
    );

    return useMemo(() => {
        const onReturnHandler: Command = ({ editor, commands, chain }) => {
            const { selection } = editor.state;
            const { from, to } = selection;

            const hasText = !isTiptapEditorEmpty(editor as unknown as Editor);

            const isCaretAtEnd = selection.empty && from === editor.state.doc.content.size - 1;
            const isCaretAtStart = selection.empty && from === 1;

            if (!hasText || isCaretAtEnd || isCaretAtStart) {
                commands.flushPendingUpdate({});

                onEnter({
                    hasText,
                    insertBefore: hasText && isCaretAtStart,
                    mergeTransactions: false,
                    newTaskTextContent: null,
                    newTaskSelection: null,
                });
                return true;
            }

            // Get the slice from the end of the selection to the end of the document
            const newContentSlice = editor.state.doc.slice(to, editor.state.doc.content.size, true);
            const newTaskTextContent = createJsonContentFromSlice(newContentSlice, editor.schema);

            // Remove the content from this editor from the start of the selection
            chain().deleteRange({ from, to: editor.state.doc.content.size }).flushPendingUpdate({}).run();

            onEnter({
                hasText,
                insertBefore: false,
                mergeTransactions: true,
                newTaskTextContent,
                newTaskSelection: null,
            });

            // Not sure why we need to double defer this, but as it's a temporary solution
            //  I'm not going to worry about investigating it further
            defer(() => defer(() => focusActiveEditor(1, 1)));

            return true;
        };

        const onDeleteHandler: Command = ({ editor }) => {
            if (isTiptapEditorEmpty(editor)) {
                // Prevent mousetrap deletion handler from being triggered
                defer(() => onKeyboardDelete({ forward: true }));

                return true;
            }

            const { selection } = editor.state;
            const { from } = selection;

            const isCaretAtEnd = selection.empty && from === editor.state.doc.content.size - 1;

            if (!isCaretAtEnd) return false;

            editor.commands.flushPendingUpdate({});

            defer(() => {
                onEndDelete({ textContent: editor.getJSON() as TiptapContent });

                // Return the caret to its original position
                // NOTE: We need a double defer to allow the current element to get edited again
                //  This is a hack - but because the "single task editor" is intended to be temporary,
                //  I won't try to improve this solution
                defer(() => defer(() => focusActiveEditor(from, from)));
            });

            return true;
        };

        const onBackspaceHandler: Command = ({ editor }) => {
            if (isTiptapEditorEmpty(editor)) {
                // Prevent mousetrap deletion handler from being triggered
                defer(() => onKeyboardDelete({ forward: false }));

                return true;
            }

            const { selection } = editor.state;
            const { from } = selection;

            // If not at the start, ignore
            if (!selection.empty || from !== 1) return false;

            editor.commands.flushPendingUpdate({});

            defer(() => {
                const currentEditorContentSize = editor.state.doc.content.size;

                onStartBackspace({ textContent: editor.getJSON() as TiptapContent });

                // Subtract the length of this content, excluding the opening doc node
                const offset = -currentEditorContentSize + 1;

                defer(() => focusActiveEditor(offset, offset));
            });

            return true;
        };

        const onTabHandler =
            (increaseIndentation: boolean): Command =>
            ({ editor }) => {
                changeIndentation({ increaseIndentation });

                // Give the editor time to mount after the indentation change
                defer(() => {
                    focusActiveEditor(editor.state.selection.from, editor.state.selection.to);
                });

                return true;
            };

        const toggleCompleteHandler: Command = () => {
            toggleComplete();
            return true;
        };

        const openDueDatePopupHandler: Command = () => {
            dispatchOpenDueDatePopup();
            return true;
        };

        /**
         * Only open the assignment popup if the caret is at the end of the editor,
         * either preceded by a space, or there's no text.
         */
        const openAssignmentPopupHandler: Command = ({ editor }) => {
            const { selection } = editor.state;
            const { from } = selection;

            const isCaretAtEnd = selection.empty && from === editor.state.doc.content.size - 1;

            if (!isCaretAtEnd) return false;

            // If from is at the start of the document, then we should open the popup
            if (from !== 1) {
                // Otherwise, only open the popup if there's a space before the caret
                const textBeforeCaret = editor.state.doc.textBetween(from - 1, from, '\n');

                if (textBeforeCaret !== ' ') return false;
            }

            dispatchOpenAssignmentPopup();
            return true;
        };

        /**
         * Moves the caret to the previous task, if there is one.
         */
        const onArrowPrevHandler: Command = ({ editor }) => {
            const { selection } = editor.state;
            const { from } = selection;

            if (!selection.empty) return false;

            if (from !== 1) return false;

            // Prevent mousetrap arrow handler from being triggered
            defer(() => {
                goToPreviousTask(true) &&
                    // If it was successful, focus the previous editor at the end
                    defer(() => focusActiveEditor(-1, -1));
            });

            return true;
        };

        /**
         * Moves the caret to the next task, if there is one.
         */
        const onArrowNextHandler: Command = ({ editor }) => {
            const { selection, doc } = editor.state;
            const { from } = selection;

            if (!selection.empty) return false;

            if (from !== doc.content.size - 1) return false;

            // Prevent mousetrap arrow handler from being triggered
            defer(() => {
                goToNextTask(true) &&
                    // If it was successful, focus the next editor at the start
                    defer(() => focusActiveEditor(1, 1));
            });

            return true;
        };

        return KeyboardShortcuts.configure({
            keyboardShortcuts: {
                Enter: onReturnHandler,
                Return: onReturnHandler,
                // Backspace
                Backspace: onBackspaceHandler,
                'Shift-Backspace': onBackspaceHandler,
                'Alt-Backspace': onBackspaceHandler,
                'Control-Backspace': onBackspaceHandler,
                'Cmd-Backspace': onBackspaceHandler,
                // Delete
                Delete: onDeleteHandler,
                'Shift-Delete': onDeleteHandler,
                'Alt-Delete': onDeleteHandler,
                'Control-Delete': onDeleteHandler,
                'Cmd-Delete': onDeleteHandler,
                // Tab
                Tab: onTabHandler(true),
                'Shift-Tab': onTabHandler(false),
                // Shortcuts
                'Mod-.': toggleCompleteHandler,
                'Shift-2': openAssignmentPopupHandler,
                'Shift-Mod-2': openDueDatePopupHandler,
                // Arrows
                ArrowLeft: onArrowPrevHandler,
                'Alt-ArrowLeft': onArrowPrevHandler,
                'Mod-ArrowLeft': onArrowPrevHandler,
                ArrowUp: onArrowPrevHandler,
                'Alt-ArrowUp': onArrowPrevHandler,
                'Mod-ArrowUp': onArrowPrevHandler,
                ArrowRight: onArrowNextHandler,
                'Alt-ArrowRight': onArrowNextHandler,
                'Mod-ArrowRight': onArrowNextHandler,
                ArrowDown: onArrowNextHandler,
                'Alt-ArrowDown': onArrowNextHandler,
                'Mod-ArrowDown': onArrowNextHandler,
            },
        });
    }, [
        onEnter,
        onKeyboardDelete,
        changeIndentation,
        toggleComplete,
        dispatchOpenDueDatePopup,
        dispatchOpenAssignmentPopup,
        goToPreviousTask,
        goToNextTask,
        onStartBackspace,
        onEndDelete,
    ]);
};

export default useTaskTiptapEditorKeyboardShortcutsExtension;
