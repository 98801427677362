// Lib
import { Extension } from '@tiptap/core';
import { defer } from 'lodash';

// Actions
import { redoAction, undoAction } from '../../../client/utils/undoRedo/undoRedoActions';

// Types
import { DEFAULT_TIPTAP_EXTENSION_PRIORITY } from '../tiptapTypes';

interface AppUndoOptions {
    dispatch: Function;
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        appUndo: {
            appUndo: () => ReturnType;
            appRedo: () => ReturnType;
        };
    }
}

/**
 * This extension triggers an app undo/redo, rather than a Tiptap undo/redo.
 * It's intended for task list editors, because they're made up of multiple editors,
 * it's a better experience to allow the app to handle it.
 * This comes with some quirks (elements updating in strange orders), but it's better
 * than being stuck in a single editor's undo stack.
 *
 * NOTE: This isn't a perfect solution to triggering app undo because we don't also control
 *  the focus. Currently, it's only intended as a temporary solution, while task lists
 *  are made up of multiple editors.
 */
export const AppUndo = Extension.create<AppUndoOptions>({
    name: 'appUndo',

    priority: DEFAULT_TIPTAP_EXTENSION_PRIORITY + 1,

    addOptions() {
        return {
            ...this.parent?.(),
            dispatch: () => {},
        };
    },

    addStorage() {
        return {
            initialUndoDepth: -1,
            initialRedoDepth: -1,
        };
    },

    addCommands() {
        return {
            /**
             * Saves the current state, then triggers an app (rather than Tiptap) undo.
             */
            appUndo:
                () =>
                ({ editor }) => {
                    defer(() => {
                        this.options.dispatch(undoAction());
                    });

                    editor.commands.flushPendingUpdate({});
                    return true;
                },
            /**
             * Saves the current state, then triggers an app (rather than Tiptap) redo.
             */
            appRedo:
                () =>
                ({ editor }) => {
                    defer(() => {
                        this.options.dispatch(redoAction());
                    });

                    editor.commands.flushPendingUpdate({});
                    return true;
                },
        };
    },

    addKeyboardShortcuts() {
        return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Mod-z': () => this.editor.commands.appUndo(),
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Mod-Shift-z': () => this.editor.commands.appRedo(),
        };
    },
});
