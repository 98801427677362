import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import IconLight from './light/IconLight';
import IconDark from './dark/IconDark';

// Selectors
import { getThemeIsDarkMode } from '../../user/account/accountModal/interface/themeSettings/themeSelector';
import getGridSize from '../../utils/grid/gridSizeSelector';

// Constants
import { GRID } from '../../utils/grid/gridConstants';
import { ICON_SIZES } from './iconConstants';

type IconProps = {
    name: string;
    size?: string;
};

const getIconSizeForGridSize = (gridSize: number) => {
    switch (gridSize) {
        case GRID.SMALL.size:
            return ICON_SIZES.SMALL;
        case GRID.MEDIUM.size:
            return ICON_SIZES.MEDIUM;
        case GRID.LARGE.size:
        default:
            return ICON_SIZES.LARGE;
    }
};

const Icon = ({ name, size }: IconProps) => {
    const gridSize = useSelector(getGridSize);
    const isDarkMode = useSelector(getThemeIsDarkMode);

    const iconSize = size || getIconSizeForGridSize(gridSize);

    return isDarkMode ? <IconDark name={name} iconSize={iconSize} /> : <IconLight name={name} iconSize={iconSize} />;
};

Icon.propTypes = {
    isDarkMode: PropTypes.bool,
    name: PropTypes.string,
    gridSize: PropTypes.number,
    size: PropTypes.string,

    getIconSizeForGridSize: PropTypes.func,
};

export default Icon;
