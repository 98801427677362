// Lib
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

// Selectors
import getGridSize from '../../../utils/grid/gridSizeSelector';

// Hooks
import { useCardClipperPositioner } from './useCardClipperPositioner';
import { useCardClipperEventHandler } from './useCardClipperEventHandler';
import { useTiptapActiveEditorSelector } from '../../../components/tiptapEditor/store/tiptapEditorStoreHooks';

// Components
import CardClipperTool from './CardClipperTool';

// Types
import { Selection } from '@tiptap/pm/state';
import { CardClipperTiptapContentProps } from './cardClipperTypes';

// Styles
import './CardClipper.scss';

type CardClipperTiptapToolProps = CardClipperTiptapContentProps & {
    currentSelection?: Selection;
    clipperContainerRef: React.RefObject<HTMLDivElement>;
};

const CardClipperTiptapTool = (props: CardClipperTiptapToolProps): ReactElement => {
    const {
        elementId,
        currentSelection,
        clipperContainerRef,
        isClipping,
        isClipCopy,
        isClipDragging,
        clippedText,
        getContextZoomScale,
    } = props;

    const gridSize = useSelector(getGridSize);

    const { selectionYMidpoint, selectionHeight } = useCardClipperPositioner(
        currentSelection,
        clipperContainerRef,
        isClipping,
    );
    const { beforeDragEnd, onClipEnd, onClipStart } = useCardClipperEventHandler(elementId, isClipCopy);

    return (
        <CardClipperTool
            elementId={elementId}
            gridSize={gridSize}
            isClipping={isClipping}
            isClipCopy={isClipCopy}
            isClipDragging={isClipDragging}
            clippedText={clippedText}
            beforeDragEnd={beforeDragEnd}
            onClipStart={onClipStart}
            onClipEnd={onClipEnd}
            selectionYMidpoint={selectionYMidpoint}
            selectionHeight={selectionHeight}
            getContextZoomScale={getContextZoomScale}
        />
    );
};

const CardClipperTiptapContents = (props: CardClipperTiptapContentProps): ReactElement | null => {
    const { elementId, isClipping, isClipCopy, isClipDragging, clippedText, getContextZoomScale } = props;

    const currentSelection: Selection = useTiptapActiveEditorSelector((editor) => editor?.state.selection);
    const clipperContainerRef = React.useRef<HTMLDivElement>(null);

    if (!isClipping && (!currentSelection || currentSelection.empty)) return null;

    return (
        <div className="CardClipper CardClipperTiptapContents" ref={clipperContainerRef}>
            <CardClipperTiptapTool
                elementId={elementId}
                isClipping={isClipping}
                isClipCopy={isClipCopy}
                isClipDragging={isClipDragging}
                clippedText={clippedText}
                currentSelection={currentSelection}
                clipperContainerRef={clipperContainerRef}
                getContextZoomScale={getContextZoomScale}
            />
        </div>
    );
};

export default CardClipperTiptapContents;
