export enum AppVersionRulePlatform {
    ios = 'ios',
    android = 'android',
}

export enum AppVersionRuleModifier {
    olderOrEqual = '<=',
    equal = '==',
    newerOrEqual = '>=',
}
export interface AppVersionRule {
    platform: AppVersionRulePlatform;
    modifier: AppVersionRuleModifier;
    version: string;
    reason: string;
    addedBy: string;
    addedAt: number;
}
